<div id="contractList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="breadcrumb-item active"><a [routerLink]="['/contract/list']">{{"views.contract-detail.Contract List"| translate}}</a></li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{"views.home.Log out"| translate}}</a></li>
    </ol>
    <h1>{{contract.name}}</h1>

    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
             float:right;
    font-size: 22px; "></i>
        </p>
    </div>
    <div class="card">
        <div class="card-header">
        </div>
        <div class="card-body">
            <form [formGroup]="contractrequest" (ngSubmit)="onSubmit(contractrequest)" class="form-horizontal" role="form">
                <div class="mb-3 row" show-errors>
                    <label for="contract.status" class="col-sm-2 control-label">{{'views.user.Status'| translate}}</label>
                    <div class="col-sm-7">
                        <img style="width: 25px" *ngIf="contract.status === 'suspended'"
                        src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                        title="{{'views.user.suspended' | translate}}" />
                    <img style="width: 25px" *ngIf="contract.status === 'active'"
                        src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                        title="{{'views.user.active' | translate}}" />
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="contract.name" class="col-sm-2 control-label ">{{'views.public.orgchoice.Name'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="contract.name" (change)="checkName(name)" formControlName="name" required 
                                />
                    </div>
                    <div class="col-sm-3" *ngIf="contractrequest.controls.name.errors!=null">
                        <p class="error-block" *ngIf="contractrequest.controls.name.errors.required && contractrequest.controls.name.touched">{{'views.public.otpmail.Required'| translate}}</p>
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="contract.startdate" class="col-sm-2 control-label ">{{'views.service.Start date'| translate}}</label>
                    <div class="col-sm-7">
                        <p class="input-group">
                            <input id="contract.startDate" name="startDate" type="date" class="form-control" 
                                   formControlName="startDate" 
                                   
                                   style="text-indent: 0px;width:340px" 
                                   required placeholder="dd/MM/yyyy"/>
                        </p>
                    </div>
                    <div class="col-sm-1" *ngIf="contractrequest.controls.startDate.errors != null">
                        <p class="help-block" *ngIf="contractrequest.controls.startDate.errors.required && contractrequest.controls.startDate.touched">{{'views.public.otpmail.Required'| translate}}</p>
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="contract.endDate" class="col-sm-2 control-label ">{{'views.service.End date'| translate}}</label>
                    <div class="col-sm-7">
                        <p class="input-group">
                            <input id="contract.endDate" name="enddate" type="date" class="form-control" 
                                 formControlName="endDate"  
                                 required placeholder="dd/MM/yyyy"
                                 style="text-indent: 0px;width:340px" 
                                 />
                            
                        </p>
                    </div>
                    <div class="col-sm-1" *ngIf="contractrequest.controls.endDate.errors != null">
                        <p class="help-block" *ngIf="contractrequest.controls.endDate.errors.required && contractrequest.controls.endDate.touched">{{'views.public.otpmail.Required'| translate}}</p>
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="contract.wbs" class="col-sm-2 control-label ">{{'views.contract-detail.WBS'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="contract.wbs"  [(ngModel)]="contract.wbs"name="wbs" formControlName="wbs" />
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="contract.description" class="col-sm-2 control-label ">{{'views.service.Description'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="contract.description" [(ngModel)]="contract.description" name="description" formControlName="description" />
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label  for="contract.contactEmail" class="col-sm-2 control-label ">{{'views.service.Contact email'| translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon">@</span>
                            <input type="text" class="form-control" 
                                   name="email" id="contract.contactEmail" 
                                   [(ngModel)]="contract.contactEmail"
                                   formControlName="contactEmail" 
                                   />
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <p class="help-block" *ngIf="contractrequest.controls.contactEmail.errors?.email && contractrequest.controls.contactEmail.touched">{{'views.public.pwdmail.The email address is invalid'| translate}}</p>
                    </div>
                </div>
                 <div class="mb-3 row">
                    <div class="offset-sm-2 col-sm-10">
                        <div class="checkbox" >
                            <label>
                                <input type="checkbox" id="contract.roleNotifications" [(ngModel)]="contract.roleNotifications" name="roleNotifications" formControlName="roleNotifications"  style="position: relative;"/>
                                <span class="">{{'views.contract-detail.Disable role assign notification e-mail.'| translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-light custom-btn"
                                (click)="submit()"
                                [attr.disabled]="contractrequest.status === 'INVALID'? '':null"
                                >
                            <span class="bi bi-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save'| translate}}</button>
                    </div>
                </div>
            </form>

            </div>
            </div>
            <accordion>
                <div id="organizations" >
                    <accordion-group is-open="false" is-disabled="false">
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                    
                            <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                           </div> {{'views.contract-detail.Organizations'| translate}}({{organizationcount}})
                        </div>
                   
                    <span class="" *ngIf="organizationcount < 1">
                        {{'views.contract-detail.The contract is not linked to any organization.'| translate}} <br />
                    </span>
                    <div >
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- ID Column -->
                            <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                              <td mat-cell *matCellDef="let row"> 
                                <img style="width: 25px" *ngIf="row.status == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                <img style="width: 25px" *ngIf="row.status == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                              </td>
                            </ng-container>
                        
                            <!-- Progress Column -->
                            <ng-container matColumnDef="organization">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                              <td mat-cell *matCellDef="let row" (click)="navigateorgDetail(row)"> {{row.organizationId.name}} </td>
                            </ng-container>
                         <!-- Progress Column -->
                         <ng-container matColumnDef="technicalId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Technical ID</th>
                            <td mat-cell *matCellDef="let row" (click)="navigateorgDetail(row)"> {{row.organizationId.technicalId}} </td>
                          </ng-container>
                            <ng-container matColumnDef="filter-status">
                                <th mat-header-cell *matHeaderCellDef> 
                                 
                                   </th>
                              </ng-container>
                            
        
                              <ng-container matColumnDef="filter-organization">
                                <th mat-header-cell *matHeaderCellDef> 
                                   <mat-form-field appearance="outline">
                                  <input
                                    matInput
                                    (keyup)="filterData('name',$event.target.value)"
                                    
                                  />
                                </mat-form-field> </th>
                              </ng-container>
                              <ng-container matColumnDef="filter-technicalid">
                                <th mat-header-cell *matHeaderCellDef> 
                                   <mat-form-field appearance="outline">
                                  <input
                                    matInput
                                    (keyup)="filterData('technicalId',$event.target.value)"
                                    
                                  />
                                </mat-form-field> </th>
                              </ng-container>
            
                              <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                        <div >
                                         <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
                                       </div> 
                                       </td>
                                    
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-organization','filter-technicalid']"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                            
                          </table>
                                  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="organizationcount" [pageSize]="10"></mat-paginator>

                          <mat-form-field appearance="outline" class="my-form-field">
                            <input [ngModel]="testData" class="my-input" [formControl]="orgformControl"
                                placeholder="Add Organization: " type="text" matInput [matAutocomplete]="auto1">
                
                            <mat-autocomplete class="my-autocomplete" #auto1="matAutocomplete" (optionSelected)="updateInputorg($event)"
                                [displayWith]="displayFn">
                                <mat-option *ngFor="let item of orgfilteredOptions | async" [value]="item">
                                    {{item.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <button class="btn btn-light custom-btn" (click)="addOrganization()">
                            <span class="bi bi-plus-lg" style="margin-right: 10px">
                            </span>{{"views.home.Add organization"| translate}}
                        </button>
               
                    </div>
                        </accordion-group>
                        </div>
                        <div id="services" >
                            <accordion-group is-open="false" is-disabled="false">
                                <div accordion-heading  style="width: 100%;">  
                                    <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                                   </div>{{'views.contract-detail.Services'| translate}} ({{servicecount}})
                                </div>
                           
                            <span class="" *ngIf="servicecount < 1">
                                {{'views.contract-detail.The contract is not linked to any organization.'| translate}} <br />
                            </span>
                            <div >
                                <table mat-table [dataSource]="servicedataSource" matSort>
        
                                    <!-- ID Column -->
                                    <ng-container matColumnDef="status">
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                      <td mat-cell *matCellDef="let row"> 
                                        <img style="width: 25px" *ngIf="row.status == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                        <img style="width: 25px" *ngIf="row.status == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                                      </td>
                                    </ng-container>
                                
                                    <!-- Progress Column -->
                                    <ng-container matColumnDef="name">
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                                      <td mat-cell *matCellDef="let row" (click)="navigateServiceDetail(row)"> {{row.serviceId.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                                <div >
                                                 <app-confirm-delete (onconfirm)="deleteService(row)"></app-confirm-delete>
                                               </div> 
                                               </td>
                                            
                                    </ng-container>
                                    <ng-container matColumnDef="filter-status">
                                        <th mat-header-cell *matHeaderCellDef> 
                                         
                                           </th>
                                      </ng-container>
                                    
                
                                      <ng-container matColumnDef="filter-name">
                                        <th mat-header-cell *matHeaderCellDef> 
                                           <mat-form-field appearance="outline">
                                          <input
                                            matInput
                                            (keyup)="servicefilterData('name',$event.target.value)"
                                          />
                                        </mat-form-field> </th>
                                      </ng-container>
                                    
                    
                                
                                    <tr mat-header-row *matHeaderRowDef="serviceDisplayedColumns"></tr>
                                    <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name']"></tr>
                                    <tr mat-row *matRowDef="let row; columns: serviceDisplayedColumns;"></tr>
                                
                                  </table>
                                          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="servicecount" [pageSize]="10"></mat-paginator>
        
                                  <mat-form-field class="my-form-field" appearance="outline">
                                    <input  class="my-input" [formControl]="serviceformControl"
                                        placeholder="Add Service " type="text" matInput [matAutocomplete]="auto2">
                                    <mat-autocomplete class="my-autocomplete" #auto2="matAutocomplete" (optionSelected)="updateInputService($event)"
                                        [displayWith]="displayFn">
                                        <mat-option *ngFor="let item of servicefilteredOptions | async" [value]="item">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button class="btn btn-light custom-btn" (click)="addServices()">
                                    <span class="bi bi-plus-lg" style="margin-right: 10px">
                                    </span>{{"views.home.Add service"| translate}}
                                </button>
                       
                            </div>
                                </accordion-group>
                                </div>
                        </accordion>
                        <div style="display: flex;margin-top: 50%;">
                            <app-terms-conditions></app-terms-conditions>
                        </div>
</div>