import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IdleSessionTimeout } from 'idle-session-timeout';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { getCookie } from 'xsrf-cookie';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  fetchClient(arg0: { clientname: string; }, arg1: (action: any) => void) {
    throw new Error('Method not implemented.');
  }

  private _baseHref: string;
  private _token;
  private _previoususerlistlocation: string;
  emptymessage: string;
  clientFormFlag: boolean;
  clientData: any[];
  commonService: any;
  private session: IdleSessionTimeout;
  private initialCountdown: any;
  countdown: any;
  private countdownInterval: any;
  staySignedIn = false;

  private _frbasehref: string;

  public get frbasehref(): string {
    return this._frbasehref;
  }
  public set frbasehref(value: string) {
    this._frbasehref = value;
  }


  public get previoususerlistlocation(): string {
    return this._previoususerlistlocation;
  }
  public set previoususerlistlocation(value: string) {
    this._previoususerlistlocation = value;
  }
  public get token() {
    return this._token;
  }
  public set token(value) {
    this._token = value;
  }
  private _selectedOrg: any;

  private _titleSub: string;
  public get titleSub(): string {
    return this._titleSub;
  }
  public set titleSub(value: string) {
    this._titleSub = value;
  }
  public get selectedOrg(): string {
    return this._selectedOrg;
  }
  public set selectedOrg(value: any) {
    this._selectedOrg = value;
  }
  public get baseHref(): any {
    return this._baseHref;
  }
  public set baseHref(value: string) {
    this._baseHref = value;
  }
  private languageSubject = new BehaviorSubject<string>('');
  private compLanguageSubject = new BehaviorSubject<string>('');
  constructor(private http: HttpClient, private ngZone: NgZone, private router: Router) 
  {
    if (window.location.host.startsWith('accountqa')) {
        this._frbasehref="https://logonqa.deloitte.com"
        
    } else if(window.location.host.startsWith('accountstage')) {
      this._frbasehref="https://logonstage.deloitte.com"

    }
    else if(window.location.host.startsWith('logonlocal')) {
      this._frbasehref="https://logondev.deloitte.com"

    }else if(window.location.host.startsWith('accountdev')) {
      this._frbasehref="https://logondev.deloitte.com"

    }
    else
     {
      this._frbasehref="https://logon.deloitte.com"
    } 
  }
  
  fetchUserTenantName(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers,withCredentials:true };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/fetchUserTenantName', email, requestOptions);
  }
  
  fetchHelpdeskUserTenantName(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/helpdesk/user/fetchUserTenantName', email, requestOptions);
  }

  fetchAllTechnicalDetails()
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/user/allTechnicalDetails',{ observe: 'response' });
  }
 
  
  getSession() {
    return this.session;
  }

  getAccessToken() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user['accessToken'];
  }

  getCurrentUser() {
    //(localStorage.getItem('authToken'));
    const token= localStorage.getItem('authToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
    return this.http.get<any>(this._baseHref + '/dis/rest/currentuser/user', {headers:headers,observe: 'response'});
  }
  updateUserDetails(id, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any[]>(this._baseHref + '/dis/rest/currentuser/' + id, params, requestOptions);
  }
  getCurrentOrg() {

    return this.http.get<any>('/dis/rest/currentuser/organization',{ observe: 'response' });
  }
  setCurrentOrg(request) {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/currentuser/organization', request, requestOptions);
  }
  getAllAvailableOrgs()
  {
    return this.http.get<any>('/dis/rest/currentuser/organizations',{ observe: 'response' });

  }
  setAllAvailableOrgs(orgs)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/currentuser/organizations', orgs, requestOptions);
  }
  // get all contracts
  getContractDetailsServices(id: any)
  {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contractservice/contract/' + id, { observe: 'response' });
  }
  // get all users
  getAllUsers(){
    return this.http.get<any>(this._baseHref + '/dis/rest/user' );
  }
  userDelete(id: any, request: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/user/activate/' + id, request, requestOptions);
  }
  getAllUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/fetch', user, requestOptions);
  }
  getUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/organizationuser/currentorg/fetch', user, requestOptions);
  }
  getPendingUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/organization/fetch', user, requestOptions);
  }
  getPendingAllUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/fetch', user, requestOptions);
  }
  uniqueValue(email, id,) {

    return this.http.get(this._baseHref + '/dis/rest/userrequest/checkEmail?value=' + email + '&id=' + id,{ responseType: 'text' });
  }
  getuserDetailsCheck(orgid,email)
  {
    return this.http.get(this._baseHref + '/dis/rest/user/checkUserExists?orgid=' + orgid + '&email=' + email,{ responseType: 'text' });

  }
  checkEmailfromorg(email, id) {

    return this.http.get(this._baseHref + '/dis/rest/userrequest/checkEmailfromorg?value=' + email + '&orgid=' + id,{ responseType: 'text' });
  }
  // getRoles() {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `${this.getAccessToken}`
  //   });
  //   const requestOptions = { headers };
  //   return this.http.get(this.baseHref + '/dis/rest/currentuser/roles', requestOptions);
  // }
  getOrgDetails()
  { 
    return this.http.get(this.baseHref + '/dis/rest/userrequest/orgDetails');

  }
  getServices() {
    return this.http.get(this.baseHref + '/dis/rest/service/currentorg');
  }
  getrolesinitial() {
    return this.http.get(this.baseHref + '/dis/rest/role/initial');
  }
  saveUserRequest(userInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/newUser', userInfo, requestOptions);
  }
  addExistingUser(userInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrequest/addUserToOrg', userInfo, requestOptions);
  }
  
  usersbymail(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/mail', email, requestOptions);
  }
  helpdeskUsersbymail(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/helpdesk/user/mail', email, requestOptions);
  }
  usersHistoryBymail(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/history/email', email, requestOptions);
  }
  organizationHistoryBymail(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/organization/history/email', email, requestOptions);
  }
  roleHistoryBymail(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/role/history/email', email, requestOptions);
  }
  emailHistoryBymail(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/emailReferences/email', email, requestOptions);
  }
  saveUserRoleRequest(userRoleInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrolerequest', userRoleInfo, requestOptions);
  }
  saveorguserrequestbymail(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/organizationuser/saveorgusersrequestbymail', email, requestOptions);
  }
  deletePendingUser(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.delete<any>(this._baseHref + '/dis/rest/userrequest/' + id , requestOptions);
  }
  deletePendingOrgUser(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/userrequest/deleteUserFromOrg', requestOptions); 
  
  }
  activateUser( request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/newlinktouser' , request, requestOptions);
  }
  activateAllUser() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/newLinktoallusers', {}, requestOptions);
  }
  saveSuspedUser(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/organizationuser/' + id, request, requestOptions);
  }
  updateUser(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/user/' + id, request, requestOptions);
  }
  updateUserStatus(request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/user/updateUserStatus', request, requestOptions);
  }
  
  updateUserRequest(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrequest/' + id, request, requestOptions);
  }
  userSuspend(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/user/suspend/' + id, request, requestOptions);
  }
  registerUserOtp(email,orgid) {
    const request ={"email":email,"orgId":orgid}
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/currentuser/registerotp',request, requestOptions);
  }
  revokeUserOtp(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/currentuser/revokeotp',email, requestOptions);
  }
  managecred(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/helpDesk/managecredentials',email, requestOptions);
  }
  userDetails(id) {

    return this.http.get(this._baseHref + '/dis/rest/user/' + id);

  }
  userRequestDetails(id) {

    return this.http.get<any>(this._baseHref + '/dis/rest/userrequest/' + id);

  }
  userRoleRequestDetails(id) {

    return this.http.get<any>(this._baseHref + '/dis/rest/userrequest/userrolerequest/' + id);

  }
  userRoleHistoryDetails(id) {

    return this.http.get<any>(this._baseHref + '/dis/rest/user/role/history/' + id);

  }
  userHistoryDetails(id) {

    return this.http.get<any>(this._baseHref + '/dis/rest/user/history/' + id);

  }
  getFederatedClientData(id) {

    return this.http.get<any>(this._baseHref + '/dis/rest/user/getFederatedClientData/' + id);

  }
  commonmethodtogetHistory(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/history',request, requestOptions); 
  }
  sendrequestlink(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/public/activation/requestlink',email, requestOptions); 
  }
  getTenant()
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/public/samlvalidate/getTenant',{ observe: 'response' });

  }
  userOrgHistoryDetails(id) {
    return this.http.get<any>(this._baseHref + '/dis/rest/user/organization/history/' + id);
  }
  organizationDetails(id) {
    return this.http.get<any>(this._baseHref + '/dis/rest/organizationuser/user/' + id);
  }
  rolesDetails(id) {
    return this.http.get<any>(this._baseHref + '/dis/rest/userrole/user/' + id);
  }
  resetPassword(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/sendPwdResetLink', email,requestOptions);
  }

  registerOtp(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/user/registerotp/' + id);

  }
registerOtpreset(id)
{
  
  return this.http.get<any>(this._baseHref + '/dis/rest/user/registerotp/' + id);
}
  registerAuthApp(request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/registerotp/email',request, requestOptions);
  }
  revokeAuthApp(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/revokeotp', email,requestOptions);
 
  }
  suspendRole(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this._baseHref + '/dis/rest/userrole/' + id, request, requestOptions);
  }
  /**New Code * */
  updateRoleAssignmentStatus(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrole/updateRoleAssignmentStatus', request, requestOptions);
  }
    /**New Code * */
  deleteRoleAssignment(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post(this._baseHref + '/dis/rest/userrole/deleteRoleAssignment', request, requestOptions);
  }
 
  setLanguage(lang: string) {
    this.languageSubject.next(lang);
  }
  requireReRegister(id)
  {
    return this.http.get<any>(this._baseHref + '/dis/rest/user/removeMfa/' + id);
  }
  getLanugage(): Observable<any> {
    return this.languageSubject.asObservable();
  }

  setComponentLanguage(lang: string) {
    this.compLanguageSubject.next(lang);
  }
  getCompLanguage(): Observable<string> {
    return this.compLanguageSubject.asObservable();
  }
  batchUploadModule(file)
  {
    const headers = new HttpHeaders({
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    const formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(this._baseHref + '/dis/rest/user/batchupload', formParams, { reportProgress: true, observe: 'events', headers });
  }
 
 
  deleteCurrentUserRole(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/role/delete/' + id, { id }, requestOptions);
  }
  deleteRoleAssignment_old(id, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrole/' + id, params, requestOptions);
  }
  deleteRoleRequest(id, params) {
    // userrolerequest
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrolerequest/' + id, params, requestOptions);
  }
  suspendCurrentUserRole(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/role/suspend/' + id, { id }, requestOptions);
  }
  suspendRoleAssignment(id, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrole/' + id, params, requestOptions);
  }
  activateCurrentUserRole(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/role/activate/' + id, { id }, requestOptions);
  }
  activateRoleAssignment(id, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrole/' + id, params, requestOptions);
  }
  activateRolerequest(id, params) {
    // activateRoleRequest
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put(this._baseHref + '/dis/rest/userrolerequest/activate' + id, params, requestOptions);
  }
  batchOrganizationUpload(file) {
    const headers = new HttpHeaders({
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    const formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(this._baseHref + '/dis/rest/organization/batchupload', formParams, { reportProgress: true, observe: 'events', headers });
  }
    userHistoryByEmail(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/history/email', email, requestOptions);
  }
  userOrganizationHistory(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/user/organization/history/email', email, requestOptions);
  }
  sendReports(reportname,orgid): Observable<any>
  {
    const request ={"reportType":reportname,"orgId":orgid}
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/stats/reports', request, requestOptions).pipe(
      catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      if (error.status === 200) {
        errorMessage = 'Error parsing JSON response';
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    return throwError(errorMessage);
  }
  saveFederation(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/federation/newClient', params, requestOptions);
  }
  getFederatedClientByDomain(domain)
  {
    const headers = new HttpHeaders({

    'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.get<any>(this._baseHref + '/dis/rest/federation/checkDomainExists/'+domain);
  } 
  
  updateFederation(id,params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this._baseHref + '/dis/rest/federation/updateFederatedClient/'+id, params, requestOptions);
  }
  getFederations(clientName: string): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.get(this._baseHref + `/dis/rest/federation/getClient/${clientName}`, requestOptions);
  }
  
  getClientByDomain(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.get<any>(this._baseHref + `/dis/rest/federation/getClientByDomain?email=${email}`, requestOptions);
  }
  // getSelfService(sendData){
  //   const headers = new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Accept-API-Version': 'resource=2.1,protocol=1.0'
  //   });
  //   const requestOptions = { headers,withCredentials :true};
  //   return this.http.post<any>("https://logonqa.deloitte.com/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=UserSelfService", sendData,requestOptions);
  // }
  // getSelfServiceAuthApp(sendData){
  //   const headers = new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Accept-API-Version': 'resource=2.1,protocol=1.0'
  //   });
  //   const requestOptions = { headers,withCredentials :true};
  //   return this.http.post<any>("https://logonqa.deloitte.com/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=AuthAppRegistration", sendData,requestOptions);
  // }
  getSelfService(sendData,authIndexType,authIndexValue){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-API-Version': 'resource=2.1,protocol=1.0'
   });
   const requestOptions = { headers,withCredentials :true};
   return this.http.post<any>(this._frbasehref+"/am/json/realms/O365/authenticate?authIndexType="+authIndexType+"&authIndexValue="+authIndexValue+"", sendData,requestOptions);
    
  }
}
