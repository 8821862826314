<div id="userRequestList" [hidden]="!(authorized())">

  <ol class="breadcrumb breadcrumb-dis">
    <li class="breadcrumb-item"><a [routerLink]="['']">{{'views.userrequest.Home' | translate}}</a></li>
    <li class="breadcrumb-item active">{{'views.userrequest.Pending users for'| translate}} {{currentOrg?.name}}</li>
    <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{'views.userrequest.Log out' | translate}}</a></li>
</ol>

<div class="titleicon"><img src="assets/images/homepage/users.png" alt="{{'views.userrequest.Userrequest management' | translate}}"/></div>
<h1>{{'views.userrequest.Pending users for'| translate}} {{currentOrg?.name}}</h1>
<!--The alerts come here -->
<!-- <alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)" class="">{{alert.msg}}</alert> -->

  <div class="card" style="overflow-x: auto;">
    <div class="card-header" style="display: flex; justify-content: space-between; align-items: center;">
      <div style="display: flex; align-items: center;">
        <img src="assets/images/homepage/users.png" alt="{{'views.organization.Users'| translate}}" width="30px" style="margin-right: 10px;" />
        <span>{{'views.userrequest.Pending users'| translate}} ({{resultsLength}})  
          </span>
          <div class="loader"   *ngIf="isLoadingResults">
            <div class="dot" style="--dot-index:0;"></div>
            <div class="dot" style="--dot-index:1;"></div>
            <div class="dot" style="--dot-index:2;"></div>
          </div> 
      </div>
    
    </div>
      <div class="example-container mat-elevation-z8" style="overflow-x: auto;">
       
        <div class="example-table-container">
          <div *ngFor = "let alert of alerts">
            <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                {{alert.msg}}
                <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
             float:right;
    font-size: 22px; "></i>
            </p>
        </div>
          <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort
                 matSortActive="lastname" matSortDisableClear matSortDirection="asc"
                 (matSortChange)="resetPaging()">
          
      
            <!-- Title Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}</th>
              <td mat-cell *matCellDef="let row">
               
            <img style="width: 25px" *ngIf="row.accountStatus === 'pending'"
                src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                title="{{'views.user.active' | translate}}" />
              </td>
            </ng-container>
      
          
            <ng-container matColumnDef="lastname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Last name'|translate}}</th>
              <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">{{row.sn}}</td>
            </ng-container>
      
           
            <ng-container matColumnDef="firstname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
               {{'views.user.First name'|translate}}</th>
              <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">
                <span style="margin: 0;">{{row.givenName}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                  {{'views.user.Email'|translate}}
                </th>
                <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">
                  <span style="margin: -22px;">{{row.mail}}</span></td>
              </ng-container>
              <ng-container matColumnDef="action1">
                <th mat-header-cell *matHeaderCellDef>
                 
                <!-- <div *ngIf="row.userId.status  === 'suspended'">
                    <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                    <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                </div> -->
                </th>
                <td mat-cell *matCellDef="let row" style="font-size: 11px;
                width: 47px;">
                    <button (click)="sendNewLink(row)" style="width: 67px;
                    color: #333;
                    font-weight: 400;
                    margin: 0 -15px">
                        {{'views.userrequest.Send activation link'| translate}}
                    </button>

                </td>
              </ng-container>
              <ng-container matColumnDef="action2">
                <th mat-header-cell *matHeaderCellDef  >
                 
                <!-- <div *ngIf="row.userId.status  === 'suspended'">
                    <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                    <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                </div> -->
                </th>
                <td mat-cell *matCellDef="let row">
                   <div style="
   margin: -6px -4px 0px;
    width: 67px;
    color: #333;
    font-weight: 400;">
                    <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>

                   </div>

                </td>
              </ng-container>
               <!-- Item Description Column -->
<ng-container matColumnDef="filter-status">
<th mat-header-cell *matHeaderCellDef> 
 
   </th>
</ng-container>

<!-- Cost Description Column -->
<ng-container matColumnDef="filter-lastname">
<th mat-header-cell *matHeaderCellDef> 
  <mat-form-field appearance="outline" style="width:80px !important">
    <input
    matInput
    (keyup)="applyFilterbyLastName($event.target.value)"
    
  />
</mat-form-field> </th>
</ng-container>
<!-- Item Description Column -->
<ng-container matColumnDef="filter-firstname">
<th mat-header-cell *matHeaderCellDef>
  <mat-form-field appearance="outline" style="width:93px !important">
    <input
      matInput
      (keyup)="applyFilterbyFirstName($event.target.value)"
     
    />
  </mat-form-field>
</th>
</ng-container>

<!-- Cost Description Column -->
<ng-container matColumnDef="filter-email">
<th mat-header-cell *matHeaderCellDef> 
  <mat-form-field appearance="outline" style="width:247px !important">
    <input
    matInput
    (keyup)="applyFilterbyEmail($event.target.value)"
    
  />
</mat-form-field> </th>
</ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-lastname','filter-firstname','filter-email']"
  class="example-second-header-row">
</tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"></mat-paginator>
      </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" style="width:159px" class="btn btn-light custom-btn" (click)="gotoHomePage()">
                <span class="bi bi-house-up-fill" style="margin-right: 10px"></span>
                {{'views.user.Home' | translate}}
            </button>
        </div>
        <div class="btn-group" *ngIf="!(isHelpDeskAdmin())">
            <button type="button" class="btn btn-light custom-btn" style="width:496px"(click)="gotoUserRequestNewPage()">
                <span class="bi bi-plus-lg" style="margin-right: 10px"></span>
                {{'views.user.Register user for organization' | translate}} {{currentOrg?.name}}
            </button>
        </div>
    </div>
      
</div>
<app-terms-conditions></app-terms-conditions>
  </div>
