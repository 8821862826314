<div id="roleassignmentNew" [hidden]="!(authorized())">

    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a href="" routerLink="/home">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="breadcrumb-item"><a href="" routerLink="/roleassignment/list">{{'views.roleassignmentorg-list.Role Assignments for'| translate}} {{currentOrganization.name}}</a></li>
        <li class="breadcrumb-item active">{{'views.roleassignmentorg-new.New Role Assignment for'| translate}}
            {{currentOrganization.name}}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{"views.home.Log out"| translate}}</a></li>
    </ol>

    <!-- <div class="titleicon"><img src="images/homepage/roles.png" alt="role management" /></div> -->
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
    <h1>{{'views.roleassignmentorg-new.New Role Assignment for'| translate}} {{currentOrganization.name}}</h1>
    <div class="card">
        <div class="card-header"></div>
        <div class="card-body">
            <form name="roleassignmentForm" class="form-horizontal" role="form" [formGroup]="roleAssignmentForm">
                <div class="mb-3 row" show-errors>
                    <label for="role.startDate" class="col-sm-2 control-label ">{{'views.service.Start date'|
                        translate}}</label>
                    <!-- <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div> -->

                    <div class="col-sm-7">
                        <input type="date" [attr.disabled]="true"  class="form-control" formControlName="startDate">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleAssignmentForm.controls['startDate'].hasError('required') && roleAssignmentForm.controls['startDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="role.endDate" class="col-sm-2 control-label ">{{'views.service.End date'|
                        translate}}</label>
                    <!-- <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div> -->
                    <div class="col-sm-7">
                        <input type="date" class="form-control" formControlName="endDate"
                            (change)="dateChanges($event.target.value)">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleAssignmentForm.controls['endDate'].hasError('required') && roleAssignmentForm.controls['endDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div>
                <!-- <div class="mb-3 row" show-errors>
                    <label for="role.technicalId" class="col-sm-2  control-label ">{{'views.role.Technical ID' |
                        translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="technicalId"
                            (keyup)="validateTechnicalId($event)">
                    </div>
                    <div class="col-sm-3">
                        <p class="help-block"
                            *ngIf="roleForm.controls['technicalId'].hasError('required') && roleForm.controls['technicalId'].touched">
                            {{'views.role.Required' |
                            translate }}</p>
                        <p class="help-block" *ngIf="roleForm.controls['technicalId'].hasError('pattern')">
                            {{'views.role.The technical ID can only contain alphanumeric values.' | translate }}</p>
                    </div>
                </div> -->
                <div class="mb-3 row" show-errors>
                    <label for="roleassignment.user" class="col-sm-2 control-label ">{{"views.role.User"|
                        translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" (keyup)="filterUsers($event.target.value)" class="form-control"
                        formControlName="user" [matMenuTriggerFor]="menu1" #userTrigger="matMenuTrigger" (input)="fetchUsers()" placeholder="To Search please minimum three letters in email ">
                    <div class="col-sm-2" style="max-width: none;margin:-31px 379px"
                            *ngIf="roleAssignmentForm.controls['user'].hasError('required') && roleAssignmentForm.controls['user'].touched">
                            <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                        </div>
                        <div class="col-sm-2" style="max-width: none;margin:-31px 379px"  *ngIf="roleAssignmentForm.controls.user.touched && currentUsers?.length<=0">
                            <span class="hint-text call-send-text">No Users Found</span>
                           </div>
                           <mat-menu #menu1="matMenu" >
                            <ng-container *ngFor="let user of currentUsers">
                                <button mat-menu-item (click)="selectUser(user)">{{user?.sn}}
                                    {{user?.givenName}} ({{user?.mail}})</button>
                            </ng-container>
                        </mat-menu>
                       

                    </div>
                </div>
                <div class="mb-3 row" show-errors>
                    <label for="roleassignment.roleId" class="col-sm-2 control-label ">{{'views.organization.Role'|
                        translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="role" placeholder="Search role"
                             placeholder="Search for roles" [matMenuTriggerFor]="menu2"  #roleTrigger="matMenuTrigger">
                        <mat-menu #menu2="matMenu" >


                            <ng-container  *ngFor="let role of currentRoles">
                                <button mat-menu-item (click)="selectRole(role)">{{role?.name}}-
                                    {{role?.description}} ({{role?.technicalId}})</button>
                            </ng-container>
                        </mat-menu>
                       <div *ngIf="roleAssignmentForm.controls.role.touched && currentRoles?.length<=0">
                        <span>No roles Found</span>
                       </div>
                    </div>
                </div>
                <!-- <div class="mb-3 row" *ngIf="selectedRole?.technicalId==='DIAS'">
                    <label for="role.serviceId" class="col-sm-2 control-label ">{{'views.roleassignment-detail.Target Service'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" placeholder="Search Service" formControlName="serviceId"
                            (keyup)="filterService($event.target.value)" data-toggle="dropdown"
                            class="dropdown-toggle form-control" [matMenuTriggerFor]="menu">
                        <mat-menu #menu="matMenu">
                            <ng-container *ngFor="let service of availableServices">
                                <button mat-menu-item (click)="selectService(service)">{{service.name}}</button>
                            </ng-container>
                        </mat-menu>
                    </div>
                    <div class="col-sm-3">
                        <p class="help-block" ng-if="roleForm.serviceId.$error.required">{{'views.role.Required' |
                            translate }}</p>
                    </div>
                </div> -->
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button" class="btn btn-light custom-btn" (click)="saveRoleAssignment()"
                            [disabled]="!roleAssignmentForm.valid">
                            <span class="bi bi-floppy-disk"
                                style="margin-right: 10px"></span>{{'views.role.Save' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            {{"views.roleassignment-new.Batch Upload Role Assignments"| translate}}
        </div>
        <div class="card-body">
            <div style="float:right">
                <button type="button" style="    width: 41px;
                height: 29px;
                margin-right: 10px;" class="butnfntsize btn btn-light custom-btn btn-xs" (click)="showhelp = !showhelp"
                    title="{{'views.user.Show help' | translate}}">
                    <span class="bi bi-question-lg" style="margin-right: 10px"></span>
                </button>
            </div>

            <div *ngIf="showhelp" style="overflow: auto; display: flex; flex-direction: column; gap: 10px">

              <div>
                <p><span>{{'views.roleassignment.Batch Upload Desc' | translate}}</span></p>
              </div>

              <div>
                <p>
                  <span>{{'views.roleassignment.Supported Operations available for' | translate}}</span>
                  &nbsp;<span><strong>App Admin</strong></span>&nbsp;
                  <span>{{'views.roleassignment.and' | translate}}</span>
                  &nbsp;<span><strong>CIAM Admin</strong></span>&nbsp;
                  <span>{{'views.roleassignment.are' | translate}}</span>
                </p>
              </div>

              <div style="margin-left: 50px">
                <ul style="list-style-type: disc">
                  <li><strong>CREATE</strong>&nbsp;<span>({{'views.roleassignment-Assign App specific Role' | translate}})</span></li>
                  <li><strong>ACTIVATE</strong>&nbsp;<span>({{'views.roleassignment-Reactivate App specific Role' | translate}})</span></li>
                  <li><strong>SUSPEND</strong>&nbsp;<span>({{'views.roleassignment-Suspend App specific Role' | translate}})</span></li>
                  <li><strong>DELETE</strong>&nbsp;<span>({{'views.roleassignment-Remove App specific Role' | translate}})</span></li>
                </ul>
              </div>

              <div>
                <p>
                  <span>{{'views.roleassignment-An application specific role can be assigned/suspended/reactivated/deleted to a client user' | translate}}</span>
                  &nbsp;<span><strong><u>{{'views.roleassignment-only' | translate}}</u></strong></span>&nbsp;
                  <span>{{'views.roleassignment-within the CIAM organization the user has been assigned to' | translate}}</span>
                </p>
              </div>

              <div>
                <p><strong>{{'views.roleassignment-Template for Assign a role batch upload' | translate}}</strong></p>
                <div style="margin-left: 50px">
                  <ul style="list-style-type: disc">
                    <li>{{'views.roleassignment-The CSV input file needs to be saved in plain format' | translate}}</li>
                    <li>{{'views.roleassignment-It should contain lines in the following format. It must not contain any headers' | translate}}</li>
                    <li>{{'views.roleassignment-Please note, operations should always be in lower case, but attributes are not case sensitive' | translate}}</li>
                  </ul>
                </div>
              </div>

              <div>
                <p>Create;Email;RoleTechID;OrgTechID;RoleEndDate(yyyy-mm-dd)</p>
                <p>Activate;Email;RoleTechID;OrgTechID;RoleEndDate(yyyy-mm-dd)</p>
                <p>Suspend;Email;RoleTechID;OrgTechID;RoleEndDate(yyyy-mm-dd)</p>
                <p>Delete;Email;RoleTechID;OrgTechID;RoleEndDate(yyyy-mm-dd)</p>
              </div>

              <div>
                <p><strong>{{'views.user.Note:' | translate}}</strong></p>
                <div style="margin-left: 50px">
                  <ul style="list-style-type: disc">
                    <li>{{'views.roleassignment-Requester will be notified by email when the operation has been completed' | translate}}</li>
                    <li>{{'views.roleassignment-Email attachment will include the results, listing success/failure for each operation included in the input file' | translate}}</li>
                  </ul>
                </div>
              </div>

              <div>
                <p>{{'views.roleassignment-Examples for each operation' | translate}}: </p>
                <div style="margin-left: 50px">
                  <p>create;user1@test.com;ROLE1; CIAMDemoOrgTechID;2025-08-30</p>
                  <p>activate;user2@test.com;ROLE2; CIAMDemoOrgTechID;2025-08-30</p>
                  <p>suspend;user3@test.com;ROLE3; CIAMDemoOrgTechID;2025-08-30</p>
                  <p>delete;user4@test.com;ROLE; CIAMDemoOrgTechID;2025-08-30</p>
                </div>
              </div>

              <br/>

            </div>

            <div *ngIf="selected">
                <p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size
                        / 1000 | number:0}} kb)</span></p>
            </div>
            <div *ngIf="showprogress" class="progress" style="height: 20px">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progresswidth}"
                    aria-valuenow="(progressvalue / progressmax)*100 | number:0" aria-valuemin="0" aria-valuemax="100">
                    {{(progressvalue / progressmax)*100 | number:0 }}%
                </div>
            </div>
            <div class="btn-group">
                <button type="button" class=" butnfntsize btn btn-light custom-btn" (click)="download()" title="Download file">
                    <span class="bi bi-save" style="margin-right: 10px"></span>
                    {{"views.user.Download batch upload template"| translate}}
                </button>
            </div>
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <div class="choose_file">
                        <button style="width:315px" type="button" class="butnfntsize btn btn-light custom-btn btn-group" title="Select file">

                            <span class="bi bi-paperclip" style="margin-right: 10px"></span>
                            {{'views.user.Select your CSV file' | translate}}
                        </button>
                        <input type="file" (change)="selectfile($event)" accept=".csv,.CSV">
                    </div>
                </div>
                <div class="btn-group">
                    <button style="width:342px" [attr.disabled]="selected==false ? '':null" type="button" class="butnfntsize btn btn-light custom-btn"
                        (click)="upload()" title="Upload file">
                        <span class="bi bi-upload" style="margin-right: 10px"></span>
                        {{'views.user.Upload file' | translate}}
                    </button>
                </div>
            </div>

        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>
</div>
