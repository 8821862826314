import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommonService } from '../../common.service';
import { MatTableDataSource } from '@angular/material/table';
import { RolesService } from '../../roles-service.service';
import { PermissionService } from '../../permission.service';
import { Router } from '@angular/router';
import { element } from 'protractor';



@Component({
  selector: 'app-all-role-assignments',
  templateUrl: './all-role-assignments.component.html',
  styleUrls: ['./all-role-assignments.component.css']
})
export class AllRoleAssignmentsComponent implements OnInit, AfterViewInit {
  request: any;

  constructor(private commonService: CommonService, private roleService: RolesService,  private permission: PermissionService,private router: Router) { }
  filteredAndPagedIssues = new MatTableDataSource<any>([]);
  isLoadingResults = false;
  isFilteringResults = false;
  alltxt = 'All';
  filter = {};
  displayedColumns: string[] = ['status', 'email', 'role', 'action'];
  resultsLength = 0;
  originalData: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
  this.apiTrigger();
  }
  
  
  ngAfterViewInit() {
    this.filteredAndPagedIssues.paginator = this.paginator;
  }
authorized()
{
   return this.permission.isAdmin ;

}
  apiTrigger() {
    this.isLoadingResults = true;
    this.roleService.getAllRoleAssignmentsList().subscribe((data: any) => {
      let filteredRoles = data.message.filter(role => role.roleName !== 'adminOfOrg');
      this.filteredAndPagedIssues.paginator = this.paginator;
     this.filteredAndPagedIssues.data = filteredRoles;
     this.originalData = filteredRoles;
     this.resultsLength = filteredRoles.length;
     this.isLoadingResults = false;
    }, () => {
      this.isLoadingResults = false;
   });
  }
  applyFilterbyRole(filterValue: string) {
   this.filterData('roleName',filterValue);
  }
  applyFilterbyEmail(filterValue: string) {
    const e = filterValue!=""?filterValue.toLowerCase():"";

    this.filterData('mail',e);

  }
  
  deleteRole(row)
  {
    const currentUser=JSON.parse(localStorage.getItem('currentUserDetails'));
    if(currentUser['mail']===row.mail)
    {
      alert("you can't revoke access to your own account");
    }
    else
    {
      this.commonService.usersbymail(row.mail).subscribe(data =>{
        const authroles = data.authzRoles;
        let request= 
         [ {
              "operation": "remove",
              "field": "/authzRoles",
              "value": {}
              
          }]
      ;
      if(authroles.length>1)
      {
        authroles.forEach(element =>{
          if(element._refResourceId===row.roleName)
          {
            request[0]['value']=element;
            this.commonService.updateUser(data._id,request).subscribe(data => {
              if(data.code==0)     
               this.apiTrigger();
            });
          }
        })
      }
      else
      {
        request[0]['value']=authroles[0];
        this.commonService.updateUser(data._id,request).subscribe(data => {
          this.apiTrigger();
        });
      }
      
       
      })
    }
   

      
  }
  
 
  filterData(key, value) {
    this.isFilteringResults = true;
    setTimeout(() => {
    this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
      let rowData;
        rowData = item[key].toLowerCase();
      return rowData.includes(value.toLowerCase());
    });
    this.isFilteringResults = false;
  }, 300);
  }
  resetPaging() {
    this.apiTrigger();
  }


}
