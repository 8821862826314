
<!-- 
<div class="example-loading-shade" [hidden]="!spinnerFlag">
    <mat-spinner *ngIf="spinnerFlag"></mat-spinner>
</div> -->
<div id="HomePage">
    <div class="d-flex justify-content-between align-items-center">
        <p><br/><span>{{'views.home.Welcome'| translate}} <span *ngIf="currentuser!==undefined">{{currentuser.givenName}}! </span></span></p>
    <p>
        <span class="float-end text-decoration-none">
            <a href="oidc/breadcrumb-item">{{"views.home.Log out"|
                translate}}</a></span></p>
    </div>
    <div class="mb-3 row" *ngIf="authorizedUsers()" >
        <p><span>{{'views.home.Current organization:'| translate}} {{selectedOrgName}}</span></p>
        <!-- <input *ngIf="organizations.length > 1" class="form-control" style="width: auto" ng-focus="hasfocus"
            id="organizationselectinput" placeholder="{{'views.home.Change organization:' | translate}}" type="text"
            ng-model="selected" [formControl]="organization"
            typeahead="organization as organization.name for organization in organizations | filter:$viewValue:stateComparator | orderBy:'name'" /> -->

        <div style="font-size: 75%;">
        <mat-form-field class="my-form-field" appearance="outline"
        style="margin-bottom: -10px; height: 60px;width:324px !important">
            <input style="font-size: 14px;" [formControl]="myControl"
                placeholder="Search Organizations: " matInput [matAutocomplete]="auto1">

            <mat-autocomplete class="my-autocomplete" #auto1="matAutocomplete" (optionSelected)="updatePortCode($event)"
                [displayWith]="displayFn">
                <mat-option [matTooltip]="item.name" class="width: fit-content" *ngFor="let item of filteredOptions | async | sort: 'asc':'name'" [value]="item">
                  <span class="option-text"> {{item.name}}</span> 
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        </div>
    </div>
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
                       <button type="button" class="btn-close" aria-label="Close" (click)="closeAlert(i)"></button>

        </p>
    </div>
    <div *ngIf="!authorizedUsers()" class="mb-3 row" style="visibility: hidden; height: 2px;"></div>
   
    <accordion style="display: block;width: 100%; margin-top: 3%;">
        <div id="usermgt" *ngIf="hasAlladminTypes()" class="home-accordion">
            <accordion-group  [isOpen]="activeGroup === 'group1'" (click)="onGroupChange('group1')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/users.png" alt="{{'views.home.User management'| translate}}"
                            width="50%" />
                    </div>{{"views.home.User management"| translate}}

                </div>
                <div class="btn-group btn-group-justified">

                    <div class="btn-group">
                        <button type="button"  style="width:304px"class="btn btn-light custom-btn" (click)="gotoUserListPage()"
                            title="{{'views.home.View the users for'| translate}} {{selectedOrgName}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View users"| translate}}</button>
                    </div>
                    <div class="btn-group"  *ngIf="hasOrgAdminandAdmin()">
                        <button type="button" class="btn btn-light custom-btn" (click)="gotoUserRequestNewPage()"
                            title="{{'views.home.Register a new user for'| translate}} {{selectedOrgName}}">
                            <span class="bi bi-plus-lg"
                                style="margin-right: 10px"></span>{{"views.home.Register user"| translate}}</button>
                    </div>

                    <div class="btn-group">
                        <button type="button" class="btn btn-light custom-btn" (click)="gotoUserRequestListPage()"
                            title="{{'views.home.View pending users for'| translate}} {{selectedOrgName}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View pending users"|
                            translate}}</button>
                    </div>
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="hasHelpDeskAdminandAdmin()">
                        <button type="button" style="width:300px" class="btn btn-light custom-btn" (click)="gotoUserListAllPage()"
                            title="{{'views.home.View all users in DIS'| translate}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View all users"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasHelpDeskAdminandAdmin()">
                        <button type="button" style="width:350px" class="btn btn-light custom-btn" (click)="gotoUserRequestListAllPage()"
                            title="{{'views.home.View all pending users'| translate}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View all pending"| translate}}</button>
                    </div>
                </div>
            </accordion-group>
        </div>
        <div id="userselfservice" class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group2'" (click)="onGroupChange('group2')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">


                        <img src="assets/images/homepage/users.png" alt="{{'views.home.User self-service'| translate}}"
                            width="50%" />
                    </div>
                    <a routerLink="/selfservice" style="color:black;ont-weight: 400;">{{"views.home.User self-service"|
                        translate}}
                    </a>


                </div>
            </accordion-group>

        </div>
        <div id="userbatch" *ngIf="hasOrgAdminandAdmin()" class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group3'" (click)="onGroupChange('group3')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/batch.png"
                            alt="{{'views.home.Batch user management'| translate}}" width="50%" />
                    </div>{{"views.home.Batch user management"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="hasOrgAdminandAdmin()">
                        <button type="button" class="btn btn-light custom-btn" style="width:662px" (click)="gotoUserBatchPage()"
                            title="{{'views.home.Perform batch operations on users'| translate}}">
                            <span class="bi bi-justify"
                                style="margin-right: 10px"></span>{{"views.home.Batch operations"| translate}}</button>
                    </div>
                </div>
            </accordion-group>
        </div>
        <div id="rolemgt" *ngIf="hasOrgAdminandAdmin() " class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group4'" (click)="onGroupChange('group4')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/roles.png" alt="{{'views.home.Role management'| translate}}"
                            width="50%" />
                    </div>{{"views.home.Role management"| translate}}
                </div>
                <div class="btn-group btn-group-justified btnjust">
                    <div class="btn-group" *ngIf="hasCIAMAdmin()">
                        <button style="width:320px" type="button" class="btn btn-light custom-btn" (click)="gotoRoleListPage()" title="{{'views.home.View roles'| translate}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View roles"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasCIAMAdmin()">
                        <button style="width:336px" type="button" class="btn btn-light custom-btn" (click)="gotoRoleNewPage()" title="{{'views.home.Add role'| translate}}">
                            <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{"views.home.Add role"| translate}}</button>
                    </div>
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="hasOrgAdminandAdmin()">
                        <button style="width:320px" type="button" class="btn btn-light custom-btn" (click)="gotoRoleAssignmentNewPerOrgPage()" title="{{'views.home.Assign a role'| translate}}">
                             <span class="bi bi-link"
                                style="margin-right: 10px"></span>{{"views.home.Assign a role"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasOrgAdminandAdmin()">
                        <button style="width:336px"type="button" class="btn btn-light custom-btn" (click)="gotoRoleAssignmentListPage()" title="{{'views.home.View role assignments'| translate}}">
   <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View role assignments"|
                            translate}}</button>
                    </div>
                   
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="hasCIAMAdmin()">
                        <button style="width:320px" type="button" class="btn btn-light custom-btn" (click)="gotoRoleAssignmentNewPage()" title="{{'views.home.Assign a role (admin)'| translate}}">
                            <span class="bi bi-link"
                                style="margin-right: 10px"></span>{{"views.home.Assign a role (admin)"|
                            translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasCIAMAdmin()">
                        <button style="width:336px" type="button" class="btn btn-light custom-btn" (click)="gotoAllRoleAssignmentListPage()" title="{{'views.home.View all role assignments'| translate}}">
                            <span class="bi bi-search"
                                style="margin-right: 10px"></span>{{"views.home.View Admin Roles"|
                            translate}}</button>
                    </div>
    
                </div>
            </accordion-group>
        </div>
        <!-- <div id="contractmgt" *ngIf="authorizedContracts()" class="home-accordion">
            <accordion-group>
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/contracts.png" alt="{{'views.home.View contracts'| translate}}" width="50%"/>
                </div>
                {{"views.home.Contract management"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" >
                        <button type="button" class="btn btn-light custom-btn" (click)="gotoContractListPage()" title="{{'views.home.View contracts'| translate}}">
                            <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.home.View contracts"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="authorizedForReports()">
                        <button type="button" class="btn btn-light custom-btn" (click)="gotoContractNewPage()" title="{{'views.home.Add contract'| translate}}">
                            <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.home.Add contract"| translate}}</button>
                    </div>
                </div>
                
            </accordion-group>
        </div> -->
        <div id="orgmgt" *ngIf="hasCIAMAdmin()" class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group5'" (click)="onGroupChange('group5')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/organizations.png" alt="{{'views.home.Organization management'| translate}}"
                            width="50%" />
                    </div>{{"views.home.Organization management"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button style="width:215px" type="button" class="btn btn-light custom-btn" (click)="gotoOrganizationListPage()" title="{{'views.home.View organizations'| translate}}">
                        <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.home.View organizations"| translate}}</button>
                </div>
                <div class="btn-group">
                    <button style="width:215px" type="button" class="btn btn-light custom-btn" (click)="gotoOrganizationNewPage()" title="{{'views.home.Add organization'| translate}}">
                        <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{"views.home.Add organization"| translate}}</button>
                </div>
                <div class="btn-group">
                    <button style="width:235px"  type="button" class="btn btn-light custom-btn" (click)="gotoOrganizationBatchPage()" title="{{'views.home.Batch operations'| translate}}">
                        <span class="bi bi-justify" style="margin-right: 10px"></span>{{"views.home.Batch operations"| translate}}</button>
                </div>
            </div>
            </accordion-group>
        </div>
        <div id="servicemgt" *ngIf="hasCIAMAdmin() " class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group6'" (click)="onGroupChange('group6')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/services.png" alt="{{'views.home.Service management'| translate}}"
                            width="50%" />
                    </div>{{"views.home.Service management"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button style="width:325px" type="button" class="btn btn-light custom-btn" (click)="gotoViewServicesPage()" title="{{'views.home.View services'| translate}}">
                        <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.home.View services"| translate}}</button>
                </div>
                <div style="width:325px" class="btn-group">
                    <button type="button" class="btn btn-light custom-btn" (click)="gotoAddServicePage()" title="{{'views.home.Add service' | translate}}" >
                        <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{"views.home.Add service"| translate}}</button>
                </div>
            </div>
            </accordion-group>
        </div>
        <div id="report" *ngIf="hasOrgAdminandAdmin()" class="home-accordion">
            <accordion-group  [isOpen]="activeGroup === 'group7'" (click)="onGroupChange('group7')">
                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/report.png" alt="{{'views.home.Report'| translate}}" width="50%"/>
                </div>
                {{"views.home.Report"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="hasOrgAdminandAdmin()">
                        <button style="width:315px" type="button" class="btn btn-light custom-btn" (click)="sendReport()" title="{{'views.home.Generate report for'| translate}} {{selectedOrgName}}">
                   <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.report.Generate report"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasOrgAdminandAdmin()">
                        <button style="width:315px"  type="button" class="btn btn-light custom-btn" (click)="sendOrgEmailOtpReport()" title="{{'views.home.Generate report for'| translate}} {{selectedOrgName}}">
 <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.report.Generate Email OTP report"| translate}}</button>
                    </div>
                </div>
                <div class="btn-group btn-group-justified">
    
                    <div class="btn-group"  *ngIf="hasCIAMAdmin()">
                        <button style="width:630px"  type="button" class="btn btn-light custom-btn" (click)="sendApplicationReport()" title="{{'views.report.Generate Application Report'| translate}}">
                            <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.report.Generate Application Report"| translate}}</button>
                    </div>
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group"  *ngIf="hasCIAMAdmin()">
                        <button type="button" class="btn btn-light custom-btn" (click)="sendTotalUsersCountReport()" title="{{'views.report.Generate Total Users Count Report'| translate}}">
                            <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.report.Generate Total Users Count Report"| translate}}</button>
                    </div>
                    <div class="btn-group" *ngIf="hasCIAMAdmin()">
                        <button style="width:321px"  type="button" class="btn btn-light custom-btn" style="overflow: hidden;white-space: nowrap;display: block;text-overflow: ellipsis;" (click)="sendApplicationAdminReport()" title="{{'views.report.Generate Application Admin Report'| translate}}">
                            <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.report.Generate Application Admin Report"| translate}}</button>
                    </div>
                </div>
                
            </accordion-group>
        </div>
      
        <div id="helpdesk" *ngIf="hasHelpDeskAdminandAdmin()" class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group8'" (click)="onGroupChange('group8')">

                <div accordion-heading style="width:100%;">
                    <div style="width: 50px; float: left">
                        <img src="assets/images/homepage/helpdesk.png" alt="{{'views.home.Help desk'| translate}}"
                            width="50%" />
                    </div>{{"views.home.Help desk"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button style="width:315px" type="button" class="btn btn-light custom-btn" (click)="gotoShowUserEntriesPage()" title="{{'views.public.pwdreset.Password Reset'| translate}}">
                        <span class="bi bi-search" style="margin-right: 10px"></span>{{"views.public.pwdreset.Password Reset"| translate}}</button>
                </div>
                <div class="btn-group">
                    <button style="width:315px" type="button" class="btn btn-light custom-btn" (click)="gotoShowUserEntriesPage()" title="{{'views.home.Show user entry'| translate}}">
                        <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{"views.home.Show user entry"| translate}}</button>
                </div>
            </div>
            </accordion-group>
        </div>

        
        <div id="config"   *ngIf="hasCIAMAdmin()" class="home-accordion">
            <accordion-group is-open="false" is-disabled="false"  [isOpen]="activeGroup === 'group9'" (click)="onGroupChange('group9')">
                <div accordion-heading style="width: 100%;">
                    <div style="width: 50px; float: left"> 
                        <img src="assets/images/homepage/users.png" alt="{{'views.home.Federated Client'| translate}}" width="50%"/>
                    </div>{{"views.home.Federated Client"| translate}}
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button style="width:315px" type="button" class="btn btn-light custom-btn" (click)="gotoFederatedNewPage()" title="{{'views.home.New Client'| translate}}">
                            <span class="bi bi-lock" style="margin-right: 10px"></span>{{"views.home.New Client"| translate}}</button>
                    </div>
                    <div class="btn-group">
                        <button style="width:315px" type="button" class="btn btn-light custom-btn" (click)="gotoFederatedUpdateDetailsPage()" title="{{'views.home.Update Client'| translate}}">
                            <span class="bi bi glyphicon-list-alt" style="margin-right: 10px"></span>{{"views.home.Update Client"| translate}}</button>
                    </div>
                </div>
            </accordion-group>
        </div>

    </accordion>
    <div style="display: flex;" class="customfooter">
        <app-terms-conditions></app-terms-conditions>
    </div>
    </div>