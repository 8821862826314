<div class="footer customfooter" >
    <div class="info-terms">
        <div class="info-terms-link-container">
            <a href="/dis/public/tou" target="_blank" class="link-info link custom-links">{{'views.home.Terms of Use' | translate}}</a>
            <a href="/dis/public/privacynotice" target="_blank" class="link-info link custom-links">{{'views.home.Privacy Statement' | translate}}</a>
            <a href="/dis/public/cookienotice" target="_blank" class="link-info link custom-links">{{'views.home.Cookies' | translate}}</a>
        </div>
        <div class="info-text">
            <div class="main-text info-text-footer">
                <p style="font-size:10px">Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see",
                <a class="link-description link custom-links" style="font-size: 10px;" href="https://www.deloitte.com/about">www.deloitte.com/about</a>
                to learn more.
            </div>
            <div class="main-text info-text-footer">© <span id = 'year'>{{year}}</span>. For information, contact Deloitte Global.</div>
        </div>
    </div>
</div>