<div id="roleassignmentNew" *ngIf="authorized()">

  <ol class="breadcrumb breadcrumb-dis">
   <li class="breadcrumb-item"><a href="" routerLink="/home">{{"views.contract-detail.Home"| translate}}</a></li>
      <li class="breadcrumb-item"><a href="" routerLink="/roleassignment/list/all">{{'views.roleassignment-detail.Role Assignment List'| translate}}</a></li>
      <li class="breadcrumb-item active">{{'views.admin.role.list'| translate}}        </li>
      <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{"views.home.Log out"| translate}}</a></li>
  </ol>

  <!-- <div class="titleicon"><img src="images/homepage/roles.png" alt="role management" /></div> -->
  <div *ngFor = "let alert of alerts">
      <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
          {{alert.msg}}
          <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
          float:right;
 font-size: 22px; "></i>
      </p>
  </div>

  <h1>{{'views.admin.role.new'| translate}} </h1>

  <div class="card">
      <div class="card-header"></div>
      <div class="card-body">
          <form name="roleassignmentForm" class="form-horizontal" role="form" [formGroup]="roleAssignmentForm">
              <!-- <div class="mb-3 row" show-errors>
                  <label for="role.startDate" class="col-sm-3 control-label ">{{'views.service.Start date'|
                      translate}}</label>
                  <div class="col-sm-7">
                      <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                  </div>
                  <div class="col-sm-7">
                      <input type="date" class="form-control" formControlName="startDate" [attr.disabled]="true">
                  </div>
                  <div class="col-sm-1" style="max-width: none"
                      *ngIf="roleAssignmentForm.controls['startDate'].hasError('required') && roleAssignmentForm.controls['startDate'].touched">
                      <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                  </div>
              </div> -->
              <!-- <div class="mb-3 row" show-errors>
                  <label for="role.endDate" class="col-sm-3 control-label ">{{'views.service.End date'|
                      translate}}</label>

                  <div class="col-sm-7">
                      <input type="date" class="form-control" formControlName="endDate"
                          (change)="dateChanges($event.target.value)">
                  </div>
                  <div class="col-sm-1" style="max-width: none"
                      *ngIf="roleAssignmentForm.controls['endDate'].hasError('required') && roleAssignmentForm.controls['endDate'].touched">
                      <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                  </div>
              </div> -->
              <!-- <div class="mb-3 row" show-errors>
                  <label for="role.technicalId" class="col-sm-3  control-label ">{{'views.role.Technical ID' |
                      translate }}</label>
                  <div class="col-sm-7">
                      <input type="text" class="form-control" formControlName="technicalId"
                          (keyup)="validateTechnicalId($event)">
                  </div>
                  <div class="col-sm-3">
                      <p class="help-block"
                          *ngIf="roleForm.controls['technicalId'].hasError('required') && roleForm.controls['technicalId'].touched">
                          {{'views.role.Required' |
                          translate }}</p>
                      <p class="help-block" *ngIf="roleForm.controls['technicalId'].hasError('pattern')">
                          {{'views.role.The technical ID can only contain alphanumeric values.' | translate }}</p>
                  </div>
              </div> -->

              <div class="mb-3 row" show-errors>
                  <label for="roleassignment.roleId" class="col-sm-3 control-label ">{{'views.organization.Role'|
                      translate}}</label>
                  <div class="col-sm-7">
                      <mat-form-field appearance="fill">
                          <mat-label>Role</mat-label>
                          <mat-select formControlName="role" (selectionChange)="roleChange($event)">
                            <mat-option *ngFor="let role of adminRoles" [value]="role">
                              {{role}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                  </div>
              </div>
              <div class="mb-3 row" show-errors>
                  <label for="roleassignment.user" class="col-sm-3 control-label ">{{"views.role.User"|
                      translate}}</label>
                  <div class="col-sm-7">
                      
                     <input type="text" (keyup)="filterUsers($event.target.value)" class="form-control"
                          formControlName="user" #userTrigger="matMenuTrigger" [matMenuTriggerFor]="menu1"  (input)="fetchUsers()" placeholder="Search user">
                      <div class="col-sm-1" style="max-width: none"
                          *ngIf="roleAssignmentForm.controls['user'].hasError('required') && roleAssignmentForm.controls['user'].touched">
                          <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                      </div>
                      <mat-menu #menu1="matMenu">
                          <ng-container *ngFor="let user of currentUsers">
                              <button mat-menu-item (click)="selectUser(user)">{{user?.sn}}
                                  {{user?.givenName}} ({{user?.mail}})</button>
                          </ng-container>
                      </mat-menu> <div *ngIf="roleassignmentForm?.controls.user.touched && currentUsers?.length<=0">
                          <span>No Users Found</span>
                         </div>

                  </div>
              </div>
              <div class="mb-3 row" show-errors *ngIf="orgAdminFlag ">
                  <label for="roleassignment.organizationId" class="col-sm-3 control-label ">{{'views.user.Organization'| translate}}</label>
                  <div class="col-sm-7">
                      <input type="text" class="form-control" formControlName="organization" placeholder="Search Organization"
                            [attr.disabled]="isMenuDisabled==true ? '':null"  #orgTrigger="matMenuTrigger" [matMenuTriggerFor]="menu2">
                      <mat-menu #menu2="matMenu">
                          <ng-container *ngFor="let org of availableOrganizations">
                              <button mat-menu-item (click)="selectOrg(org)">{{org?.name}} </button>
                          </ng-container>
                      </mat-menu>
                      <div *ngIf="roleassignmentForm?.controls.organization.touched && availableOrganizations?.length<=0">
                          <span>No Organizations Found</span>
                         </div>
                  </div>
              </div>
                 <div class="col-sm-3"></div>
              <div class="col-sm-7">
                  <div class="btn-group savebutton">
                      <button type="button" class="btn btn-light custom-btn" (click)="saveRoleAssignment()"
                          [disabled]="(!roleAssignmentForm.valid)||(isMenuDisabled)">
                          <span class="bi bi-floppy-disk"
                              style="margin-right: 10px"></span>{{'views.role.Save' | translate }}</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
  <div class="card"  *ngIf="authorizedBatch()">
      <div class="card-header">
          {{"views.roleassignment-new.Batch Upload Role Assignments"| translate}}
      </div>
      <div class="card-body">
          <div style="float:right">
              <button type="button" style="    width: 41px;
              height: 29px;
              margin-right: 10px;"  class="butnfntsize btn btn-light custom-btn btn-xs" (click)="showhelp = !showhelp"
                  title="{{'views.user.Show help' | translate}}">
                  <span class="bi bi-question-lg" style="margin-right: 10px"></span>
              </button>
          </div>
          <div *ngIf="showhelp" style="overflow: auto; display: flex; flex-direction: column; gap: 10px">

            <div>
              <p><span>{{'views.roleassignment.Admin Role Batch Upload Desc' | translate}}</span></p>
            </div>

            <div>
              <p>
                <span>{{'views.roleassignment.Supported Operations available only for' | translate}}</span>
                &nbsp;<span><strong>CIAM Admin</strong></span>&nbsp;
                <span>{{'views.roleassignment.are' | translate}}</span>
              </p>
            </div>

            <div style="margin-left: 50px">
              <ul style="list-style-type: disc">
                <li><strong>CREATE</strong>&nbsp;<span>--{{'views.roleassignment-Assign Org-Admin Role' | translate}}</span></li>
                <li><strong>DELETE</strong>&nbsp;<span>--{{'views.roleassignment-Remove Org-Admin Role' | translate}}</span></li>
              </ul>
            </div>

            <div>
              <p><strong>{{'views.roleassignment-Template for Assign a role (admin) batch upload' | translate}}</strong></p>
              <div style="margin-left: 50px">
                <ul style="list-style-type: disc">
                  <li>{{'views.roleassignment-The CSV input file needs to be saved in plain format' | translate}}</li>
                  <li>{{'views.roleassignment-It should contain lines in the following format. It must not contain any headers' | translate}}</li>
                </ul>

                <div style="margin-left: 20px">
                  <ul style="list-style-type: disc">
                    <li>
                      <div style="margin-bottom: 10px"><i>{{'views.roleassignment-Operations to create and delete' | translate}} App admins: </i></div>
                      <div>
                        <p>Create;Email;RoleName;OrgTechID</p>
                        <p>Delete;Email;RoleName;OrgTechID</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div style="margin-left: 20px">
                  <ul style="list-style-type: disc">
                    <li>
                      <div style="margin-bottom: 10px"><i>{{'views.roleassignment-Operations to create and delete' | translate}} CIAM Admin and Help Desk Admin: </i></div>
                      <div>
                        <p>Create;Email;RoleName</p>
                        <p>Delete;Email;RoleName</p>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div>
              <p>{{'views.roleassignment-Examples for each operation' | translate}}: </p>

              <div style="margin-left: 20px">
                <ul style="list-style-type: disc">
                  <li>
                    <div style="margin-bottom: 10px"><i>{{'views.roleassignment-Operations to create and delete' | translate}} App admins: </i></div>
                    <div>
                      <p>create;user1@deloitte.com;adminOfOrg;CIAMDemoOrgTechID</p>
                      <p>delete;user1@deloitte.com;adminOfOrg;CIAMDemoOrgTechID</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div style="margin-left: 20px">
                <ul style="list-style-type: disc">
                  <li>
                    <div style="margin-bottom: 10px"><i>{{'views.roleassignment-Operations to create and delete' | translate}} CIAM Admin and Help Desk Admin: </i></div>
                    <div>
                      <p>create;user1@deloitte.com;ciam-admin</p>
                      <p>delete;user2@deloitte.com;gsd-gas-admin</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div *ngIf="selected">
              <p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size
                      / 1000 | number:0}} kb)</span></p>
          </div>
          <div *ngIf="showprogress" class="progress" style="height: 20px">
              <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progresswidth}"
                  aria-valuenow="(progressvalue / progressmax)*100 | number:0" aria-valuemin="0" aria-valuemax="100">
                  {{(progressvalue / progressmax)*100 | number:0 }}%
              </div>
          </div>
           <div class="btn-group">
              <button type="button" class="butnfntsize btn btn-light custom-btn" (click)="download()" title="Download file">
                  <span class="bi bi-save" style="margin-right: 10px"></span>
                  {{"views.user.Download batch upload template"| translate}}
              </button>
          </div>
          <div class="btn-group btn-group-justified">
              <div class="btn-group">
                  <div class="choose_file">
                      <button style="width:315px" type="button" class="butnfntsize btn btn-light custom-btn btn-group" title="Select file">

                          <span class="bi bi-paperclip" style="margin-right: 10px"></span>
                          {{'views.user.Select your CSV file' | translate}}
                      </button>
                      <input type="file" (change)="selectfile($event)" accept=".csv,.CSV">
                  </div>
              </div>
              <div class="btn-group">
                  <button  style="width:342px" [attr.disabled]="selected==false ? '':null" type="button" class="butnfntsize btn btn-light custom-btn"
                      (click)="upload()" title="Upload file">
                      <span class="bi bi-upload" style="margin-right: 10px"></span>
                      {{'views.user.Upload file' | translate}}
                  </button>
              </div>
          </div>

      </div>
  </div>
  <app-terms-conditions></app-terms-conditions>
</div>
