import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './User_Management/home/home.component';
import { PendingComponent } from './User_Management/pending/pending.component';
import { UserComponent } from './User_Management/user/user.component';
import { AllusersComponent } from './User_Management/allusers/allusers.component';
import { PendingallusersComponent } from './User_Management/pendingallusers/pendingallusers.component';
import { NewuserComponent } from './User_Management/newuser/newuser.component';
import { UserdetailComponent } from './User_Management/userdetail/userdetail.component';
import { PwdsetComponent } from './pwdset/pwdset.component';
import { UserhistoryComponent } from './User_Management/History/userhistory/userhistory.component';
import { ErrorComponent } from './error/error.component';
import { UserrequestDetailComponent } from './User_Management/userrequest-detail/userrequest-detail.component';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { PwdResetComponent } from './pwd-reset/pwd-reset.component';
import { OtpSetComponent } from './otp-set/otp-set.component';
import { LogoutComponent } from './logout/logout.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { BatchComponent } from './BatchUserManagement/batch/batch.component';
import { ContractListComponent } from './contract_Management/contract-list/contract-list.component';
import { ContractNewComponent } from './contract_Management/contract-new/contract-new.component';
import { RolesListComponent } from './Roles/roles-list/roles-list.component';
import { RoleAssignmentsComponent } from './Role Assignment/role-assignments/role-assignments.component';
import { AllRoleAssignmentsComponent } from './Role Assignment/all-role-assignments/all-role-assignments.component';
import { RoleNewComponent } from './Roles/role-new/role-new.component';
import { RoleAssignmentNewComponent } from './Role Assignment/role-assignment-new/role-assignment-new.component';
import { AdminRoleAssignmentNewComponent } from './Role Assignment/admin-role-assignment-new/admin-role-assignment-new.component';
import { RoleDetailsComponent } from './Roles/role-details/role-details.component';
import { ContractDetailComponent } from './contract_Management/contract-detail/contract-detail.component';
import { HelpDeskComponent } from './help-desk/help-desk.component';
import { ViewServicesComponent } from './Service_Management/viewservices/view-services/view-services.component';
import { AddServiceComponent } from './Service_Management/addservice/add-service/add-service.component';
import { OrganizationListComponent } from './Organization_Management/organizationlist/organization-list/organization-list.component';
import { OrganizationNewComponent } from './Organization_Management/organizationnew/organization-new/organization-new.component';
import { OrganizationBatchComponent } from './Organization_Management/organizationbatch/organization-batch/organization-batch.component';
import { OrganizationDetailsComponent } from './Organization_Management/organizationdetails/organization-details/organization-details.component';
import { ServiceDetailsComponent } from './Service_Management/servicedetails/service-details/service-details.component';
import { FederationNewComponent } from './Federation_Management/federation-new/federation-new.component';
import { FederationUpdateDetailsComponent } from './Federation_Management/federation-update-details/federation-update-details.component';
import { RoleRequestsComponent } from './Role Request/role-requests/role-requests.component';
import { RoleAssignmentDetailComponent } from './Role Assignment/role-assignment-detail/role-assignment-detail.component';
import { AllRoleRequestsComponent } from './Role Request/all-role-requests/all-role-requests.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { TouComponent } from './tou/tou.component';
import { ErrorMfaComponent } from './error-mfa/error-mfa.component';
import { PwdmailComponent } from './pwdmail/pwdmail.component';
import { CookienoticeComponent } from './cookienotice/cookienotice.component';
import { AuthGuard } from './_guards';
import { LoginComponent } from './login/login.component';
import { SsologinComponent } from './ssologin/ssologin.component';
import { Privacynoticeb2bComponent } from './privacynoticeb2b/privacynoticeb2b.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ErrorPasswordComponent } from './error-password/error-password.component';
const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
      path: 'ssologin',
      component: SsologinComponent
    },
    {
        path: 'user/list',
        component: UserComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'userrequest/list',
        component: PendingComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/list/all',
        component: AllusersComponent,
        canActivate: [AuthGuard]
    },
    {
        path:   'userrequest/list/all',
        component: PendingallusersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'userrequest/new',
        component: NewuserComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        component: HomeComponent
    },
    
    { path: 'user/:id', component: UserdetailComponent,
        canActivate: [AuthGuard]
     },
    { path: 'userrequest/:id', component: UserrequestDetailComponent,
        canActivate: [AuthGuard]
     },
    { path: 'public/set', component: PwdsetComponent,
        
    },
    { path: 'public/success', component: SuccessScreenComponent },
    { path: 'public/request', component: PwdmailComponent },
    { path: 'public/error', component: ErrorComponent },
    { path: 'public/errorpassword', component: ErrorPasswordComponent },
    { path: 'public/errormfa', component: ErrorMfaComponent },
    { path: 'public/reset', component: PwdResetComponent },
    { path: 'user/history/:id', component: UserhistoryComponent ,
        canActivate: [AuthGuard]
    },
    { path: 'public/otpset', component: OtpSetComponent },
    { path: 'public/privacynotice', component: PrivacyNoticeComponent },
    { path: 'public/b2bprivacynotice', component: Privacynoticeb2bComponent },
    { path: 'public/tou', component: TouComponent },
    { path: 'public/cookienotice', component: CookienoticeComponent },
    { path: 'oidc/logout', component: LogoutComponent },
    { path: 'public/oidc/logout', component: LogoutComponent,
        canActivate: [AuthGuard]
     },
    { path: 'selfservice', component: SelfServiceComponent ,canActivate: [AuthGuard]},
    { path: 'usersBatch', component: BatchComponent ,
        canActivate: [AuthGuard]
    },
    { path: 'contract/list', component: ContractListComponent ,
        canActivate: [AuthGuard]
    },
    { path: 'contract/new', component: ContractNewComponent ,
        canActivate: [AuthGuard]
    },
    { path: 'contract/:id', component: ContractDetailComponent,
        canActivate: [AuthGuard]
     },
    { path: 'usersBatch', component: BatchComponent,
        canActivate: [AuthGuard]
     },
    { path: 'role/list', component: RolesListComponent,
        canActivate: [AuthGuard]
     },
    { path: 'roleassignment/list', component: RoleAssignmentsComponent , canActivate: [AuthGuard]},
    { path: 'roleassignment/list/all', component: AllRoleAssignmentsComponent,  canActivate: [AuthGuard] },
    { path: 'role/new', component: RoleNewComponent ,  canActivate: [AuthGuard]},
    { path: 'roleassignment/new', component: RoleAssignmentNewComponent,  canActivate: [AuthGuard] },
    { path: 'roleassignment/new/adminRole', component: AdminRoleAssignmentNewComponent ,  canActivate: [AuthGuard]},
    { path: 'roleassignment/:roleId/:orgId/:userId', component: RoleAssignmentDetailComponent ,  canActivate: [AuthGuard] },
    { path: 'role/:id', component: RoleDetailsComponent ,  canActivate: [AuthGuard] },
    { path: 'helpdesk', component: HelpDeskComponent ,  canActivate: [AuthGuard]},
    { path: 'viewallservices', component: ViewServicesComponent ,  canActivate: [AuthGuard]},
    { path: 'organization/list', component: OrganizationListComponent,  canActivate: [AuthGuard] },
    { path: 'organization/new', component: OrganizationNewComponent ,  canActivate: [AuthGuard]},
    { path: 'organization/batch', component: OrganizationBatchComponent ,  canActivate: [AuthGuard]},
    { path: 'organization/:id', component: OrganizationDetailsComponent,  canActivate: [AuthGuard] },
    { path: 'service/:id', component: ServiceDetailsComponent,  canActivate: [AuthGuard] },
    { path: 'services/new', component: AddServiceComponent,  canActivate: [AuthGuard] },
    { path: 'services/list', component: ViewServicesComponent,  canActivate: [AuthGuard] },
    { path: 'federation/new', component: FederationNewComponent ,  canActivate: [AuthGuard]},
    { path: 'federation/details', component: FederationUpdateDetailsComponent,  canActivate: [AuthGuard] },
    {path: 'public/404', component: NotfoundComponent},
    { path: '**', redirectTo: '' },

];
@NgModule({

    imports: [
        RouterModule.forRoot(routes, { useHash: false})
    ],

    exports: [
        RouterModule
    ],
})
export class AppRoutingModule { }

