<div id="roleList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a routerLink="/home">{{'views.role.Home' | translate }}</a></li>
        <li class="breadcrumb-item active">{{'views.role.Role list' | translate }}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{'views.role.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/roles.png" alt="role management" /></div>
    <h1>{{'views.role.Role list' | translate }}</h1>
<div class="card">
    <div class="card-header" style = "height: 25px">
            <div class="loader" *ngIf="isFilteringResults || isLoadingResults || isRateLimitReached">
              <div class="dot" style="--dot-index:0;"></div>
              <div class="dot" style="--dot-index:1;"></div>
              <div class="dot" style="--dot-index:2;"></div>
            </div>
          </div>
        
        <!-- <div class="card-header"></div>
        <div class="card-body">
            <table ng-table="tableParams" class="table  dislist" show-filter="true" style="padding: 2px"
                export-csv="csv">
                <tr ng-repeat="role in $data">
                    <td data-title="'views.role.Status' | translate" sortable="'status'" class="dislist">
                        <p ng-hide="true">{{role.status}}</p>
                        <img style="width: 25px" ng-show="role.status == 'suspended'" src="images/icons/bullet_red.png"
                            alt="suspended" title="suspended" />
                        <img style="width: 25px" ng-show="role.status == 'active'" src="images/icons/bullet_green.png"
                            alt="active" title="active" />
                    </td>
                    <td data-title="'views.role.Name' | translate" sortable="'name'" filter="{ 'name': 'text' }"><a
                            href="#/role/{{role.id}}">{{role.name}}</a></td>
                    <td ng-show="authorizedServices()" data-title="'Service'" sortable="'serviceId.name'"
                        filter="{ 'serviceId.name': 'text' }"><a
                            href="#/service/{{role.serviceId.id}}">{{role.serviceId.name}}</a></td>
                    <td data-title="''" style="width:22%">
                        <div ng-if="role.status !== 'suspended'">
                            <div confirm-suspend on-confirm="suspendRole(role)"></div>
                            <div confirm-delete on-confirm="deleteRole(role)"></div>
                        </div>
                        <div ng-if="role.status === 'suspended'">
                            <div confirm-activate on-confirm="activateRole(role)"></div>
                            <div confirm-delete on-confirm="deleteRole(role)"></div>
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->
        <div class="example-table-container">

            <table style="width: 100%;" mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort matSortActive="name"
                matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                <!-- (matSortChange)="resetPaging()" -->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <img style="width: 25px" *ngIf="row.status === 'suspended'"
                            src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                            title="{{'views.user.suspended' | translate}}" />
                        <img style="width: 25px" *ngIf="row.status === 'active'"
                            src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                            title="{{'views.user.active' | translate}}" />
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.role.Name'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" [routerLink]="['/role/', row?._id]">{{row.name}}</td>
                </ng-container>


                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                        {{'Service'}}</th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row?.services?.length > 0">
                              <a  style="color:#00a3e0"[routerLink]="['/service/', row.services[0]._id]">{{ row.services[0].name }}</a>
                            </ng-container>
                            <ng-container *ngIf="!row?.services?.length">
                            </ng-container>
                          </td>
                    </ng-container>
                <!-- <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{'views.user.Email'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)">{{row.userId.email}}</td>
                </ng-container> -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="row.status  !== 'suspended'" style="display: flex;">
                            <app-confirmsuspend (onconfirm)="suspendRole(row)"></app-confirmsuspend>
                            <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>

                        </div>
                        <div *ngIf="row.status  === 'suspended'" style="display: flex;">
                            <app-confirm-activate (onconfirm)="activateRole(row)"></app-confirm-activate>
                            <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="filter-status">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-name">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput (keyup)="filterData('name',$event.target.value)" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-service">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput (keyup)="filterData('service',$event.target.value)" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-action">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

                <!-- <ng-container matColumnDef="filter-email">
                    <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterbyEmail($event.target.value)" />
                        </mat-form-field> </th>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name', 'filter-service','filter-action']"
                    class="example-second-header-row">
                </tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"></mat-paginator>
    </div>

    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" class="btn btn-light custom-btn" style="width: 300px;" routerLink="/home">
                <span class="bi bi-house-up-fill" style="margin-right: 10px"></span>{{'views.role.Home' | translate
                }}</button>
        </div>
        <div class="btn-group">
            <button type="button" class="btn btn-light custom-btn" style="width: 395px;" routerLink="/role/new">
                <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{'views.role.Add new role' |
                translate }}</button>
        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>

</div>
