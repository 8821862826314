<div class="login-page reset-password-page activate-page">

    <div class="main-container self-service-main-container" style="margin-top: 25px; max-width: 500px; margin-left: 5%">
        <div class="main-text"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'><a style="color: #00a3e0" href="" onclick="javascript:window.open('','_self').close()">Close</a></span></b></div>
            <div class="self-service-form-container" style="display: block; max-width: none; width: 100%;">
        <div class="form-title"><h3><b><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Last revised: February 21<sup>st</sup>, 2025</span></b></h3></div>
        <div class="title"><h2><b><span style='font-size: 14.0pt; font-family:"Arial",sans-serif;color:#100B0C'>Cookie Notice</span></b></h2></div>

        <h3 class="form-title"><b><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>What is a cookie?</span></b></h3>

        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            A cookie is a small text file placed on your device by the websites that you visit. Cookies are 
            then sent back to the website on each subsequent visit, or to another webpage that recognizes 
            that cookie. Cookies are used in order to make the website work, or to work more efficiently, 
            as well as to provide information to the owners of the website. Such information may be used 
            to provide a more personalized and responsive service. Cookies may be either first party cookies 
            set directly by us to your device, or third-party cookies set by a third-party provider on our 
            behalf. Whenever you use this system, information may be collected through the use of cookies 
            and other similar technologies (e.g., web beacons, tags, scripts, local storage). 
        </span></p>

        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            This Cookie notice is a part of our Privacy Notice. For more information about us, and how we process personal information, please see our 
        <a href="/dis/public/privacynotice" style="color: black; text-decoration: underline; font-size: 11pt;">Privacy Notice</a>.
        </span></p>

        <h3 class="form-title"><b><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Cookies we use and why</span></b></h3>
        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            This system uses cookies and web beacons that are classified into the following categories:
        </span></p>

        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            • “Strictly Necessary” which are necessary for the system to function and cannot be 
                switched off in our systems. They are set in response to actions made by you which 
                amount to a request for services, such as authenticating into the system or managing 
                your session with the requested service.        
        </span></p>

        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            • “Analytics and Performance” which are necessary for our non-public and internal 
            systems to measure audience and usage statistics, as well as content consumption.        
        </span></p>
        <p class="main-text"><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            These enable us to obtain relevant usage information to produce internal analytics so 
            we can monitor and improve the performance of our systems. These cannot be switched 
            off in our non-public or internal systems.
        </span></p>

        <h3 class="form-title"><b><span style='font-size: 11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>How long will cookies remain on my device?</span></b></h3>

        <p class="main-text"> <span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'>
            The length of time cookies will stay on your device will depend on whether it is a “persistent” 
            or “session” cookie. A persistent cookie will be stored by a web browser and will remain valid 
            until its set expiration date, unless deleted by you before the expiration date. A session cookie, 
            on the other hand, will expire at the end of your web session, when the web browser is closed.          
        </span></p>

        <p class="main-text"><span style="color: black; font-size: 11.0pt;"><b>Controller of the Cookies:</b></span> <span style='font-size: 11.0pt;font-family:"Arial",sans-serif;'> Deloitte Touche Tomatsu Limited with registered office at 1 New Street Square, London, United Kingdom, EC4A 3HQ
            decided to use the cookies specified on CIAM.</span></p>
            </div>
                <!-- FOOTER -->
        <app-terms-conditions></app-terms-conditions>
    </div>
    <div class="img-container self-service-img-container">
    </div>

</div>