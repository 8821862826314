<div id="fedrated">

    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a href="" [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="breadcrumb-item active">{{"views.home.Federated Client"| translate}} / {{"views.home.New Client" | translate}}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{"views.home.Log out"| translate}}</a></li>
    </ol>

    <h1>{{"views.home.New Client"| translate}}</h1>
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
    <!-- Alert 
    <alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert(index)" class="">{{alert.msg}}</alert>
    -->
    
    <div class="card">
        <!-- <div class="card-header">
            <div class="subtitleicon">                          
                <img src="images/homepage/helpdesk.png" alt="helpdesk" width="30px"/>
            </div><span class="">{{"views.helpdesk.Help Desk"| translate}}</span>
        </div> -->
        <div class="card-body"> 
         
            
          
           

            <form [formGroup]="federationRequest" class="form-horizontal" role="form">
                <!-- Client Name -->
                <div class="mb-3 row" show-errors>
                    <label for="federationRequest.federatedclientname" class="col-sm-3 control-label ">{{"views.federated.Federated or B2B client name" | translate}}</label>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <span class="input-group-text"><span class="bi bi-person"></span></span>
                            <input type="text" formControlName="federatedclientname" class="form-control" id="federationRequest.federatedclientname" name="federatedclientname" required ng-pattern="/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/"/>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <p class="help-block" *ngIf="federationRequest.get('federatedclientname').errors?.required && federationRequest.get('federatedclientname').touched">{{"views.userrequest.Required" | translate}}</p>
                        <p class="help-block" *ngIf="!(federationRequest.get('federatedclientname').errors?.required) && federationRequest.get('federatedclientname').invalid && federationRequest.get('federatedclientname').touched">{{"views.service.Invalid Input" | translate}}</p>
                    </div>
                </div>
                <!--Domains-->
                <div class="mb-3 row" show-errors>
                    <label for="federationRequest.clientdomains" class="col-sm-3 control-label ">{{"views.federated.Client federated domains" | translate}}</label>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <span class="input-group-text"><span class="bi bi-person"></span></span>
                            <input type="text" formControlName="clientdomains" class="form-control" id="federationRequest.clientdomains" name="clientdomains" required
                                   />
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <p class="help-block" *ngIf="federationRequest.get('clientdomains').errors?.required && federationRequest.get('clientdomains').touched">{{"views.userrequest.Required" | translate}}</p>
                        <p class="help-block" *ngIf="!(federationRequest.get('clientdomains').errors?.required) && federationRequest.get('clientdomains').invalid && federationRequest.get('clientdomains').touched">{{"views.service.Invalid Input" | translate}}</p>
                    </div>
                </div>
                 <!-- Email -->
                 <div class="mb-3 row" show-errors >
                    <label  for="federationRequest.clienthelpdeskemail" class="col-sm-3 control-label ">{{"views.federated.Client HelpDesk email" | translate}}</label>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <span class="input-group-text">@</span>
                            <input type="text" class="form-control" 
                                   name="clienthelpdeskemail" id="federationRequest.clienthelpdeskemail" 
                                   ng-pattern="/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
                                   formControlName="clienthelpdeskemail" 
                                   />
                        </div>
                    </div>
                    
                    <div class="col-sm-1">
                        <p class="help-block" *ngIf=" federationRequest.controls.clienthelpdeskemail.status == 'INVALID' &&federationRequest.controls.clienthelpdeskemail.touched">{{"views.userrequest.The email address is invalid" | translate}}</p>

                       
                    </div>
                    
                </div>
<!--Helpdesk mobile -->
 <!-- Mobile Phone -->
 <div class="mb-3 row" show-errors >
    <label for="federationRequest.clienthelpdeskphone" class="col-sm-3 control-label ">{{"views.federated.Client HelpDesk phone number" | translate}}</label>
    <div class="col-sm-7">
        <div class="input-group">
         <span class="input-group-text"><span class="bi bi-phone"></span></span>
            <input class="form-control" name="clienthelpdeskphone"
             type="text" name="clienthelpdeskphone"  
             formControlName="clienthelpdeskphone" 

            style="position: inherit"/>
        </div>
    </div>
    <div class="col-sm-1">
        <p class="help-block" *ngIf="federationRequest.controls.clienthelpdeskphone.status == 'INVALID' &&federationRequest.controls.clienthelpdeskphone.touched">{{'views.userrequest.The correct format is: +11234567890' | translate}}</p>

       
    </div>
    <!-- 
    <div class="col-sm-2">
        <p class="help-block" *ngIf="clienthelpdeskphone.errors?.required">{{"views.userrequest.Required" | translate}}</p>
        <p class="help-block" *ngIf="clienthelpdeskphone.errors?.pattern">{{"views.userrequest.The correct format is: +11234567890" | translate}}</p>
    </div>
    -->
</div>
                <!-- Iam team contact Email -->
                <div class="mb-3 row" show-errors >
                    <label  for="federationRequest.clientIAMemail" class="col-sm-3 control-label ">{{"views.federated.Client IAM team contact email" | translate}}</label>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <span class="input-group-text">@</span>
                            <input type="text" class="form-control" 
                                   name="emailIAM" id="federationRequest.clientIAMemail" 
                                   
                                   formControlName="clientIAMemail" 

                                 
                                   />
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <p class="help-block" *ngIf="federationRequest.controls.clientIAMemail.status == 'INVALID' &&federationRequest.controls.clientIAMemail.touched">{{"views.userrequest.The email address is invalid" | translate}}</p>
                
                       
                    </div>
                    <!--
                    <div class="col-sm-2">
                        <p class="help-block" *ngIf="emailIAM.errors?.required">{{"views.userrequest.Required" | translate}}</p>
                        <p class="help-block" *ngIf="emailIAM.errors?.pattern">{{"views.userrequest.The email address is invalid" | translate}}</p>
                       
                    </div>
                    -->
                </div>
                <!-- IAM Mobile Phone -->
 <div class="mb-3 row" show-errors >
    <label for="federationRequest.clientIAMphone" class="col-sm-3 control-label ">{{"views.federated.Client IAM team contact phone" | translate}}</label>
    <div class="col-sm-7">
        <div class="input-group">
         <span class="input-group-text"><span class="bi bi-phone"></span></span>
            <input class="form-control" name="clientIAMphone" type="text" name="tel" 
            formControlName="clientIAMphone" 

            style="position: inherit"/>
        </div>
    </div>
    <div class="col-sm-1">
        <p class="help-block" *ngIf="federationRequest.controls.clientIAMphone.status == 'INVALID' &&federationRequest.controls.clientIAMphone.touched">{{'views.userrequest.The correct format is: +11234567890' | translate}}</p>

       
    </div>
    <!--
    <div class="col-sm-2">
        <p class="help-block" *ngIf="mobileIAM.errors?.required">{{"views.userrequest.Required" | translate}}</p>
        <p class="help-block" *ngIf="mobileIAM.errors?.pattern">{{"views.userrequest.The correct format is: +11234567890" | translate}}</p>
    </div>
    -->
</div>
                <!-- Save Button -->
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-light custom-btn"
                                (click)="submit(federationRequest)"
                                [attr.disabled]="federationRequest.controls?.status==='INVALID' ? '':null ">
                            <span class="bi bi-send" style="margin-right: 10px"></span>Save</button>
                    </div>
                </div>
            </form>
                </div>

    </div>

    <div style="display: flex;margin-top: 50%;">
        <app-terms-conditions></app-terms-conditions>
    </div>

    </div>
