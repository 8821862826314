import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs-compat';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';
import { User } from '../Users';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'src/app/permission.service';
import { ServiceManagementService } from 'src/app/Service_Management/service-management.service';
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js';
@Component({
selector: 'app-newuser',
templateUrl: './newuser.component.html',
styleUrls: ['./newuser.component.css']
})
export class NewuserComponent implements OnInit {
userrequestForm: FormGroup = new FormGroup({
userType: new FormControl('external', Validators.required),
    firstname: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?! )[^\s_]+(?: [^\s_]+)*$/)

    ]),
    lastname: new FormControl('', [Validators.required,
      Validators.pattern(/^(?! )[^\s_]+(?: [^\s_]+)*$/)
    ]),
    mainLanguage: new FormControl('EN', Validators.required),
    email: new FormControl('', [Validators.required,Validators.pattern(/^[^\s][a-zA-Z0-9._%-\'-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ]),
    email2: new FormControl('', Validators.pattern(/^[^\s][a-zA-Z0-9._%-\'-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)),
    mobile: new FormControl({ value: '', disabled: false }),
    azureServiceId: new FormControl(),
    available: new FormControl(),
    selected: new FormControl(),
    groupId: new FormControl('')

  });
  addtoaditionalorg = false;
  hiddenform = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  uniqueValue;
  isLoading=false;
  currentorganization;
  titlesub ="a new";
  alreadyExistingFlag=false;
  existingUserProfile : any;
  otherUserType = false;
  services;
  filteredOptions: Observable<[]>;
  azureServiceId = new FormControl();
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  availableroles;
  submitClicked = false;
  selectedroles = [];
  msg;
  alerts = [];
0
  accoountStatus: Object;
  formattedNumber: string;
  isValid: boolean;
 checkuniqueValue: boolean = false;
  constructor(private servicemanagementservice:ServiceManagementService,private translate: TranslateService, private permission: PermissionService , private fb: FormBuilder, private commonService: CommonService, private router: Router) { 

        this.currentorganization = this.commonService.selectedOrg;
        // this.userrequestForm.get('mobile').valueChanges
        // .pipe(debounceTime(200))
        // .subscribe(values => {
        //   // Compare current }
        //   this.onPhoneNumberChange(values)
        // });
  }
  checkNumber(values): void {
    if(this.userrequestForm.get('mobile').value !=null)
    {
      values= this.userrequestForm.get('mobile').value;
    const formphoneNumber = values?.internationalNumber;
    const phoneNumber = parsePhoneNumber(formphoneNumber);
    if (phoneNumber) {
      this.formattedNumber = phoneNumber.formatInternational();
      this.isValid = phoneNumber.isValid();
      if(phoneNumber.isValid())
      {
        this.isValid =true;
      }
      else
      {
        this.isValid = false;
      }
    } else {
      this.formattedNumber = '';
      this.isValid = false;
    }
  }
  else
  {
    this.isValid = true;
  }
  }

  closeAlert(index) {
    this.alerts.splice(index, 1);
  }

  ngOnInit(): void {

    this.commonService.getCompLanguage().subscribe(lang => {
      if (lang) {
        this.translate.use(lang);
      }
    });


    this.commonService.getOrgDetails().subscribe(
      data => {
        if(data['services']!=null)
        {
          const servicedetails=data[`services`].filter(service => service.status === "active");

        this.services = servicedetails;
        }
      const roledetails=data[`roleDetails`].filter(roledetail => roledetail.status === "active");
        this.availableroles = this.updateRoleDetailsWithServiceName(roledetails,this.services);
        this.filteredOptions = this.azureServiceId.valueChanges
          .pipe(
            startWith({}),
            map(user => user && typeof user === 'object' ? user.name : user),
            map((name: string) => name ? this.filter(name) : this.services.slice())
          );

      }, error => {
      }
    );

  }
   updateRoleDetailsWithServiceName(roledetails, servicedetails) {
    let roleDetails =[];
    for (const roleDetail of roledetails) {
      let service = servicedetails.find(sd => sd._id === roleDetail.serviceId);
      if (!service) {
        // Fetch from API if not found in the existing array
        //service = await fetchServiceDetailsFromAPI(roleDetail.serviceId);
        this.servicemanagementservice.getServiceDetailswithId(roleDetail.serviceId).subscribe(data =>
          {
            //servicedetails.push(service);
          // Optionally update the local cache
          roleDetail.serviceName = data.body['name'];  // Update the role detail
          roleDetails.push(roleDetail);
          });
      }
      else
      {
      roleDetail.serviceName = service.name;
      roleDetails.push(roleDetail);

      }// Update the role detail
    }
    return roleDetails;
  }
  filter(name: string) {
    return this.services.filter(option =>
      option.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  displayFn(service): string {
    return service ? service.name : '';
  }
  updatePortCode(event: MatAutocompleteSelectedEvent) {

    if (event.option.value !== undefined) {
      this.userrequestForm.get('azureServiceId').setValue(event.option.value._id);
    }
    else { this.userrequestForm.get('azureServiceId').setValue(null); }
  }
  domains(event , available) {

  }
  onSubmit(form: FormGroup) {

    this.submitClicked = true;
    if (!this.addtoaditionalorg)
      {

      const user = {
        "orgId":this.commonService.selectedOrg[`id`],
        "givenName" :"",
        "sn": "",
        "mail" : "",
        "targetService" : "",
        "telephoneNumber" : "",
        "roles" : "",
        "userType": "",
        "sendEmail":true,
        "azureTenant":this.commonService.selectedOrg[`tenantName`],

          "preferredLanguage":""

    }
    if( this.userrequestForm.get('azureServiceId').value!=null)
      user.targetService = this.userrequestForm.get('azureServiceId').value;
    if (this.selectedroles.length > 0) {
      user.roles = this.selectedroles
      .filter(role => role._id !== null) // Remove roles where roleId is null
      .map(role => role._id) // Extract roleId from each role
      .join(','); // Join all roleIds with a comma and a space as separators}
    }
        user.userType = this.userrequestForm.get('userType').value;
      user.givenName = this.userrequestForm.get('firstname').value;
      user.sn = this.userrequestForm.get('lastname').value;
      user.preferredLanguage = this.userrequestForm.get('mainLanguage').value;
      user.mail = this.userrequestForm.get('email').value;
      const mobObj = this.userrequestForm.get('mobile').value;
      if (mobObj != null) {
        user.telephoneNumber = mobObj.internationalNumber;
      }

      this.commonService.saveUserRequest(user).subscribe(userrequest => {
        if (userrequest.accountStatus === 'undefined') {
          this.submitClicked = false;
          this.translate.get('views.js.alerts.userrequest.Azure check could not be performed, please try again later.').subscribe(res => {
            this.alerts.push({ type: 'danger', msg:res});
          });
        }
        else if (userrequest.accountStatus === 'azureuser') {
          this.translate.get('views.js.alerts.userrequest.User cannot be invited as the account already exists in another CIAM environment and cannot be duplicated.').subscribe(res => {
            this.alerts.push({ type: 'danger', msg:res});
          });
          this.submitClicked = false;
        }
        else if (userrequest.accountStatus === 'noazureuser') {
          this.translate.get('views.js.alerts.userrequest.A user with this email address could not be found in Azure.').subscribe(res => {
            this.alerts.push({ type: 'danger', msg:res});
          });
          this.submitClicked = false;
        }
        else if (userrequest.accountStatus === 'invalidb2buser') {
          this.translate.get('views.js.alerts.userrequest.User cannot be invited as the account already exists in another CIAM environment and cannot be duplicated.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg:res});
          });
          this.submitClicked = false;
        }
        else {
          this.router.navigate(['/userrequest/list']).then(nav => {
             // true if navigation is successful
          }, err => {
             // when there's an error
          });
        }
      });
    }
    else {
      let technicalid;
      if (this.userrequestForm.get('azureServiceId').value === "" ||this.userrequestForm.get('azureServiceId').value === null ) {
          technicalid=null;
      }
      else
      {
          technicalid = this.userrequestForm.get('azureServiceId').value.technicalId;
      }
     const request ={
      "status":this.accoountStatus,
      "email": this.userrequestForm.get('email').value,
      "orgId":this.commonService.selectedOrg[`id`],
      "roleIDs":[],
      "sendEmail":true
      }
      if (this.selectedroles.length > 0) {
        this.selectedroles.forEach(element => {
          request.roleIDs.push(element._id);
        });
      }
      this.commonService.addExistingUser(request).subscribe(data =>
      {
        if(data['code']==200)
        this.router.navigate(['/userrequest/list']).then(nav => {});
      else
      {
        this.translate.get('views.js.alerts.userrequest.User cannot be invited as the account already exists in another CIAM environment and cannot be duplicated.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg:data['message']});
        });
      }
      }
)

}



}
onLangChange(event: any) {
    this.userrequestForm.get('mainLanguage').setValue(event.target.value);
    this.userrequestForm.get('mainLanguage').updateValueAndValidity();
  }

  onAzureCheckBoxChange(event: any) {
    this.alerts = [];
    this.alerts.push();
    this.otherUserType = false;
    if (((this.existingUserProfile[0]?.userType.toLowerCase() === 'clientidp' ||
      this.existingUserProfile[0]?.userType.toLowerCase() === 'external')
      && this.userrequestForm.get('userType').value === 'azureb2b')
      ||
      (this.existingUserProfile[0]?.userType.toLowerCase() === 'azureb2b' &&
        !(this.userrequestForm.get('userType').value === 'azureb2b'))) {
      this.otherUserType = true;
      if (this.otherUserType) {
        this.msg = this.translate.instant('views.js.alerts.userrequest.User cannot be invited as the account already exits in CIAM and the usertype is') + this.existingUserProfile[0]?.userType;
        this.alerts.push({ type: 'danger', msg: this.msg });
      }
    }
  }

  authorized() {
    if((this.permission.isHelpDeskAdmin)&&!(this.permission.isAdmin || this.permission.isOrganizationAdmin))
    {
      this.router.navigate([''])
    }
    return this.permission.isAdmin || this.permission.isOrganizationAdmin;
  }
  changePreferredCountries() {
    this.preferredCountries = [CountryISO.UnitedStates, CountryISO.Canada];
  }
  checkUniqueValue(email) {
 this.isLoading =true;
    const e = this.userrequestForm.value.email!=""?(this.userrequestForm.value.email).toLowerCase():"";
    const orgid= this.commonService.selectedOrg[`id`];
    // this.checkuniqueValue= true;
    this.commonService.getuserDetailsCheck(orgid,e).subscribe(userData =>{
      console.log(userData);
      const result = JSON.parse(userData).result;
      this.existingUserProfile = result;
      if (result?.length > 0) {
        if (((this.existingUserProfile[0]?.userType.toLowerCase() === 'clientidp' ||
          this.existingUserProfile[0]?.userType.toLowerCase() === 'external')
          && this.userrequestForm.get('userType').value === 'azureb2b')
          ||
          
          (this.existingUserProfile[0]?.userType.toLowerCase() === 'azureb2b' &&
          !(this.userrequestForm.get('userType').value === 'azureb2b'))) {
          this.msg = this.translate.instant('views.js.alerts.userrequest.User cannot be invited as the account already exits in CIAM and the usertype is') + this.existingUserProfile[0]?.userType;
          this.alerts.push({ type: 'danger', msg: this.msg });
          this.otherUserType = true;
        }
        this.accoountStatus = result[0]?.accountStatus;
        // this.accoountStatus ="active"
        this.uniqueValue = true;
        this.isLoading = false;
        this.userrequestForm.get('firstname').setValue(result[0].givenName);
        this.userrequestForm.get('lastname').setValue(result[0].sn);
      }
      else {
        this.accoountStatus = "pending";
        this.uniqueValue = false;

        this.isLoading = false;

      }

    })
  }
  addUserToCurrentOrg(email) {

    this.addtoaditionalorg = true;
    this.uniqueValue = false;

    this.titlesub = 'an existing';

  }
  navigateToList() {
    this.router.navigate(['userrequest/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  moveItem1(item, from, to) {
    const idx = this.selectedroles.indexOf(item);
    if (idx !== -1)
    {
      this.selectedroles.splice(idx, 1);
      this.availableroles.push(item);
    }
  }
  moveItem2(item, from, to) {
    const idx = this.availableroles.indexOf(item);
    if (idx !== -1)
    {
      this.availableroles.splice(idx, 1);
      this.selectedroles.push(item);
    }
  }
  isDisabled() {
    if (this.submitClicked || ((this.userrequestForm.status === 'INVALID')&& !(this.addtoaditionalorg))|| this.uniqueValue || this.isLoading ) {
      return true;
    }
    else {
      return false;
    }
  }

}
