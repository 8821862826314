<div class="header-self-service">
    <div class="logo-white">
        <img src="assets/img/self-service/deloitte-logo-white.svg">
    </div>
    <div>
        <i class="fa fa-globe" style="color:white;font-size: 24px;"></i>
        
            <select  [(ngModel)]="touLang" id="language" (change)="touchangeLanguage(touLang)">

                <option *ngFor="let language of touLanguages" value={{language}}>{{language}}</option>
            </select>
       
    </div>
</div>
<div class="main-container self-service-main-container" style="margin-top: 100px; max-width: 500px; margin-left: 5%">
    <div class="form-container self-service-form-container"
         style="display:block; max-width: none"
         ng-show="true">
         <p class=MsoNormal style='line-height:normal;background:white'><b>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'><a style="color: blue" href="" onclick="javascript:window.open('','_self').close()">Close</a></span></b></p>
    <div *ngIf="currentTouLanguage == touLanguages[0]">
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Client
    Identity Access Management</span></b></p>
    
    <h1 class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>TERMS OF
    USE</span></b></h1>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-family:"Arial",sans-serif;color:#100B0C'>Last revised: </span><span
    style='font-family:"Arial",sans-serif;color:#100B0C'>June 29, 2020</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-family:"Arial",sans-serif;
    color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>WHO THESE TERMS OF USE APPLY
    TO; WHAT THEY GOVERN.</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>By using </span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>this Client Identity
    Access Management website</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> (this “Website”), you are agreeing to these Terms of Use</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> (“Terms”)</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> on behalf of yourself and
    the company, organization, or other legal entity that you work for as a
    partner, principal, director, employee, or contractor and, if applicable, its
    affiliates (“your Company”).</span></b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Thereafter,
    these Terms shall be a legally binding agreement between you and Company on the
    one hand, and the Deloitte Firm (as defined below) that invited you to this
    Website on the other (referred to herein as &quot;we&quot;, &quot;us&quot; or
    “our”).   </span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>If
    you do not agree to these Terms, then you are not allowed to use this Website
    and should immediately terminate such usage.</span></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>As used herein,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> “<b>Deloitte </b></span><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Firm</span></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>” means</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    Deloitte Touche Tohmatsu Limited (“<b>DTTL</b>”), </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>(ii) each member firm of DTTL and </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>the </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>affiliates of such </span><span style='font-family:"Arial",sans-serif'>member
    firms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> DTTL and </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>the Deloitte Firms and any of </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>their related entities</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> are referred to herein collectively as
    the “<b>Deloitte Network</b>”. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoBlockText align=left style='text-align:left'><span
    style='font-size:11.0pt'>Where you or Company are our client and we have
    agreed, pursuant to an engagement letter or other contract (each an &quot;<b>Engagement
    Letter</b>&quot;) to the performance of services or delivery of deliverables
    (an “<b>Engagement</b>&quot;), then all</span><span style='font-size:11.0pt'>
    Engagement services and deliverables shall be governed by the Engagement Letter
    and, where applicable, these Terms. As set forth more fully in <b>Section 9A</b>,
    in the event of any conflict between an Engagement Letter and these Terms, the
    terms of the Engagement Letter shall control with respect to the applicable
    Engagement.  Where neither you nor </span><span style='font-size:11.0pt'>Company</span><span
    style='font-size:11.0pt'> are our client, these Terms shall apply except as set
    forth in <b>Section 9A</b>.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Although parts of these Terms may
    reference other entities in the Deloitte Network, these Terms are only with us
    and not with any of those other entities.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>2. Eligibility; License; Other
    Applications</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>; Restrictions; Privacy Statement</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal;page-break-after:
    avoid'><b><i><span style='font-family:"Arial",sans-serif'>A. Eligibility. </span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>To be eligible to use this Website, you
    must be at least 18 years old and have full power and authority to enter into
    these Terms on behalf of yourself and Company. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>You
    must also not be prohibited from entering into these Terms or using any portion
    of this Website by us, Company, any other party, or any contractual, regulatory
    or other legal restrictions. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You hereby represent and warrant to us
    that you meet these eligibility requirements.</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You must notify us if circumstances
    change such that you are no longer in compliance with the eligibility
    requirements set forth in the paragraph above. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>     </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B. License.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>On the condition that you comply with
    all your obligations under these Terms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>,
    we grant you a limited, revocable, nonexclusive, non-assignable,
    non-sublicensable right to access and use the Website only as expressly
    permitted herein.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>C. Other Applications.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>On the condition that you
    comply with all of your obligations under these Terms</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>, you are authorized to
    use this Website solely for the purpose of managing your account and profile
    information in order to obtain access to one or more other applications and
    websites (“<b>Other Applications</b>”).</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Your use of such Other
    Applications are governed by all license terms, terms of use, legal terms,
    privacy notices, and similar terms on such Other Applications (“<b>Other
    Application Terms</b>”) and you and your Company must comply with all Other
    Application Terms.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>D. Certain restrictions applicable to
    your User Account and general usage of this Website<span style='color:#92D050'>.</span></span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>In order to use this Website, you </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>will receive access through </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>a </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>user
    account (“</span><b><span style='font-family:"Arial",sans-serif'>User Account</span></b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>”).  </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif;color:#100B0C'>You and your Company are responsible for
    anything that happens through your user account until it is deactivated.
    Without limiting the foregoing, you and your Company agree to the following: </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will only create and
    maintain one </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> at
    any given time, which shall be for you alone and not shared or transferred to
    anyone else.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will only access the
    permitted sections of this Website that you have been granted access and will
    not hack into or otherwise attempt to access other areas of this Website.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and your Company will
    keep your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>
    password secure and confidential. </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will not remove any
    copyright, trademark, or other proprietary rights notices found on this Website
    or its content.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and Company will
    comply with all laws applicable in accessing and using this Website, including
    all data privacy, export control, copyright, and securities laws and
    regulations.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will immediately cease
    all use of your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> and
    this Website upon the termination of your employment or other work relationship
    with your Company. Your Company must notify us in writing of such termination,
    and your Company remains responsible for your actions with respect to your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> and this Website until
    such notification and related deactivation of your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt; font-family:"Arial",sans-serif;color:#100B0C'>You and Company will not
    reverse engineer, decompile or otherwise attempt to derive the source code for
    any underling software related to this Website, or adapt, modify or create
    derivative works based on this Website or its underlying technology. </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and your Company will
    not engage in any action that directly or indirectly interferes with the proper
    working of, or places an unreasonable load on, </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif;color:#100B0C'>this</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> Website or the underlying infrastructure, including spamming
    and the distribution of computer viruses or other malicious code.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You or your Company will
    notify us immediately upon becoming aware of any unauthorized use of your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> or any other breach of
    the above obligations.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>E. Privacy.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>You
    and Company acknowledge and agree that we may disclose your User Account
    information </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>(including any
    information you provide in connection with multi-factor authentication (i.e.,
    first name, last name, corporate email address, corporate cell / telephone
    number)) </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>to others if we
    have a good faith belief that such action is reasonably necessary to comply
    with law, regulatory requirements, professional standards, or prevent harm, or
    as otherwise provided in the applicable Engagement Letter, these Terms or the
    Privacy Statement.</span>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Please review the</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;<span class=MsoHyperlink><a
    href="/dis/public/#/privacynotice" target="_blank" >Privacy
    Statement</a></span><b>&nbsp;</b></span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>for
    more information regarding the ways in which your personal information is
    collected in connection with your use of this Website, the purposes for which
    your personal information is used, and how it is shared.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>   </span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>User Account Deactivation</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Deactivating Your User Account.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You or Company may deactivate your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>, for any or no reason, at any time,
    with written notice to us</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    as per <b>Section 7 (Notices)</b>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> This
    notice will be effective upon our processing such notice. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    may </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>deactivate your User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> for any reason or
    no reason, at any time, with or without notice, effective immediately or as may
    be specified in any given notice. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Without limiting the foregoing, your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> may be deactivated by Website
    administration if inactive for extended time periods.</span></p>
    
    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:40.5pt;text-indent:-40.5pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Effect of Deactivation.</span></i></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:6.0pt;text-indent:-18.0pt;
    line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>1.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Usage</span></u><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Deactivation
    of your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> results in the immediate termination of
    your ability to </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>technologically
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>access and use this
    Website.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> In addition, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>we may bar </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>you from any future use of this Website
    and also </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>block access to</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> this Website from any IP address or
    range of IP addresses associated with you.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;text-indent:
    -18.0pt;line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>2.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Surviving Terms</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Section 2B</span></b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> of these Terms shall not survive
    deactivation of your User Account and termination of your license to access and
    use this Website. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>The remainder of these Terms shall
    survive.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;line-height:
    normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Federated Access Terms</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>If your Company has agreed
    to federate to Deloitte systems by accepting federated access terms, your
    authentication to this Website will be governed by those Federation Terms. </span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Intellectual Property
    Rights; No use of Deloitte names or logos</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>This Website and its
    contents are protected by copyright, trademark, and other laws of the United
    States and/or foreign countries. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>We
    and our licensors reserve all</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    rights not expressly granted in these Terms</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>“Deloitte”, “Touche”,
    “Tohmatsu”, “Deloitte Touche Tohmatsu”, “Deloitte &amp; Touche”, the Deloitte
    logo, and local language variants of the foregoing trademarks, and certain
    product names that appear on this Website (collectively, the “<b>Deloitte Marks</b>”),
    are trademarks or registered trademarks of entities within the Deloitte
    Network. Except as expressly provided in these Terms or as expressly authorized
    in writing by the relevant trademark owner, neither you nor your Company shall
    use any Deloitte Marks either alone or in combination with other words or design
    elements, including in any press release, advertisement, or other promotional
    or marketing material or media, whether in written, oral, electronic, visual or
    any other form.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>References to other
    parties’ trademarks on this Website are for identification purposes only and do
    not indicate that such parties have approved this Website or any of its
    contents. These Terms do not grant you any right to use the trademarks of other
    parties.</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Disclaimers and Limitations
    of Liability</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>neither we nor
    any other ENTITY WITHIN the Deloitte Network<b> </b>is, by means of PERMITTING
    YOU ACCESS</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'> TO </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'>this Website </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>(INCLUDING
    ALL</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>TECHNOLOGY, WEBSITE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> CONTENT</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>, OUTPUT</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>,
    AND </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>OTHER CONTENT),</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> RENDERING PROFESSIONAL ADVICE OR
    SERVICES</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> TO YOU OR COMPANY.
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> BEFORE MAKING ANY DECISION
    OR TAKING ANY ACTION THAT MIGHT AFFECT YOUR OR </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>COMPANY'S</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  FOR CLARITY, ADVICE AND DELIVERABLES
    THAT WE PROVIDE PURSUANT TO AN ENGAGEMENT LETTER SHALL BE GOVERNED BY THE TERMS
    OF THAT ENGAGEME<span style='text-transform:uppercase'>NT LETTER, EVEN IF
    ACCESSIBLE BY OR DELIVERED TO YOU VIA THIS WEBSITE.  </span></span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>ALTHOUGH WE TAKE COMMERCIALLY REASONABLE
    STEPS TO MAKE </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>THIS WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>USEFUL AND SECURE, THIS WEBSITE
    (INCLUDING ALL WEBSITE TECHNOLOGY, WEBSITE CONTENT, OUTPUT, AND OTHER CONTENT) </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>IS PROVIDED AS IS, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>WITHOUT WARRANTY OF ANY KIND.  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>NO ENTITY WITHIN THE DELOITTE NETWORK </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>HAS ANY OBLIGATION TO PROVIDE
    MAINTENANCE AND SUPPORT SERVICES</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    OR </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>ANY UPDATE, UPGRADE,
    ENHANCEMENT, NEW FUNCTIONALITY, MODIFICATION, PATCH, BUG FIXES,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> OR </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>SIMILAR DELIVERABLE WITH RESPECT TO THIS WEBSITE. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> WITHOUT LIMITING THE FOREGOING, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>WE DO NOT WARRANT</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> THAT THIS WEBSITE WILL BE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> AVAILABLE,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> SECURE, ERROR-FREE, FREE FROM VIRUSES
    OR MALICIOUS CODE, OR WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR
    QUALITY</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>, AND WE EXPRESSLY
    DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
    NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>YOUR USE OF THIS WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>(INCLUDING ALL WEBSITE TECHNOLOGY,
    WEBSITE CONTENT, OUTPUT, AND OTHER CONTENT) </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>IS AT YOUR OWN RISK AND </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>YOU</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE,
    INCLUDING WITH RESPECT TO LOSS OF SERVICE OR DATA. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> WE</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    WILL</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> NOT</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> BE LIABLE FOR ANY DIRECT, INDIRECT,
    SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES
    WHATSOEVER, WHETHER IN AN ACTION OF CONTRACT, STATUTE, TORT (INCLUDING
    NEGLIGENCE), OR OTHERWISE, RELATING TO THE USE OF THIS WEBSITE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> (INCLUDING ALL WEBSITE TECHNOLOGY, WEBSITE
    CONTENT, OUTPUT, AND OTHER CONTENT).</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>Certain links
    on this Website may lead to resources, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>WEBSITES, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>AND TOOLS maintained by third parties over which we
    have no control, including THOSE maintained OR PROVIDED by other </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>ENTITIES WITHIN
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>the
    Deloitte Network. We make no representations or warranties as to the accuracy
    of, or any other aspect relating to, those resources,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'> WEBSITES, AND
    TOOLS</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'> AND LINKS TO
    ANY SUCH </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'>RESOURCES, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>WEBSITES, AND TOOLS SHOULD NOT BE CONSTRUED AS AN
    ENDORSEMENT OF THEM OR THEIR CONTENT</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'> BY US OR AN ENDORSEMENT BY THEM OF US OR THIS
    WEBSITE. </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>The above
    limitations of liability shall be applicable not only to us but also to each
    other entity within the Deloitte Network</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'> AND TO </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>OUR AND </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>THEIR RESPECTIVE PERSONNEL.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>  </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>IF ANY OF <span style='text-transform:
    uppercase'>The above limitations of liability </span>IS INVALID OR
    UNENFORCEABLE IN ANY JURISDICTION, THEN (I) IN THAT JURISDICTION IT SHALL BE
    RE-CONSTRUED TO THE MAXIMUM EFFECT PERMITTED BY LAW TO EFFECT ITS INTENT AS
    NEARLY AS POSSIBLE AND THE REMAINING TERMS SHALL REMAIN IN FULL FORCE AND
    EFFECT, AND (II) IN EVERY OTHER JURISDICTION ALL OF THESE TERMS SHALL REMAIN IN
    FULL FORCE AND EFFECT.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>COMPANY AGREES TO INDEMNIFY US AND EACH
    OTHER ENTITY WITHIN THE DELOITTE NETWORK, AND OUR AND THEIR PERSONNEL, AGAINST
    ALL COSTS, CLAIMS, LOSSES OR EXPENSES INCURRED BY OR MADE AGAINST ANY OF THE
    FOREGOING AS A RESULT OF ANY BREACH BY YOU OR COMPANY OF THESE TERMS, INCLUDING
    ANY THIRD-PARTY CLAIM THAT ANY COMPANY CONTENT INFRINGES ANY INTELLECTUAL
    PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY.</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>NOTICES.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.     </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notices from us to you.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></i></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> Any notices from us to you regarding this Website may be
    by any of the following methods:</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> (i) general postings to
    users on this Website, (ii) any communicative function available through your
    User Account, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>or</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> (iii) the email address associated with
    your User Account. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>It is your responsibility to keep the
    contact information in your User Account current and accurate.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.     </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notices from you to us<span
    style='color:#92D050'>.</span></span></i></b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> 
    Any notices from you to us regarding this Website must be in writing and sent
    to the contact provided for us in the Engagement Letter or License Agreement,
    if any, or to such other contact as we may provide.  Such notices should be
    provided using the same mechanism provided for notices in the Engagement Letter</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> If
    there is no Engagement Letter </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>or
    they do</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> not provide for
    notices, then notices from you to us must be by any of the following methods:
    (i) electronic mail; (ii) personal delivery; or (iii) a globally or nationally
    (as the case may be) recognized express mail, courier, or delivery service (“<b>Express
    Courier</b>”).</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> A notice sent by electronic mail shall
    be deemed given on the date of electronic confirmation of receipt.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> A
    notice sent by personal delivery or Express Courier shall be deemed given on
    the date of receipt or refusal of receipt.  </span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;ont-family:"Arial",sans-serif;color:black'>NO PROFESSIONAL
    RELATIONSHIP.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You and Company acknowledge and agree
    that no professional relationship of any nature is created solely by your use
    of this Website or by any correspondence or communication with us or any other
    person or entity relating to this Website.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> For clarification, if a
    professional relationship already exists with us (e.g., pursuant to an
    Engagement Letter), it will not be terminated by your use of this Website.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>The infrastructure of, and certain
    Website technology and content found on, this Website are provided to us by
    DTTL or one or more of its related entities.</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> However,
    your use of this Website is solely at our invitation, and neither DTTL nor any
    of those related entities is providing a service to you or Company or entering
    into a professional relationship with you or Company by way of this Website.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> Unless
    otherwise set forth specifically in a separate written agreement, any service
    that you receive and relationship that you have with respect to this Website is
    with us alone and is governed by these Terms and any related Engagement Letter.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#0070C0'>&nbsp;</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal;page-break-after:
    avoid'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>9.   
    GENERAL TERMS.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Entire Agreement; Engagement Letter</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Subject to the following two sentences,
    these Terms constitute the full and complete agreement between you and Company
    on the one hand, and us on the other, with respect to the subject matter
    hereof, and supersede any oral and prior written agreements with respect to
    such subject matter.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> In the event of any conflict between
    these Terms and an Engagement Letter, the terms of the Engagement Letter shall
    control with respect to the applicable Engagement. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Where
    (i) neither you nor Company are our client and your activity on this Website is
    not covered by an Engagement Letter and (ii) we have entered into a separate
    written agreement with you or Company with respect to this Website, then, in
    the event of a conflict between such agreement and these Terms, such agreement
    shall control.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Amendments to these Terms.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We may revise these Terms at any time in
    our sole discretion by posting such revised terms at this <span
    style='color:#0070C0'>Terms of Use </span>link in this Website’s footer (i.e.,
    this webpage that you are currently viewing) or elsewhere on this Website, or
    otherwise notifying you in accordance with Section 7 (Notices).</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> Such revised terms shall be effective to you upon posting
    or other notice, unless otherwise explicitly stated by us.</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>It is your and Company’s responsibility
    to be aware of any such revised terms by checking here and reading your
    notices.</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If you or Company does not agree with
    any of these Terms as they may be amended from time to time, you should
    deactivate your User Account as per Section 3 (</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account Deactivation</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>). </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>C.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> No informal waivers, agreements or
    representations.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>No waiver of any breach by you or
    Company, or of any objection to any act or omission connected therewith, shall
    be implied or claimed by you or Company or be deemed to constitute a consent to
    any continuation of such breach, act or omission, unless contained in a writing
    signed by us. </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>D.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Dispute resolution; equitable relief<span
    style='color:#92D050'>. </span></span></i></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt;text-indent:-18.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Choice of Law;
    etc</span></u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> Any
    choice of law, venue, and dispute resolution terms and procedures applicable to
    the Engagement Letter shall govern these Terms, and all rights and obligations
    arising from or otherwise relating to them. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>In the
    event no Engagement Letter</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>exists, then (i) these Terms shall be
    governed by, construed in accordance with, and enforced under the laws (both
    substantive and procedural) of the State of New York, USA (but specifically
    excluding the United Nations Convention on Contracts for the International Sale
    of Goods), without reference to principles of conflict of laws; (ii) you,
    Company and we agree to submit to the exclusive jurisdiction and venue of the 
    federal and state courts situated in New York State with respect to any dispute
    arising hereunder or in connection with such rights and obligations and (iii)
    you, Company and we also agree to irrevocably waive any right to a jury trial
    in any action, proceeding or counterclaim relating to these Terms.  </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notwithstanding
    the above, we shall still be allowed to apply for injunctive remedies (or an
    equivalent type of urgent legal relief) in any jurisdiction.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt;text-indent:-18.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Equitable Relief</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'></span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> In no event shall you or
    Company seek or be entitled to rescission, injunctive or other equitable
    relief, or to enjoin or restrain the operation, use or exploitation of this
    Website or any part of it, including any Website technology or Website content.
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We shall be entitled to injunctive or
    other equitable relief in order to prevent, mitigate or remedy the breach or
    continuing breach of these Terms.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>E.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Remedies not exclusive.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Exercise or enforcement of a right or
    remedy given in these Terms shall not be considered to be in lieu of
    enforcement of other rights or remedies otherwise existing at law or equity,
    unless specifically waived in writing.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>F.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Severability.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If any term in these Terms</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> is invalid or unenforceable in any
    jurisdiction, then (i) in that jurisdiction it shall be re-construed to the
    maximum </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>extent</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> permitted by law to effect its intent
    as nearly as possible and the </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>remaining
    terms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> shall remain in full
    force and effect, and (ii) in every other jurisdiction all of these Terms shall
    remain in full force and effect.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>            </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>G.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> No construction against the drafter.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If an ambiguity or question of intent or
    interpretation arises with respect to these Terms, these Terms will be
    construed as if drafted jointly by you, Company and us, and no presumption or
    burden of proof will arise favoring or disfavoring any of those parties by
    virtue of authorship of these Terms.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>H.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Assignment and delegation.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You and Company may not assign or
    delegate any rights or obligations under these Terms, and any such purported assignment
    or delegation shall be ineffective. We may freely assign or delegate all rights
    and obligations under these Terms, fully or partially, without notice to you or
    Company. These Terms shall be binding upon your, Company’s and our successors
    and permitted assigns.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>I.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Relationship of the parties; third
    party beneficiaries.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>No agency, partnership, franchise, or
    joint venture is created by these Terms between you and Company on the one
    hand, and us on the other.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Certain Deloitte Firms and DTTL and its
    related entities are third party beneficiaries to certain sections of these
    Terms, and may enforce specific rights hereunder.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>J.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Language.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>In the event that we have provided you
    or Company with a translation of the English language version of these Terms,
    the Privacy Statement, and/or any other documentation, you and Company agree
    that the translation is provided for convenience only and that the English
    language version governs your relationship with us. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>K.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Construction.</span></i></b></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:36.0pt;text-indent:-18.0pt'><span style='font-size:11.0pt;
    font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Agreement
    Headings and Numbering.</span></u><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>  Paragraph or Section numbers and headings that are used
    in these Terms are included for convenience only and, if there is any conflict
    between any such numbers and headings and the text of these Terms, the text
    shall control.</span></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:36.0pt;text-indent:-18.0pt'><span style='font-size:11.0pt;
    font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Including.</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  As used in these
    Terms, the word “including” means “including, without limitation,” and the word
    “include” means “include, without limitation.”</span></p>
    </div>
    
    <div *ngIf="currentTouLanguage == touLanguages[1]">
            <div class="form-container self-service-form-container" style="display:block; max-width: none">
                    <div class="title">CIAM Consumer Identity and Access Management</div>     
                    <div class="title">利用規定</div>
                    <p class="main-text">最終改定日： 2020年 6月 29日</p>
                    <div class="title">1. 適用対象者；対象事項</div>
                    <p class="main-text">&nbsp;この 「 CIAM Consumer Identity and Access Management」 Webサイト（｢当 Webサイ
    ト｣）のご利用により、利用者のみなさまは、ご自身のみならず、ご自身がパートナー、プリン
    シパル、取締役、従業員または契約業者として所属 ・関係 する 企業 、組織その他の法人または場
    合によりその関連会社（｢ 利用者企業 等｣） のために 、 当 利用 規定 （｢ 当規定 ｣）に同意したことに
    なります。その後、 当規定 は、 利用者および 利用者企業等 と、利用者を当 Webサイトに招待し
    たデロイトファーム（以下に定義 ）（｢当ファーム｣ ）との間 の 法的拘束力を有する合意となるも
    のとします。 利用者が 当規定 に 同意 し ない 場合 、当 Webサイトを利用することはできないため、
    直ちに利用を中止してください。
    当規定 における「 デロイトファーム 」とは、デロイト・トウシュ・トーマツ・リミテッド
    （｢ DTTL｣）、 DTTLの各メンバーファームおよび当該メンバーファームの関 係 法人を意味しま
    す。 DTTLおよびデロイトファームならびにその各々の関 係 法人を総称して「 デロイト・ネット
    ワーク 」といいます。
    利用者または 利用者企業等が 当ファーム のクライアントであり、 エンゲージメントレター そ
    の他の 契約 書面（個々に「 エンゲージメントレター 」）により、 当ファームが サービ ス の履行ま
    たは成果物の提供（｢ エンゲージメント ｣）に合意している場合、エンゲージメントのすべてのサ
    ービスおよび成果物に エンゲージメントレター の規定が適用され、必要に応じ、 当規定 も適用さ
    れます。 第９ 条 A項 に詳細に規定されているとおり、 エンゲージメントレター と 当規定 との間
    に齟齬がある場合、該当するエンゲージメントについては エンゲージメントレター の規定が優
    先します。利用者または利用者企業等のいずれも 当ファーム のクライアントでない場合には、 第
    9条 A項 に定める場合を除き、 当規定 が適用されるものとします。
    当規定 の別の 項に デロイト・ネットワーク に属する他の 法人 に関する記載 があるとしても、 当
    規定 の当事者はあくまで 当ファーム であり、それらの 法人 が含まれることはありません。</p>
                    
                    <div class="title">2. 利用資格；ライセンス；他のアプリケーション 等 ；制限；プライバシー ・ ステートメント</div>                
                    <div class="form-title">A. 利用資格</div>
                    <p class="main-text">当 Webサイトは、 利用者が 18歳以上であり、かつご自身および利用者企業等のために 当規
    定 を締結する完全な権限を有する 場合に限り 利用できます。また、 利用者が当ファーム 、利用者
    企業等、その他の当事者または契約上、規制上その他の法的制約により、 当規定 の締結または当
    Webサイトの利用を禁止されていないことも条件とします。利用者は、これらの利用資格を満サイトの利用を禁止されていないことも条件とします。利用者は、これらの利用資格を満たしていることをたしていることを当ファーム当ファームに対して表明・保証するものとします。に対して表明・保証するものとします。
    状況の変化により、状況の変化により、利用者が利用者が上記の利用資格上記の利用資格を満たさない状態となった場合には、を満たさない状態となった場合には、当ファーム当ファームにご連絡ください。にご連絡ください。</p>
                    <div class="form-title">B. ライセンス</div>
                    <p class="main-text">利用者が 当規定 に 基づく すべての義務を遵守することを条件として、 当ファーム は利用者に
    対し、 当規定 により明示的に認められる範囲で のみ 、当 Webサイ トにアクセスし、利用する限
    定的、取消可能、非独占的、譲渡不可、サブライセンス不可の権利を 許諾 します。</p>
                    <div class="form-title">C. 他のアプリケーション等</div>
                    <p class="main-text">利用者が 当規定 に 基づく すべての義務を遵守することを条件として、利用者は、他のアプリケ
    ーションおよび Webサイト（｢ 他のアプリケーション等 ｣）へのアクセスを取得するために ご自
    身 のアカウントおよびプロフィールに関する情報を管理する目的にのみ、当 Webサイトを利用
    する権限を付与されます。
    利用者による他のアプリケーション等の利用には、当該他のアプリケーション等に関するす
    べてのライ センス条項、利用 規定 、法的条件、プライバシー通知等の規定（｢ 他のアプリケーシ
    ョン等 規定 ｣）が適用され、利用者および利用者企業等は 、 他のアプリケーション等 規定 をすべ
    て 遵守 すること を義務付けられます。</p>
                    <div class="form-title">D. 利用者の ユーザー アカウントおよび当 Webサイトの利用全般に適用される制限</div>
                    <p class="main-text">当 Webサイトを利用するためには、ユーザーアカウントを通じてアクセスを取得する必要が
    あります。利用者のユーザーアカウントが無効化されない限り、 その ユーザーアカウントを通じ
    て発生するすべての事項に対する一切の責任は 、 利用者および利用者企業等 にあります 。上記を
    制限することなく、 利用者および利用者企業等は 、 以下の各項目に同意するものとします。</p>
                    <ol class="main-text" style="    list-style: circle;    margin-left: 5%;">
                        <li>利用者 が設定 ・ 維持できるユーザーアカウントはいかなる時点であれ１つのみであり、
    そのアカウントは利用者専用 とし 、他者と共有または他者 に 譲渡 すること は でき ま せ
    ん 。</li>
    <li>利用者が アクセスできるのは、当 Webサイトの中でアクセスを付与された範囲のみで
    あり、当 Webサイトのその他の部分にアクセスしようとするハッキングその他の試み
    は禁止します。</li>
    <li>利用者および利用者企業等は、ユーザーアカウントのパスワードを安全かつ機密に保つ
    ものとします。</li>
    <li>利用者は、当 Webサイトまたはそのコンテンツの著作権表示、商標表示その他の知的
    財産権表示を削除してはならないものとします。</li>
    <li>利用者および利用者企業等は、当 Webサイトへのアクセスおよび使用に適用されるす
    べての法令（データの保護、輸出規制、著作権および有価証券に関連する法令を含みま
    す） の遵守を義務付けられます。</li>
    <li>利用者は、利用者企業等との雇用その他の契約関係が終了した場合、直ちにユーザーア
    カウントおよび当 Webサイトの利用をすべて中止するものとします。利用者企業等は、
    その終了について書面により 当ファーム に通知するものとし、その通知に加え、利用者
    のユーザーアカウントが無効化される時点まで、利用者のユーザーアカウントおよび当
    Webサイトに関連する利用者の行為につい ては引き続き利用者企業等が責任を負うも
    のとします。</li>
    <li>利用者および利用者企業等は、リバースエンジニアリングまたは逆コンパイル等によ
    り、 当 Webサイトに関連する基本ソフトウェアのソースコードを読み取る試みや、当
    Webサイトまたはその基礎技術に基づく改変、変更または二次的著作物の創作 の試みを
    してはならないものとします。</li>
    <li>利用者および利用者企業等は、直接的 または 間接的に当 Webサイトまたは基盤インフ
    ラストラクチャーの動作を妨げる、または それらに 不当な負荷をかける行為（スパム行
    為 や 、コンピュータウイルスそ の他の悪意あるコード の配布を含みます をしてはなら
    ないものとします。</li>
    <li>利用者または利用者企業等は、 利用者のユーザーアカウントの不正使用その他の上記の
    義務に違反する行為に気付いた場合、直ちに 当ファーム に通知するものとします。</li>
                    </ol>
                    
                    <div class="form-title">E. プライバシー</div>
                    <p class="main-text">利用者および利用者企業等は、法律、規制要件 および プロフェッショナル基準 を遵守するた
    め、または被害を防止するために 合理的に 必要であると 当ファーム が誠実に判断した場合、また
    は適用ある エンゲージメントレター 、 当規定 またはプライバシー・ステートメントに 別 段の規定
    がある場合、 利用者のユーザーアカウント情報（多要素認証に関連して利用者が提供した情報
    （すなわち、姓と名、 勤務先の 電子メールアドレスおよび電話番号ならびに携帯電話番号）を含
    みます） を 当ファーム が 他者に 開示する場合があることを認め、同意するものとします。利用者
    による当 Webサイトの利用に関連して利用者の個人情報を収集する方法、利用者の個人情報の
    利 用目的および共有 方法の 詳細については、 プライバシー・ステートメント を参照。</p>
                          
    <div class="title">3. ユーザーアカウントの無効化</div>
    <div class="form-title">A. 利用者のユーザーアカウントの無効化</div>
                    <p class="main-text">利用者または利用者企業等は、理由を問わずいつでも、 第 7条（通知） に従い書面により 当フ
    ァーム に通知した上で、利用者のユーザーアカウントを無効化することができます。 当該通知
    は、当ファームが当該通知を処理した時点で効力発生するものとします。
    当ファーム当ファームは、理由を問わずいつでも、通知した上で、または通知することなく、直ちに、まは、理由を問わずいつでも、通知した上で、または通知することなく、直ちに、または通知により指定した時点で、利用者のユーザーアカウントを無効化することがあります。たは通知により指定した時点で、利用者のユーザーアカウントを無効化することがあります。ままた、上記を制限することなく、利用者のユーザーアカウントが長期間使用されていない場合、た、上記を制限することなく、利用者のユーザーアカウントが長期間使用されていない場合、Webサイト管理者により無効化されるサイト管理者により無効化される可能性可能性があります。があります。</p>
                    
    <div class="form-title">B. 無効化の効果</div>
                    <p class="main-text">1. 利 用 利用者のユーザーアカウントが無効化されると直ちに、利用者は当 Webサ
    イトへの技術的なアクセスおよび利用ができなくなります。また、 当ファーム は、利用
    者による当 Webサイトの 利用 を停止し、利用者に関連する IPアドレスまたは IPアドレ
    スの範囲から当 Webサイトへのアクセスを遮断する場合があります。
    2. 存続条項 利用者のユーザーアカウントが無効化され、かつ当 Webサイトへのアク
    セスおよび利用に関する利用者のライセンスが終了した時点で、 当規定 第 2条 B項 は効
    力を失うものとします。 当規定 のその他の条項は、その後も効力を維持します。</p>
                    
                    
    <div class="title">4. 連携アクセス 規定</div>
                    <p class="main-text">利用者企業等が連携アクセス 規定 を受け入れ、デロイトシステムとの連携に同意した場合、当
    Webサイトにおける利用者の認証には、当該連携 アクセス 規定 が適用されます。</p>
                    
    
    <div class="title">5. 知的財産権；デロイトの名称 ・ ロゴの使用禁止</div>
                    <p class="main-text">当 Webサイトおよびそのコンテンツは、米国および ・または 諸外国の著作権法、商標法その
    他の法律により適切に保護されています。 当ファーム およびそのライセンサーは、 当規定 により
    明示的に 許諾 していないすべての権利を留保します。 当 Webサイトに表示される 「デロイト
    Deloitte）」 、 「トウシュ（ Touche）」 、 「トーマツ（ Tohmatsu）」 、 「デロイト・トウシュ・
    トーマツ（ Deloitte Touche Tohmatsu）」 、 「デロイト・アンド・トウシュ（ Deloitte & Touche）」、
    デロイトのロゴ 、および これらの商標 を 現地の言語 で表したものや商品名 （総称して「 デロイト
    商標等 」） は、 デロイト・ネットワーク に属する 法人 の商標または登録商標です。 当規定 に明示
    的に規定されている場合またはこれらの商標の所有者 が書面により明示的に許可した場合を除
    き、利用者および利用者企業等のいずれも、デロイト商標等を単独であれ、別の単語またはデザ
    イン要素との組み合わせであれ使用することができず、これには、書面、口頭、電子 形式、視覚
    的形式その他の形式を問わず、プレスリリース、広告宣伝その他の販売促進用またはマーケティ
    ング用の資料およびメディアにおける使用が含まれるものとします。
    当 Webサイトにおける他社の商標の 参照 は識別 のみを 目的 とし ており、 その 他社が当 Webサ
    イトまたはそのコンテンツを承認したことを意味するものではありません。 また、 当規定 は、他
    社の商標を使用する権利を利用者に許諾するものではありません 。</p>
                    
    
                    <div class="title">6. 免責事項および責任 の 限定</div>
                    <p class="main-text">当ファーム またはデロイト・ネットワークに属する他の 法人 のいずれも、利用者に当 Webサ
    イト（ 当 Webサイトに関する技術、 当 Webサイトのコンテンツ、アウトプットその他のコンテ
    ンツのすべてを含みます）へのアクセスを認めることにより、利用者または利用者企業等に対
    し、 プロフェッショナル な アドバイスまたはサービスを提供しているわけではありません。 利用
    者または 利用者企業等の 財務や事業に影響を与える可能性のある事項については、何らかの決
    定 や 行動を 行う前に 、適切な資格を有する専門家にご相談 ください 。 なお、 当ファーム が エンゲ
    ージメント レター に従い提供する助言および成果物 に は、当 Webサイト を通じて 利用者が それ
    らに アクセスできるとしても、または当 Webサイトを通じて それらが 利用者に提供 され るとし
    ても、当該 エンゲージメントレター の規定が適用されることにご留意ください。
    当ファーム は、当 Webサイトが有益かつ安全なものであるよう商業的に合理的な措置を講じ
    ていますが、当 Webサイト（当 Webサイトに関する技術、当 Webサイトのコンテンツ、アウ
    トプットその他のコンテンツのすべてを含みます）は 、いかなる 表明 も 保証 もなく、 現状有姿の
    ままで提供されるもので す。 また、 デロイト ・ネットワークに属するいずれの 法人 も、当 Webサイト に関する保守・支援サービス または アップデート、アップグレード、機能の拡張、新たな
    機能、変更、パッチ、バグ修正等の成果物を提供する義務を負うことはありません。また、上記
    を制限することなく、 当ファーム は、 当 Webサイト の可用性 、 安全 性 、誤りがないこと、ウィ
    ルス また は悪質なコードが 含まれてい ないこと、または特定の性 能 ・ 品質基準 を満たして いるこ
    と を 保証するものでは なく、 市場性、 権原 、特定の目的 への 適合性、 非 侵害 性 、互換性、安全性
    および 正確性の保証を含め 、 黙示的な保証責任 も一切 負 わないものとします 。
    当 Webサイトは、 当 Webサイトに関する技術、当 Webサイトのコンテンツ、アウトプット
    その他のコンテンツのすべてを含め、 利用者ご自身のリスクにおいてご 利用 いただくことを前
    提として おり、 当 Webサイト の利用 に起因する損失 に対する 責任 およびリスク は 、 サービスま
    たはデータの損失に関連するものを含 め、すべて 利用者 が 負 うものとします。 当ファームは、 契
    約上 もしくは 法令上 の行為、過失を含む 不法行為その他のいかなる根拠による かを問わず、 当
    Webサイト （当 Webサイトに関する技術、当 Webサイトのコンテンツ、アウトプットその他
    のコンテンツのすべてを含みます） の 利用に関連 する 直接 的 損害、間接 的 損害、特別損害、偶発
    的 損害 もしくは 結果的損害 の賠償または 懲罰的損害賠償 その他 一切の賠償責任を負わないもの
    とします。
    当 Webサイト には 、 当ファーム が管理していない第三者によって 維持されている リソース、
    Webサイト および ツール （デロイト・ネットワーク に属する 他 の法人 が 維持または提供 する も
    の を含みます） へのリンクが設定されていること があります。 当ファーム は、 これらのリソース、
    Webサイトおよびツール の正確性その他の側面 について、 いかなる 表明 も 保証 もするものでは
    6
    なく、
    なく、またまたこれらのリソースこれらのリソース、、Webサイトおよびツールへのリンクは、サイトおよびツールへのリンクは、当ファーム当ファームによるによるリンリンクク先もしくは先もしくはそのコンテンツそのコンテンツのの承認承認、またはリンク先による、またはリンク先による当ファーム当ファームもしくは当もしくは当Webサイトサイトの承認を意味するものとの承認を意味するものと解釈解釈すべきではありません。すべきではありません。
    上記
    上記の責任限定の責任限定条項条項は、は、当ファーム当ファームのみならず、のみならず、デロイト・ネットワークデロイト・ネットワークに属するに属する他の他の各各法人法人なならびにらびに当ファーム当ファームおよび当該各および当該各法人法人の従業員等の従業員等にも適用されるものとします。にも適用されるものとします。
    上記の上記の責任限定責任限定条項条項がいずれかの法域において無効またはがいずれかの法域において無効または実施不能実施不能とされたとされた場合、場合、(i)そのその法域法域では、では、当該条項はその意図にできる当該条項はその意図にできる限り限り沿うように、法律沿うように、法律により認められるにより認められる範囲で範囲で最大限の効力最大限の効力を有するを有するものとして解釈され、ものとして解釈され、当規定当規定のその他ののその他の条項は条項は完全な完全な効力を効力を維持する維持するものとし、ものとし、(ii)他の他のすべすべての法域ての法域ででは、は、当規定当規定ののすべすべての条項がての条項が完全な完全な効力を効力を維持す維持するものとします。るものとします。
    利用者企業等利用者企業等は、利用者または利用者企業等がは、利用者または利用者企業等が当規定当規定に違反した結果、に違反した結果、当ファーム当ファームおよびおよびデロデロイト・ネットワークイト・ネットワークに属するに属する他の他の各各法人法人ならびにならびに当ファーム当ファームおよび当該各および当該各法人法人の従業員等がの従業員等が費費用、損失もしくは支出用、損失もしくは支出を負担した場合を負担した場合またはこれらの者を相手方とする請求またはこれらの者を相手方とする請求がなされた場合がなされた場合（コ（コンテンツによる個人または団体の知的財産権その他の権利の侵害に対する第三者の請求を含みンテンツによる個人または団体の知的財産権その他の権利の侵害に対する第三者の請求を含みます）、これらのます）、これらの費用、損失、支出または請求について当ファームおよび当該各法人ならびに当費用、損失、支出または請求について当ファームおよび当該各法人ならびに当該従業員等該従業員等を補償することに同意を補償することに同意するものとするものとします。します。</p>
    
    
    
                    <div class="title">7. 通 知</div>
                    <div class="form-title">A. 当ファーム から利用者への通知</div>
                    <p class="main-text">当 Webサイトに関する 当ファーム から利用者への通知は、 (i)当 Webサイト上での利用者のみ
    なさまへの一般的な投稿、 (ii)利用者のユーザーアカウントを通じての連絡、または (iii)利用者の
    ユーザーアカウントに関連付けられた アドレスへの 電子メールのいずれかで行います。利用者
    には、ご自身のユーザーアカウント の 連絡先情報を常に最新かつ正確に保つ責任があります。</p>
    
    
                    <div class="form-title">B. 利用者から 当ファーム への通知</div>
                    <p class="main-text">当 Webサイトに関する利用者から 当ファーム への通知は、書面により、 エンゲージメントレ
    ター またはライセンス契約 に 当ファーム の連絡先が記載されている場合はその連絡先に、また
    は 当ファーム が別の連絡先を指定した場合はその連絡先に郵送するものとします。この通知に
    は、エンゲージメントレターに定める通知のメカニズムと同じメカニズムを用いる必要があり
    ます。エンゲージメントレターが存在しない場合、またはエンゲージメントレターに通知に関す
    る規定がない場合、利用者から 当ファーム への通知は、 (i)電子メール、 (ii)手交、または (iii)世界的
    または全国的な知名度のある速 達、宅配または配達のサービス（｢ 速達宅配便 ｣）のいずれかで行
    うものとします。電子メールによる通知は、受信を電子的に確認した日に受領されたものとみな
    します。 また、 手交または速達宅配便による通知は、その受領日または受取拒否の日に受領され
    たものとみなします。</p>
    
                    <div class="title">8. プロフェッショナル な関係 の不存在</div>
                    <p class="main-text">利用者および利用者企業等は、利用者が当 Webサイトを利用すること、または 当ファーム も
    しくは当 Webサイトに関連するその他の個人もしくは団体との 通信または連絡によってのみ、
    プロフェッショナルな関係が成立するものではない ことを認め、同意 するものと します。なお、
    プロフェッショナルな関係が（たとえばエンゲージメントレターにより）すでに存在する場合、
    利用者が当 Webサイトを利用することにより、その関係が終了することはありません。
    当 Webサイトのインフラストラクチャー ならびに当 Webサイト上の技術およびコンテンツ
    は、 当ファームが DTTLまたはその関 係 法人から提供を受けたものです。但し、利用者はあくま
    で 当ファーム の招待に応じて当 Webサイトを利用するため、 DTTLまたはその関 係 法人のいず
    れも、当 Webサイトを通じて利用者または利 用者企業等にサービスを提供することはなく、利
    用者または利用者企業等との間でプロフェッショナルな関係を結ぶこともありません。別途書
    面による別段の明示的合意がある場合を除き、当 Webサイトに関連 して利用者がサービスの提
    供を受ける、または関係を結ぶ相手方は 当ファーム のみであり、それらのサービスおよび関係に
    は 当規定 および関連するエンゲージメントレターが適用されるものとします。</p>
    
                    <div class="title">9. 一般条項</div>
                    <div class="form-title">A. 完全合意；エンゲージメントレター</div>
                    <p class="main-text">以下の 2文に従うことを前提として、 当規定 は、 そ の主題に関する利用者および利用者企業等
    と 当ファーム との間の完全な合意を構成するものであり、 当規定 の主題に関する口頭および従
    前の書面による合意に優先するものとします。 当規定 とエンゲージメントレターとの間に齟齬
    がある場合、適用あるエンゲージメントレターについてはエンゲージメントレターの条項 が優
    先 します。また、 (i)利用者または利用者企業等のいずれも 当ファーム のクライアントではなく、
    当 Webサイト上での利用者の活動にエンゲージメントレターが適用されない場合で、かつ (ii)当
    ファーム が当 Webサイトに関連して利用者 または利用者企業等との間で別途書面による合意を
    している場合には、その合意と 当規定 との間に齟齬があれば、その合意 が優先 します。</p>
    
                    <div class="form-title">B. 当規定 の改定</div>
                    <p class="main-text">当ファーム は、 単独の裁量でいつでも、当 Webサイトのフッターにリンクが設定されている
    「利用規定」のページ（すなわち、現在表示されている Webページ）または当 Webサイトの
    別の場所に改定後の条項を掲載することにより、または第 7条（通知）に従い利用者に通知する
    ことにより、当規定を改定することが あり ます。 改定後の当規定は、 当ファーム が明示的に別段
    の指示をした場合を除き 、上記の掲載または通知がなされた時点で利用者に対する効力を発生
    するものとします。
    この Web ページを閲覧し、また通知を読むことにより、改定後の本規定を確認する責任は、
    利用者および利用者企業等にあるものとします。
    8
    利用者または利用者企業等が随時改定される当規定の条項に同意しない場合、利用者は、第利用者または利用者企業等が随時改定される当規定の条項に同意しない場合、利用者は、第3条（ユーザーアカウントの無効化）に従い条（ユーザーアカウントの無効化）に従い、、ユーザーアカウントを無効化するものとします。ユーザーアカウントを無効化するものとします。</p>
    
    <div class="form-title">C. 非公式の権利放棄、同意または表明 の不存在</div>
                    <p class="main-text">利用者または利用者企業等 の 違反に対する権利放棄または違反に関連する作為もしくは不作
    為に対する異議申立 の放棄 は、 黙示的に なされることはなく 、また 利用者若しくは利用者企業等
    がこれらを主張する ことはできず 、 当ファーム が署名をした書面によ る場合を除き 、 その違反ま
    たは作為もしくは不作為が継続することへの同意 に該当する とみなされることはありません。</p>
                    
                    <div class="form-title">D. 紛争解決；衡平法上の救済</div>
                    <p class="main-text">1. 法 律 の選択等
    エンゲージメントレターに適用される 法律、法廷 ならびに 紛争解決の 条件および手続
    の選択は、当規定ならびに当規定に起因または関連するすべての権利および 義務に適用
    されるものとします。エンゲージメントレターが存在しない場合には、 (i)当規定は、ア
    メリカ合衆国ニューヨーク州の法律（実 体 法および手続法の双方）（但し、国際物品売
    買契約に関する国際連合条約は明確に除くものとします）に準拠し、同法に従って解釈 ・
    実施され 、 法の抵触に関する原則は 適用されないものとし、 (ii)利用者、利用者企業等お
    よび 当ファーム は、当規定に基づき、または上記の権利および義務に関連して紛争が発
    生した場合、ニューヨーク州内の連邦裁判所および州裁判所を専属的な管轄裁判所およ
    び裁判地とすることに同 意し、 (iii)利用者、利用者企業等および 当ファーム はまた、 当規
    定に関連する訴訟、手続または反訴における陪審裁判の権利を 取消不能 の形で放棄する
    ものとします。
    上記にかかわらず、 当ファーム は、法域を問わず、差止命令による救済（またはこれ
    に相当する種類の緊急の法的救済）を申請できるものとします。</p>
                    
                    <p class="main-text">2. 衡平法上の救済
    いかなる場合であっても、利用者または利用者企業等は、取消、差止命令その他によ
    る衡平法上の救済を求める、または受けることはできず、当 Webサイト（当 Webサイ
    トに関する技術、当 Webサイトのコンテンツを含みます）またはその一部の運営、利用
    または活用 の禁止または制限を求める、または実行させることはできないものとしま
    す。また、 当ファーム は、当 規定 の違反または違反の継続を防止、軽減または改善する
    ために、差止命令その他による衡平法上の救済を受ける権利を有するものとします。</p>
                    
                    <div class="form-title">E. 非独占的救済</div>
                    <p class="main-text">当規定に定める権利また は救済の行使または実施は、書面により明確に放棄された場合を除
    き、法律上または衡平法上 の その他の権利または救済の実施に代わるものとみなすべきではあ
    りません。</p>
                    
                    <div class="form-title">F. 可 分 性</div>
                    <p class="main-text">当規定の条項がある法域で無効または実施不能とされた場合、 (i)その法域では、 当該条項はそ
    の意図にできる限り沿うように、法律 により認められる 範囲で最大限の効力を有するものとし
    て解釈され、当規定のその他の条項は完全な効力を維持するものとし、 (ii)他のすべての法域で
    は、当規定のすべての条項が完全な効力を維持するものとします。</p>
    
                    <div class="form-title">G. 起草者 に不利な 解釈の禁止</div>
                    <p class="main-text">当規定に関連して意図もしくは解釈について 曖昧な点または 疑問がある場合、当規定は、利用
    者、利用者企業等および 当ファーム が共同で起草したかのように解釈され るものとし 、当規定の
    起草者であることを理由として上記当事者のいずれかに有利または不利になるような推定また
    は立証責任が生じることはないものとします。</p>
    
                    <div class="form-title">H. 譲渡および委任</div>
                    <p class="main-text">利用者および利用者企業等は、当規定に基づく権利または義務を譲渡または委任することは
    できず、そのような譲渡または委任を意図する行為はいずれも無効とします。一方、 当ファーム
    は、利用者または利用者企業等に通知することなく、当規定に基づくすべての権利および義務を
    完全または部分的に、かつ自由に譲渡または委任できるものとします。 また、 当規定は、利用者
    および利用者企業等ならびに 当ファーム の承継人および認められた 譲受人に対する拘束力を有
    するものとします。</p>
    
    <div class="form-title">I. 当事者間の関係；第三者受益者</div>
                    <p class="main-text">当規定により、利用者および利用者企業等と 当ファーム との間に代理、パートナーシップ、フ
    ランチャイズまたはジョイントベンチャーの関係が成立することはありません。
    一部のデロイトファーム、 DTTLおよび DTTLの関 係 法人は、当規定の一部の条項については
    第三者受益者に該当し、それらの条項に基づく特定の権利を 行使 することができます。</p>
                    
    
                    <div class="form-title">J. 言 語</div>
                    <p class="main-text">当ファーム が利用者または利用者企業等に対し、当規定、プライバシー・ステートメントまた
    はその他の書類の英語版をもとにした翻訳版を提供した場合、利用者および利用者企業等は、そ
    の翻訳版は便宜を図る目的でのみ提供されるものであ り、利用者と 当ファーム との関係には英
    語版が適用されることに同意するものとします。</p>
                    
    
                    <div class="form-title">K. 解 釈</div>
                    <p class="main-text">1. 見出しおよび番号
    当規定における条項の番号および見出しは、便宜を図る目的でのみ挿入されているも
    のであり、これらの番号および見出しと当規定の本文との間に齟齬がある場合、本文が
    優先するものとします。
    10
    2. 例例 示示 当規定における「含む」「含み」等の表現は、「含むがこれらに限定されない」とい当規定における「含む」「含み」等の表現は、「含むがこれらに限定されない」という例示の意味とします。う例示の意味とします。</p>
                    
    
            </div>      
    </div>
    <div *ngIf="currentTouLanguage == touLanguages[2]">
            <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Client
    Identity Access Management</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>TERMS OF USE (Belgium only)</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-family:"Arial",sans-serif;color:#100B0C'>Last revised: </span><span
    style='font-family:"Arial",sans-serif;color:#100B0C'>June 12, 2023</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-family:"Arial",sans-serif;
    color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>WHO THESE TERMS OF USE APPLY
    TO; WHAT THEY GOVERN.</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>By using </span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>this Client Identity
    Access Management website</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> (this “Website”), you are agreeing to these Terms of Use</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> (“Terms”)</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> on behalf of yourself and
    the company, organization, or other legal entity that you work for as a
    partner, principal, director, employee, or contractor and, if applicable, its
    affiliates (“your Company”).</span></b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Thereafter,
    these Terms shall be a legally binding agreement between you and Company on the
    one hand, and the Deloitte Firm (as defined below) that invited you to this
    Website on the other (referred to herein as &quot;we&quot;, &quot;us&quot; or
    “our”).   </span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>If
    you do not agree to these Terms, then you are not allowed to use this Website
    and should immediately terminate such usage.</span></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>As used herein,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> “<b>Deloitte </b></span><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Firm</span></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>” means</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    Deloitte Touche Tohmatsu Limited (“<b>DTTL</b>”), </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>(ii) each member firm of DTTL and </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>the </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>affiliates of such </span><span style='font-family:"Arial",sans-serif'>member
    firms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> DTTL and </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>the Deloitte Firms and any of </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>their related entities</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> are referred to herein collectively as
    the “<b>Deloitte Network</b>”. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoBlockText align=left style='text-align:left'><span
    style='font-size:11.0pt'>Where you or Company are our client and we have
    agreed, pursuant to an engagement letter or other contract (each an &quot;<b>Engagement
    Letter</b>&quot;) to the performance of services or delivery of deliverables
    (an “<b>Engagement</b>&quot;), then all</span><span style='font-size:11.0pt'>
    Engagement services and deliverables shall be governed by the Engagement Letter
    and, where applicable, these Terms. As set forth more fully in <b>Section 9A</b>,
    in the event of any conflict between an Engagement Letter and these Terms, the
    terms of the Engagement Letter shall control with respect to the applicable
    Engagement.  Where neither you nor </span><span style='font-size:11.0pt'>Company</span><span
    style='font-size:11.0pt'> are our client, these Terms shall apply except as set
    forth in <b>Section 9A</b>.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Although parts of these Terms may
    reference other entities in the Deloitte Network, these Terms are only with us
    and not with any of those other entities.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>2. Eligibility; License; Other
    Applications</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>; Restrictions; Privacy Statement</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal;page-break-after:
    avoid'><b><i><span style='font-family:"Arial",sans-serif'>A. Eligibility. </span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>To be eligible to use this Website, you
    must be at least 18 years old and have full power and authority to enter into
    these Terms on behalf of yourself and Company. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>You
    must also not be prohibited from entering into these Terms or using any portion
    of this Website by us, Company, any other party, or any contractual, regulatory
    or other legal restrictions. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You hereby represent and warrant to us
    that you meet these eligibility requirements.</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You must notify us if circumstances
    change such that you are no longer in compliance with the eligibility
    requirements set forth in the paragraph above. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>     </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B. License.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>On the condition that you comply with
    all your obligations under these Terms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>,
    we grant you a limited, revocable, nonexclusive, non-assignable,
    non-sublicensable right to access and use the Website only as expressly
    permitted herein.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>C. Other Applications.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>On the condition that you
    comply with all of your obligations under these Terms</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>, you are authorized to
    use this Website solely for the purpose of managing your account and profile
    information in order to obtain access to one or more other applications and
    websites (“<b>Other Applications</b>”).</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> </span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Your use of such Other
    Applications are governed by all license terms, terms of use, legal terms,
    privacy notices, and similar terms on such Other Applications (“<b>Other
    Application Terms</b>”) and you and your Company must comply with all Other
    Application Terms.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>D. Certain restrictions applicable to
    your User Account and general usage of this Website<span style='color:#92D050'>.</span></span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>In order to use this Website, you </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>will receive access through </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>a </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>user
    account (“</span><b><span style='font-family:"Arial",sans-serif'>User Account</span></b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>”).  </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif;color:#100B0C'>You and your Company are responsible for
    anything that happens through your user account until it is deactivated.
    Without limiting the foregoing, you and your Company agree to the following: </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will only create and
    maintain one </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> at
    any given time, which shall be for you alone and not shared or transferred to
    anyone else.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will only access the
    permitted sections of this Website that you have been granted access and will
    not hack into or otherwise attempt to access other areas of this Website.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and your Company will
    keep your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>
    password secure and confidential. </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will not remove any
    copyright, trademark, or other proprietary rights notices found on this Website
    or its content.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and Company will
    comply with all laws applicable in accessing and using this Website, including
    all data privacy, export control, copyright, and securities laws and
    regulations.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You will immediately cease
    all use of your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> and
    this Website upon the termination of your employment or other work relationship
    with your Company. Your Company must notify us in writing of such termination,
    and your Company remains responsible for your actions with respect to your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> and this Website until
    such notification and related deactivation of your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt; font-family:"Arial",sans-serif;color:#100B0C'>You and Company will not
    reverse engineer, decompile or otherwise attempt to derive the source code for
    any underling software related to this Website, or adapt, modify or create
    derivative works based on this Website or its underlying technology. </span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You and your Company will
    not engage in any action that directly or indirectly interferes with the proper
    working of, or places an unreasonable load on, </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif;color:#100B0C'>this</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'> Website or the underlying infrastructure, including spamming
    and the distribution of computer viruses or other malicious code.</span></p>
    
    <p class=MsoNormal style='margin-left:40.5pt;text-indent:-18.0pt;line-height:
    normal;background:white'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>You or your Company will
    notify us immediately upon becoming aware of any unauthorized use of your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'> or any other breach of
    the above obligations.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>E. Privacy.</span></i></b></p>
    
    <p class=MsoNormal style='line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>You
    and Company acknowledge and agree that we may disclose your User Account
    information </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>(including any
    information you provide in connection with multi-factor authentication and/or the implementation of
    Federation services (i.e.,
    first name, last name, corporate email address, corporate cell / telephone
    number)) </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>to others if we
    have a good faith belief that such action is reasonably necessary to comply
    with law, regulatory requirements, professional standards, or prevent harm, or
    as otherwise provided in the applicable Engagement Letter, these Terms or the
    Privacy Statement.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span>
  </p>
    <p class=MsoNormal style='line-height:normal'>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Please review the</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;<span class=MsoHyperlink><a
    href="/dis/public/#/privacynotice" target="_blank" >Privacy
    Statement</a></span><b>&nbsp;</b></span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>for
    more information regarding the ways in which your personal information is
    collected in connection with your use of this Website, the purposes for which
    your personal information is used, and how it is shared.</span>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif'>   </span></p>
    <p class=MsoNormal style='line-height:normal'>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif'>If Company has agreed to </span>
    <span style='font-size:11.0pt;font-family:"Arial",sans-serif'>federate to
    Deloitte systems (as described below), please review the respective Privacy Statement
    for more information on how your personal information is collected in connection
    with the implementation of Federation services.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>   </span></p>
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>User Account Deactivation</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Deactivating Your User Account.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You or Company may deactivate your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>, for any or no reason, at any time,
    with written notice to us</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    as per <b>Section 7 (Notices)</b>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> This
    notice will be effective upon our processing such notice. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    may </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>deactivate your User
    Account</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> for any reason or
    no reason, at any time, with or without notice, effective immediately or as may
    be specified in any given notice. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Without limiting the foregoing, your </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> may be deactivated by Website
    administration if inactive for extended time periods.</span></p>
    
    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:40.5pt;text-indent:-40.5pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Effect of Deactivation.</span></i></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-bottom:6.0pt;text-indent:-18.0pt;
    line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>1.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Usage</span></u><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Deactivation
    of your </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> results in the immediate termination of
    your ability to </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>technologically
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>access and use this
    Website.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> In addition, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>we may bar </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>you from any future use of this Website
    and also </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>block access to</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> this Website from any IP address or
    range of IP addresses associated with you.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;text-indent:
    -18.0pt;line-height:normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>2.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Surviving Terms</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Section 2B</span></b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> of these Terms shall not survive
    deactivation of your User Account and termination of your license to access and
    use this Website. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>The remainder of these Terms shall
    survive.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:6.0pt;line-height:
    normal'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Federated Access Terms</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>If your Company has agreed
    to federate to Deloitte systems by accepting federated access terms, your
    authentication to this Website will be governed by those Federation Terms. </span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:#100B0C'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Intellectual Property
    Rights; No use of Deloitte names or logos</span></b></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>This Website and its
    contents are protected by copyright, trademark, and other laws of the United
    States and/or foreign countries. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>We
    and our licensors reserve all</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    rights not expressly granted in these Terms</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>“Deloitte”, “Touche”,
    “Tohmatsu”, “Deloitte Touche Tohmatsu”, “Deloitte &amp; Touche”, the Deloitte
    logo, and local language variants of the foregoing trademarks, and certain
    product names that appear on this Website (collectively, the “<b>Deloitte Marks</b>”),
    are trademarks or registered trademarks of entities within the Deloitte
    Network. Except as expressly provided in these Terms or as expressly authorized
    in writing by the relevant trademark owner, neither you nor Company shall
    use any Deloitte Marks either alone or in combination with other words or design
    elements, including in any press release, advertisement, or other promotional
    or marketing material or media, whether in written, oral, electronic, visual or
    any other form.</span></p>
    
    <p class=MsoNormal style='line-height:normal;background:white'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>References to other
    parties’ trademarks on this Website are for identification purposes only and do
    not indicate that such parties have approved this Website or any of its
    contents. These Terms do not grant you any right to use the trademarks of other
    parties.</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>Disclaimers and Limitations
    of Liability</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>neither we nor
    any other ENTITY WITHIN the Deloitte Network<b> </b>is, by means of PERMITTING
    YOU ACCESS</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'> TO </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'>this Website </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>(INCLUDING
    ALL</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>TECHNOLOGY, WEBSITE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> CONTENT</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>, OUTPUT</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>,
    AND </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>OTHER CONTENT),</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> RENDERING PROFESSIONAL ADVICE OR
    SERVICES</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> TO YOU OR COMPANY.
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> BEFORE MAKING ANY DECISION
    OR TAKING ANY ACTION THAT MIGHT AFFECT YOUR OR </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>COMPANY'S</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  FOR CLARITY, ADVICE AND DELIVERABLES
    THAT WE PROVIDE PURSUANT TO AN ENGAGEMENT LETTER SHALL BE GOVERNED BY THE TERMS
    OF THAT ENGAGEME<span style='text-transform:uppercase'>NT LETTER, EVEN IF
    ACCESSIBLE BY OR DELIVERED TO YOU VIA THIS WEBSITE.  </span></span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>ALTHOUGH WE TAKE COMMERCIALLY REASONABLE
    STEPS TO MAKE </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>THIS WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>USEFUL AND SECURE, THIS WEBSITE
    (INCLUDING ALL WEBSITE TECHNOLOGY, WEBSITE CONTENT, OUTPUT, AND OTHER CONTENT) </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>IS PROVIDED AS IS, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>WITHOUT WARRANTY OF ANY KIND.  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>NO ENTITY WITHIN THE DELOITTE NETWORK </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>HAS ANY OBLIGATION TO PROVIDE
    MAINTENANCE AND SUPPORT SERVICES</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    OR </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>ANY UPDATE, UPGRADE,
    ENHANCEMENT, NEW FUNCTIONALITY, MODIFICATION, PATCH, BUG FIXES,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> OR </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>SIMILAR DELIVERABLE WITH RESPECT TO THIS WEBSITE. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> WITHOUT LIMITING THE FOREGOING, </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>WE DO NOT WARRANT</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> THAT THIS WEBSITE WILL BE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> AVAILABLE,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> SECURE, ERROR-FREE, FREE FROM VIRUSES
    OR MALICIOUS CODE, OR WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR
    QUALITY</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>, AND WE EXPRESSLY
    DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
    NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>YOUR USE OF THIS WEBSITE </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>(INCLUDING ALL WEBSITE TECHNOLOGY,
    WEBSITE CONTENT, OUTPUT, AND OTHER CONTENT) </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>IS AT YOUR OWN RISK AND </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>YOU</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE,
    INCLUDING WITH RESPECT TO LOSS OF SERVICE OR DATA. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> WE</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    WILL</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> NOT</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> BE LIABLE FOR ANY DIRECT, INDIRECT,
    SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES
    WHATSOEVER, WHETHER IN AN ACTION OF CONTRACT, STATUTE, TORT (INCLUDING
    NEGLIGENCE), OR OTHERWISE, RELATING TO THE USE OF THIS WEBSITE</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> (INCLUDING ALL WEBSITE TECHNOLOGY, WEBSITE
    CONTENT, OUTPUT, AND OTHER CONTENT).</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>Certain links
    on this Website may lead to resources, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>WEBSITES, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>AND TOOLS maintained by third parties over which we
    have no control, including THOSE maintained OR PROVIDED by other </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>ENTITIES WITHIN
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>the
    Deloitte Network. We make no representations or warranties as to the accuracy
    of, or any other aspect relating to, those resources,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'> WEBSITES, AND
    TOOLS</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>,</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'> AND LINKS TO
    ANY SUCH </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:
    uppercase'>RESOURCES, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>WEBSITES, AND TOOLS SHOULD NOT BE CONSTRUED AS AN
    ENDORSEMENT OF THEM OR THEIR CONTENT</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'> BY US OR AN ENDORSEMENT BY THEM OF US OR THIS
    WEBSITE. </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>The above
    limitations of liability shall be applicable not only to us but also to each
    other entity within the Deloitte Network</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'> AND TO </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>OUR AND </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    text-transform:uppercase'>THEIR RESPECTIVE PERSONNEL.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;text-transform:uppercase'>  </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>IF ANY OF <span style='text-transform:
    uppercase'>The above limitations of liability </span>IS INVALID OR
    UNENFORCEABLE IN ANY JURISDICTION, THEN (I) IN THAT JURISDICTION IT SHALL BE
    RE-CONSTRUED TO THE MAXIMUM EFFECT PERMITTED BY LAW TO EFFECT ITS INTENT AS
    NEARLY AS POSSIBLE AND THE REMAINING TERMS SHALL REMAIN IN FULL FORCE AND
    EFFECT, AND (II) IN EVERY OTHER JURISDICTION ALL OF THESE TERMS SHALL REMAIN IN
    FULL FORCE AND EFFECT.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>COMPANY AGREES TO INDEMNIFY US AND EACH
    OTHER ENTITY WITHIN THE DELOITTE NETWORK, AND OUR AND THEIR PERSONNEL, AGAINST
    ALL COSTS, CLAIMS, LOSSES OR EXPENSES INCURRED BY OR MADE AGAINST ANY OF THE
    FOREGOING AS A RESULT OF ANY BREACH BY YOU OR COMPANY OF THESE TERMS, INCLUDING
    ANY THIRD-PARTY CLAIM THAT ANY COMPANY CONTENT INFRINGES ANY INTELLECTUAL
    PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY.</span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>NOTICES.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.     </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notices from us to you.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></i></b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> Any notices from us to you regarding this Website may be
    by any of the following methods:</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> (i) general postings to
    users on this Website, (ii) any communicative function available through your
    User Account, </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>or</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> (iii) the email address associated with
    your User Account. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>It is your responsibility to keep the
    contact information in your User Account current and accurate.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.     </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notices from you to us<span
    style='color:#92D050'>.</span></span></i></b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> 
    Any notices from you to us regarding this Website must be in writing and sent
    to the contact provided for us in the Engagement Letter or License Agreement,
    if any, or to such other contact as we may provide.  Such notices should be
    provided using the same mechanism provided for notices in the Engagement Letter</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>. </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> If
    there is no Engagement Letter </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>or
    they do</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> not provide for
    notices, then notices from you to us must be by any of the following methods:
    (i) electronic mail; (ii) personal delivery; or (iii) a globally or nationally
    (as the case may be) recognized express mail, courier, or delivery service (“<b>Express
    Courier</b>”).</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> A notice sent by electronic mail shall
    be deemed given on the date of electronic confirmation of receipt.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> A
    notice sent by personal delivery or Express Courier shall be deemed given on
    the date of receipt or refusal of receipt.  </span></p>
    
    <p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
    line-height:normal;background:white'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
    color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
    style='font-size:11.0pt;ont-family:"Arial",sans-serif;color:black'>NO PROFESSIONAL
    RELATIONSHIP.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You and Company acknowledge and agree
    that no professional relationship of any nature is created solely by your use
    of this Website or by any correspondence or communication with us or any other
    person or entity relating to this Website.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> For clarification, if a
    professional relationship already exists with us (e.g., pursuant to an
    Engagement Letter), it will not be terminated by your use of this Website.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>The infrastructure of, and certain
    Website technology and content found on, this Website are provided to us by
    DTTL or one or more of its related entities.</span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> However,
    your use of this Website is solely at our invitation, and neither DTTL nor any
    of those related entities is providing a service to you or Company or entering
    into a professional relationship with you or Company by way of this Website.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> Unless
    otherwise set forth specifically in a separate written agreement, any service
    that you receive and relationship that you have with respect to this Website is
    with us alone and is governed by these Terms and any related Engagement Letter.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#0070C0'>&nbsp;</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal;page-break-after:
    avoid'><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:black'>9.   
    GENERAL TERMS.</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>A.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Entire Agreement; Engagement Letter</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Subject to the following two sentences,
    these Terms constitute the full and complete agreement between you and Company
    on the one hand, and us on the other, with respect to the subject matter
    hereof, and supersede any oral and prior written agreements with respect to
    such subject matter.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> In the event of any conflict between
    these Terms and an Engagement Letter, the terms of the Engagement Letter shall
    control with respect to the applicable Engagement. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Where
    (i) neither you nor Company are our client and your activity on this Website is
    not covered by an Engagement Letter and (ii) we have entered into a separate
    written agreement with you or Company with respect to this Website, then, in
    the event of a conflict between such agreement and these Terms, such agreement
    shall control.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>B.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Amendments to these Terms.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We may revise these Terms at any time in
    our sole discretion by posting such revised terms at this <span
    style='color:#0070C0'>Terms of Use </span>link in this Website’s footer (i.e.,
    this webpage that you are currently viewing) or elsewhere on this Website, or
    otherwise notifying you in accordance with Section 7 (Notices).</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> Such revised terms shall be effective to you upon posting
    or other notice, unless otherwise explicitly stated by us.</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>It is your and Company’s responsibility
    to be aware of any such revised terms by checking here and reading your
    notices.</span></b><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If you or Company does not agree with
    any of these Terms as they may be amended from time to time, you should
    deactivate your User Account as per Section 3 (</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>User Account Deactivation</span></b><b><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>). </span></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>C.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> No informal waivers, agreements or
    representations.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>No waiver of any breach by you or
    Company, or of any objection to any act or omission connected therewith, shall
    be implied or claimed by you or Company or be deemed to constitute a consent to
    any continuation of such breach, act or omission, unless contained in a writing
    signed by us. </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>D.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Dispute resolution; equitable relief<span
    style='color:#92D050'>. </span></span></i></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt;text-indent:-18.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Choice of Law;
    etc</span></u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> Any
    choice of law, venue, and dispute resolution terms and procedures applicable to
    the Engagement Letter shall govern these Terms, and all rights and obligations
    arising from or otherwise relating to them. </span><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'> </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>In the
    event no Engagement Letter</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>exists, then (i) these Terms shall be
    governed by, construed in accordance with, and enforced under the laws (both
    substantive and procedural) of Belgium (but specifically
    excluding the United Nations Convention on Contracts for the International Sale
    of Goods), without reference to principles of conflict of laws; (ii) you,
    Company and we agree to submit to the exclusive jurisdiction and venue of the
    Dutch speaking federal and state courts situated in Brussels with respect to any dispute
    arising hereunder or in connection with such rights and obligations and (iii)
    you, Company and we also agree to irrevocably waive any right to a jury trial
    in any action, proceeding or counterclaim relating to these Terms.  </span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Notwithstanding
    the above, we shall still be allowed to apply for injunctive remedies (or an
    equivalent type of urgent legal relief) in any jurisdiction.</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></p>
    
    <p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
    margin-bottom:6.0pt;margin-left:45.0pt;text-indent:-18.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Equitable Relief</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'></span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> In no event shall you or
    Company seek or be entitled to rescission, injunctive or other equitable
    relief, or to enjoin or restrain the operation, use or exploitation of this
    Website or any part of it, including any Website technology or Website content.
    </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>We shall be entitled to injunctive or
    other equitable relief in order to prevent, mitigate or remedy the breach or
    continuing breach of these Terms.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>E.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Remedies not exclusive.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Exercise or enforcement of a right or
    remedy given in these Terms shall not be considered to be in lieu of
    enforcement of other rights or remedies otherwise existing at law or equity,
    unless specifically waived in writing.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>F.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Severability.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If any term in these Terms</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> is invalid or unenforceable in any
    jurisdiction, then (i) in that jurisdiction it shall be re-construed to the
    maximum </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>extent</span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> permitted by law to effect its intent
    as nearly as possible and the </span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>remaining
    terms</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'> shall remain in full
    force and effect, and (ii) in every other jurisdiction all of these Terms shall
    remain in full force and effect.</span><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>            </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>G.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> No construction against the drafter.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>If an ambiguity or question of intent or
    interpretation arises with respect to these Terms, these Terms will be
    construed as if drafted jointly by you, Company and us, and no presumption or
    burden of proof will arise favoring or disfavoring any of those parties by
    virtue of authorship of these Terms.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>H.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Assignment and delegation.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>You and Company may not assign or
    delegate any rights or obligations under these Terms, and any such purported assignment
    or delegation shall be ineffective. We may freely assign or delegate all rights
    and obligations under these Terms, fully or partially, without notice to you or
    Company. These Terms shall be binding upon your, Company’s and our successors
    and permitted assigns.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>I.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Relationship of the parties; third
    party beneficiaries.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>No agency, partnership, franchise, or
    joint venture is created by these Terms between you and Company on the one
    hand, and us on the other.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>Certain Deloitte Firms and DTTL and its
    related entities are third party beneficiaries to certain sections of these
    Terms, and may enforce specific rights hereunder.</span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>J.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Language.</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>In the event that we have provided you
    or Company with a translation of the English language version of these Terms,
    the Privacy Statement, and/or any other documentation, you and Company agree
    that the translation is provided for convenience only and that the English
    language version governs your relationship with us. </span><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> </span></p>
    
    <p class=MsoNormal style='margin-bottom:6.0pt;line-height:normal'><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>K.</span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  </span></i></b><b><i><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'> Construction.</span></i></b></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:36.0pt;text-indent:-18.0pt'><span style='font-size:11.0pt;
    font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Agreement
    Headings and Numbering.</span></u><span style='font-size:11.0pt;font-family:
    "Arial",sans-serif'>  Paragraph or Section numbers and headings that are used
    in these Terms are included for convenience only and, if there is any conflict
    between any such numbers and headings and the text of these Terms, the text
    shall control.</span></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;
    margin-left:36.0pt;text-indent:-18.0pt'><span style='font-size:11.0pt;
    font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
    </span></span><u><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Including.</span></u><span
    style='font-size:11.0pt;font-family:"Arial",sans-serif'>  As used in these
    Terms, the word “including” means “including, without limitation,” and the word
    “include” means “include, without limitation.”</span></p>      
    </div>
    
    <p class=MsoNormal><span style='font-size:11.0pt;line-height:107%'>&nbsp;</span></p>
    <app-terms-conditions></app-terms-conditions>
</div>
<div class="img-container self-service-img-container">
</div>
</div>
  
