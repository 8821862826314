<!-- Code Generated by Sidekick is for learning and experimentation purposes only. -->
<div id="userRequestNew" [hidden]="!(authorized())">
    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a [routerLink]="['']">{{ 'views.userrequest.Home' | translate }}</a></li>
        <li
        class="breadcrumb-item"><a (click)="navigateToList()">{{ 'views.userrequest.User request list' | translate }}</a></li>
        <li class="breadcrumb-item active">{{ 'views.userrequest.New user' | translate }}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{ 'views.userrequest.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon">
        <img style="margin : -4px 15px" src="assets/images/homepage/users.png" alt="{{ 'views.userrequest.New user' | translate }}" />
    </div>

    <h1 style="font-size: 28px !important;" *ngIf="titlesub === 'an existing'">
        {{ 'views.userrequest.Register' | translate }} {{ 'views.js.alerts.userrequest.an existing' | translate }} {{ 'views.userrequest.user for' | translate }} {{ currentorganization.name }}
    </h1>
    <h1 style="font-size: 28px !important;" *ngIf="titlesub === 'a new'">
        {{ 'views.userrequest.Register' | translate }} {{ 'views.js.alerts.userrequest.a new' | translate }} {{ 'views.userrequest.user for' | translate }} {{ currentorganization.name }}
    </h1>

    <div class="card" *ngIf="!hiddenform">
        <div class="card-header"></div>
        <div class="card-body">
            <div *ngFor="let alert of alerts">
                <p class="alert" [ngClass]="{'alert-danger': alert.type == 'danger', 'alert-info': alert.type == 'info'}">
                    {{ alert.msg }}
                    <i (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="float:right; font-size: 22px;"></i>
                </p>
            </div>

            <form [formGroup]="userrequestForm" (ngSubmit)="onSubmit(userrequestForm)" class="form-horizontal" role="form">
                <div class="form-group" *ngIf="!addtoaditionalorg">
                    <div class="offset-sm-2 col-sm-10">
                        <div class="checkbox" style="margin:7px">
                            <label>
                                <input type="checkbox" id="userType" name="userType" trueFalseValue trueValue="azureb2b" falseValue="external" formControlName="userType" (change)="onAzureCheckBoxChange($event)" />
                                <span style="position: absolute;
                                margin-top: -1px;
                                margin-left: -12px;
                                min-height: 20px;
                                padding-left: 20px;
                                margin-bottom: 0;
                                font-weight: 400 !important;
                                cursor: pointer;">Azure B2B</span>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- First Name -->
                <div class="mb-3 row" show-errors>
                    <label for="firstname" class="col-sm-3 col-form-label">{{ 'views.userrequest.First name' | translate }}</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <span class="input-group-text">
                            <span class="bi bi-person"></span></span>
                        <input [ngClass]="{'input-error': userrequestForm.controls.firstname.status == 'INVALID' && userrequestForm.controls.firstname.touched}" type="text" class="form-control" id="firstname" name="firstname" formControlName="firstname" [attr.disabled]="addtoaditionalorg ? '' : null" />
                      </div>
                    </div>
                    <div class="col-sm-3" *ngIf="userrequestForm.controls.firstname.errors != null">
                      <p class="error-block" *ngIf="userrequestForm.controls.firstname.errors.required == true && userrequestForm.controls.firstname.touched">
                        {{ 'views.userrequest.Required' | translate }}
                      </p>
                      <p class="error-block" *ngIf="!(userrequestForm.controls.firstname.errors.required) && userrequestForm.controls.firstname.status == 'INVALID' && userrequestForm.controls.firstname.touched">
                        {{ 'views.service.Invalid Input' | translate }}
                      </p>
                    </div>
                  </div>
                  

                <!-- Last Name -->
                <div class="mb-3 row" show-errors>
                    <label for="lastname" class="col-sm-3 col-form-label">{{ 'views.userrequest.Last name' | translate }}</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <span class="input-group-text"><span class="bi bi-person"></span></span>
                        <input type="text" class="form-control" id="lastname" name="lastname" [attr.disabled]="addtoaditionalorg ? '' : null" [ngClass]="{'input-error': userrequestForm.controls.lastname.status == 'INVALID' && userrequestForm.controls.lastname.touched}" formControlName="lastname" />
                      </div>
                    </div>
                    <div class="col-sm-3" *ngIf="userrequestForm.controls.lastname.errors != null">
                      <p class="error-block" *ngIf="userrequestForm.controls.lastname.errors.required == true && userrequestForm.controls.lastname.touched">
                        {{ 'views.userrequest.Required' | translate }}
                      </p>
                      <p class="error-block" *ngIf="!(userrequestForm.controls.lastname.errors.required) && userrequestForm.controls.lastname.status == 'INVALID' && userrequestForm.controls.lastname.touched">
                        {{ 'views.service.Invalid Input' | translate }}
                      </p>
                    </div>
                  </div>
                  
                <!-- Main Language -->
                <div class="mb-3 row" *ngIf="!addtoaditionalorg">
                    <label for="mainLanguage" class="col-sm-3 control-label">{{ 'views.userrequest.Main language' | translate }}</label>
                    <div class="col-sm-6">
                        <span class="input-group">
                            <span class="input-group-text"><span class="bi bi-globe"></span></span>
                            <select formControlName="mainLanguage" placeholder="Set main language" (change)="onLangChange($event)" class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input form-control">
                                <option value="" disabled hidden></option>
                                <option label="En - English (Global)" value="EN" selected>En - English (Global)</option>
                                <option label="Nl - Dutch" value="NL">Nl - Dutch</option>
                                <option label="Fr - French (France)" value="FR">Fr - French (France)</option>
                                <option label="Es - Spanish (Spain)" value="ES">Es - Spanish (Spain)</option>
                                <option label="Es-419 - Spanish (Latin-America)" value="ES-419">Es - Spanish (Latin-America)</option>
                                <option label="Pt - Portuguese" value="PT">Pt - Portuguese</option>
                                <option label="Zh - Chinese (Simplified)" value="ZH">Zh - Chinese (Simplified)</option>
                                <option label="De - German" value="DE">De - German</option>
                                <option label="Da - Danish" value="DA">Da - Danish</option>
                                <option label="Sv - Swedish" value="SV">Sv - Swedish</option>
                                <option label="Fi - Finnish" value="FI">Fi - Finnish</option>
                                <option label="No - Norwegian" value="NO">No - Norwegian</option>
                                <option label="Pl - Polish" value="PL">Pl - Polish</option>
                                <option label="Ja - Japanese" value="JA">Ja - Japanese</option>
                                <option label="Cs - Czech" value="CS">Cs - Czech</option>
                                <option label="Ru - Russian" value="RU">Ru - Russian</option>
                                <option label="It - Italian" value="IT">It - Italian</option>
                                <option label="Fr-Ca - French (Canada)" value="FR-CA">Fr-Ca - French (Canada)</option>
                                <option label="Hu - Hungarian" value="HU">Hu - Hungarian</option>
                            </select>
                        </span>
                    </div>
                </div>

                <!-- Email -->
                <div class="mb-3 row" *ngIf="!addtoaditionalorg">
                    <label for="email" class="col-sm-3 control-label">{{ 'views.userrequest.Email' | translate }}</label>
                    <div class="col-sm-6">
                        <span class="input-group">
                            <span class="input-group-text">@</span>
                            <input type="text" class="form-control" name="email" id="userrequest.email" [ngClass]="{'input-error': userrequestForm.controls.email.status == 'INVALID' && userrequestForm.controls.email.touched || uniqueValue}" (change)="checkUniqueValue(email)" formControlName="email" />
                        </span>
                    </div>
                    <div class="col-sm-1" *ngIf="userrequestForm.controls.email.errors != null">
                        <p class="error-block" *ngIf="userrequestForm.controls.email.errors.required == true && userrequestForm.controls.email.touched">
                            {{ 'views.userrequest.Required' | translate }}
                        </p>
                        <p class="error-block" *ngIf="userrequestForm.controls.email.errors.required == null && userrequestForm.controls.email.status == 'INVALID' && userrequestForm.controls.email.touched">
                            {{ 'views.userrequest.The email address is invalid' | translate }}
                        </p>
                    </div>
                    <div *ngIf="isLoading" class="col-sm-1">
                        <div class="dot-spinner">
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block" *ngIf="uniqueValue && !otherUserType">
                            {{ 'views.userrequest.This email address already exists.' | translate }}
                            {{ 'views.userrequest.Click' | translate }}
                            <button class="linkbutton" (click)="addUserToCurrentOrg(email)">{{ 'views.userrequest.here' | translate }}</button>
                            {{ 'views.userrequest.to add the user to this organization.' | translate }}
                        </p>
                    </div>
                </div>
                <div class="mb-3 row" *ngIf="addtoaditionalorg">
                    <label for="email2" class="col-sm-3 control-label">{{ 'views.userrequest.Email' | translate }}</label>
                    <div class="col-sm-6">
                        <span class="input-group">
                            <span class="input-group-text">@</span>
                            <input type="text" class="form-control" name="email2" id="email2" formControlName="email" disabled="addtoaditionalorg" />
                        </span>
                    </div>
                </div>

                <!-- Mobile Phone -->
                <div class="mb-3 row" *ngIf="!(addtoaditionalorg || userrequestForm.controls.userType.value == 'azureb2b')">
                    <label for="mobile" class="col-sm-3 control-label">{{ 'views.userrequest.Mobile phone' | translate }}</label>
                    <div class="col-sm-6">
                        <span class="input-group form-control">
                            <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{'input-error': userrequestForm.controls.lastname.status == 'INVALID' && userrequestForm.controls.lastname.touched}" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15" [separateDialCode]="true" name="mobile" formControlName="mobile" (ngModelChange)="checkNumber()">
                            </ngx-intl-tel-input>
                        </span>
                    </div>
                    <div class="col-sm-3" *ngIf="userrequestForm.controls.mobile.errors != null">
                        <p class="error-block" *ngIf="!(isValid) && userrequestForm.controls.mobile.touched">{{ 'views.userrequest.Format' | translate }}</p>
                    </div>
                </div>

                <!-- Azure Target Service -->
                <div class="mb-3 row">
                    <label for="azureServiceId" class="col-sm-3 control-label">{{ 'views.roleassignment-detail.Target Service' | translate }}</label>
                    <div class="col-sm-8">
                        <mat-form-field style="width: 350px; height: 45px; margin: -23px 0;">
                            <input style="height: 38px;"id="azureServiceId" name="azureServiceId" class="form-control" [formControl]="azureServiceId" placeholder="Search Service" type="text" matInput [matAutocomplete]="auto1">
                            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="updatePortCode($event)" [displayWith]="displayFn">
                                <mat-option *ngFor="let item of services" [value]="item">
                                    {{ item.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Initial Roles -->
                <div style="overflow-x: auto;">
                    <accordion>
                        <div id="inititialroles">
                            <accordion-group [isOpen]="true" is-disabled="false">
                                <div accordion-heading style="width:100%;">
                                    <div style="width: 50px; float: left">
                                        <img src="assets/images/homepage/users.png" alt="{{ 'views.home.User management' | translate }}" width="50%" />
                                    </div>
                                    {{ 'views.userrequest.Add initial roles' | translate }}
                                </div>
                                <table>
                                    <tr>
                                        <td><label for="aroles">{{ 'views.userrequest.Available roles' | translate }}</label></td>
                                        <td></td>
                                        <td></td>
                                        <td><label for="sroles">{{ 'views.userrequest.Selected roles' | translate }}</label></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select (change)="domains($event, available)" class="" size="10" style="width: 250px; font-size: smaller; overflow-x: auto; overflow-y: auto;" multiple formControlName="available">
                                                <option *ngFor="let role of availableroles" [value]="role">
                                                    {{ role.serviceName }}-{{ role.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm" (click)="moveItem1(this.userrequestForm.controls.selected.value[0], selectedroles, availableroles)">
                                                <span class="bi bi-chevron-left" style="margin-right: 10px"></span>
                                            </button>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm" (click)="moveItem2(this.userrequestForm.controls.available.value[0], availableroles, selectedroles)">
                                                <span class="bi bi-chevron-right" style="margin-right: 10px"></span>
                                            </button>
                                        </td>
                                        <td>
                                            <select class="" size="10" style="width: 228px; font-size: smaller; overflow-x: auto; overflow-y: auto;" multiple formControlName="selected">
                                                <option *ngFor="let role of selectedroles" [value]="role">
                                                    {{ role.serviceName }}-{{ role.name }}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </accordion-group>
                        </div>
                    </accordion>
                </div>

                <!-- Save Button -->
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <div class="btn-group savebutton" style="margin:8px -47px">
                        <button type="submit" class="btn btn-light custom-btn" [attr.disabled]="isDisabled() ? '' : null">
                            <span class="bi bi-send" style="margin-right: 10px" *ngIf="titlesub === 'an existing'">
                                <span style="font-size: 14px; font-family: 'Open Sans'; margin: 6px;">{{ 'views.js.alerts.userrequest.Add existing user to organization' | translate }} {{ currentorganization.name }}</span>
                            </span>
                            <span class="bi bi-send" style="margin-right: 10px" *ngIf="titlesub === 'a new'">
                                <span style="font-size: 14px; font-family: 'Open Sans'; margin: 6px;">{{ 'views.userrequest.Register' | translate }}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>
</div>
