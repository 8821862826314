import { Component, OnInit, SecurityContext } from '@angular/core';
import { CONFIG } from '../config';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }
  safeNavigate(url: string) {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (sanitizedUrl) {
      window.location.href = sanitizedUrl;
    }
  }
  ngOnInit(): void {
    if (localStorage.getItem('currentUser')) {
      const ssoConfig = CONFIG.getSSOConfig();
      // logged in so end FR session first
      const user = JSON.parse(localStorage.getItem('currentUser'));
      const endSessionUrl = `${ssoConfig.end_session_endpoint}?id_token_hint=${user.idToken}&post_logout_redirect_uri=${ssoConfig.post_logout_redirect_uri}`;
      sessionStorage.clear();
      localStorage.clear();
      this.safeNavigate(endSessionUrl)

      // window.location.href = endSessionUrl;
    }
  }
}
