<div id="viewservices" *ngIf="authorized()">
    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="breadcrumb-item active">{{'views.service.Service list' | translate }}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{"views.home.Log out"| translate}}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/services.png" alt="service management"/></div>
    <h1>{{'views.service.Service list' | translate }}</h1>
  <div class="card">    
    <div class="card-header" style = "height: 25px">
            <div class="loader"   *ngIf="isLoadingResults || isRateLimitReached">
              <div class="dot" style="--dot-index:0;"></div>
              <div class="dot" style="--dot-index:1;"></div>
              <div class="dot" style="--dot-index:2;"></div>
            </div>
          </div>
        <div class="card-body">
        <div class="example-table-container">
            
            <table mat-table [dataSource]="filteredAndPagedServices" class="example-table" matSort matSortActive="name" matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                </th>
                <td mat-cell *matCellDef="let row">
                    <img style="width: 25px" *ngIf="row.status === 'suspended'"
                        src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                        title="{{'views.user.suspended' | translate}}" />
                    <img style="width: 25px" *ngIf="row.status === 'active'"
                        src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                        title="{{'views.user.active' | translate}}" />
                </td>
            </ng-container>

            <ng-container matColumnDef="servicename" style="width: fit-content;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: flex; padding: 3%; font-weight: bold; color: black;">Name
                </th>
                <td mat-cell (click)="navigateServiceDetail(row)" *matCellDef="let row">{{row.name}}</td>
            </ng-container>
            <ng-container matColumnDef="technicalid" style="width: fit-content;">
                <th mat-header-cell   *matHeaderCellDef mat-sort-header disableClear style="width: flex; font-weight: bold; color: black;">Technical ID
                </th>
                <td mat-cell *matCellDef="let row" (click)="navigateServiceDetail(row)">{{row.technicalId}}</td>
            </ng-container>
            <ng-container matColumnDef="appid" style="width: fit-content;">
                <th mat-header-cell   *matHeaderCellDef mat-sort-header disableClear style="width: flex; font-weight: bold; color: black;">App ID
                </th>
                <td mat-cell *matCellDef="let row" (click)="navigateServiceDetail(row)">{{row.appid}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef >
                 
                <!-- <div *ngIf="row.userId.status  === 'suspended'">
                    <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                    <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                </div> -->
                </th>
                <td mat-cell *matCellDef="let row">
           
                    <app-confirm-delete (onconfirm)="deleteServices(row)"></app-confirm-delete>

                </td>
              </ng-container>
            <ng-container matColumnDef="filter-status">
                <th mat-header-cell *matHeaderCellDef>

                </th>
            </ng-container>
            <ng-container matColumnDef="filter-name">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field appearance="outline">
                        <input matInput (keyup)="applyFilter('name',$event.target.value)" />
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="filter-technicalid">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field appearance="outline">
                        <input matInput (keyup)="applyFilter('technicalId',$event.target.value)" />
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="filter-appid">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field class="mat-form-field-appid" appearance="outline">
                        <input matInput (keyup)="applyFilter('appId',$event.target.value)" />
                    </mat-form-field>
                </th>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-header-row
                *matHeaderRowDef="['filter-status', 'filter-name', 'filter-technicalid','filter-appid']"
                class="example-second-header-row">
            </tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
   
   
        </div>

    <mat-paginator #pagingservice [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"
        (page)="getPageDetails($event)"></mat-paginator>
        </div>
            </div>
</div>
<div class="btn-group btn-group-justified">
    <div class="btn-group">
        <button style="width:315px" type="button" class="btn btn-light custom-btn" routerLink="/home">
            <span class="bi bi-house-up-fill" style="margin-right: 10px"></span>{{'views.role.Home' | translate}}</button>
            </div>
                <div class="btn-group">
                    <button style="width:376px" type="button" class="btn btn-light custom-btn" (click)="gotoServiceNewPage()">
                        <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{'views.service.Add new service' | translate }}</button>
                </div>
        </div>    
<div style="display: flex;margin-left: 7%;
margin-top: 19%;">
  <app-terms-conditions></app-terms-conditions>
</div>