import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import zxcvbn from 'zxcvbn';
import { PasswordService } from '../password.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';
import { CommonService } from '../common.service';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Callbacks } from 'jquery';
import dictionaryWords from '../pwdset/dictionary-words';
import { parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';

@Component({
  selector: 'app-pwd-reset',
  templateUrl: './pwd-reset.component.html',
  styleUrls: ['./pwd-reset.component.css']
})
export class PwdResetComponent implements OnInit {

  resetFlag = true;
  error: any;
  matching: boolean;
  pwddoesnotcontainuserattributes = true;
  itype = 'password';
  otpErrorMsg ="";
  passwordContainsTen: boolean = false;
  passwordContainsUppercase: boolean = false;
  passwordContainsLowercase: boolean = false;
  passwordContainsNumeral: boolean = false;
  passwordNonAlphaNumeric: boolean = false;
  passwordHasIdenticalPattern: boolean = false;
  passwordHasConsecutivePattern: boolean = false;
  passwordHasDictionaryWord: boolean = false;
  password2type = 'password';
  pwdAlreadyUsed: boolean;
  email: any;
  path: any;
  firstname: any;
  title: any;
  text: any;
  lastname: any;
  user: any;
  successType: any;
  successtitle: any;
  successtext: any;
  mobileNumber: any;
  strongpassword: boolean;
  mobileerrorFlag = false;
  userrequest: any;
  codes: any;
  tmpkeyFlag = false;
  code: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  lang: any;
  hideMobile: string;
  mobile_input;
  otpappLastchanged: any;
  preferredMFA: string;
  codevalidation;
  loaderFlag =false;
  isLoadingResults =false;
  private timeout: any;

  verifyauthcodeFlag = false;
  errormessage;
  successmessage;
  alerts: { type: string; msg: any; }[];
  validateOTP = false;
  mobileFlag = false;
  sendSMSClicked = false;
  azureTenant : any;
intialValue =null;
  passWordCallBackResponse: any;
  otpSetCallBackResponse: any;
  passWordConfirmationCallBack: any;
  finalCallBackResponse: any;
  mobile: any;

  identicalPattern = /(.)\1\1\1/;
  consecutivePattern = /(0123|1234|2345|3456|4567|5678|6789|7890|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/;
  

  phoneForm = new FormGroup({
		mobile_new: new FormControl(undefined, [Validators.required]),
    otpvalidation: new FormControl()
	});
  twiliootpalert: any;
  messageMedium: any;
  enteredMobile: string;
  isValid: boolean;
  userRelationship: any;
  constructor(private commonService: CommonService,private passwordService: PasswordService, private route: ActivatedRoute, public translateService: TranslateService, private router: Router) {
    this.phoneForm.valueChanges
    .pipe(
      debounceTime(100), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    ).subscribe(newValue =>{
    });
    
   }
  passwordForm: FormGroup = new FormGroup({
    tmpkey: new FormControl('', [Validators.pattern(/^(?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z ])|(?=.*\d)(?=.*[^a-zA-Z0-9 ])(?=.*[a-zA-Z])).*$/), Validators.required]),
    tmpkey2: new FormControl('', [Validators.required]),
  });
  verifyOTP(mobileNumber, otpCode){
    otpCode=this.phoneForm.get('otpvalidation').value;
    this.otpSetCallBackResponse.callbacks.find(callback => callback.type === 'NameCallback').input[0].value = otpCode;
    this.passwordService.passWordResetStartJourneyProgress(this.code,this.otpSetCallBackResponse).subscribe(data => {
       ;
       this.passWordConfirmationCallBack = data;
       this.otpSetCallBackResponse = data;
      if (this.passWordConfirmationCallBack != null && this.passWordConfirmationCallBack.callbacks.find((callback: any) => callback.type === 'ValidatedCreatePasswordCallback') != null) {
        this.validateOTP = true;
        this.resetFlag = false;
        this.translateService.get('views.js.alerts.emailprocessing.otpsuccess').subscribe(res => {
          this.twiliootpalert = res;
        });
      }
      else
      {
        this.validateOTP = false;
        this.resetFlag = true;
        this.translateService.get('views.js.alerts.emailprocessing.otpfail').subscribe(res => {
          this.twiliootpalert = res;
        });
      }
    });
  }
  reset()
  {
    this.mobileFlag = false;

  }
  validateMobileMessage(phoneForm,newMobile)
  {
    if ((!(this.mobileFlag)))
            this.mobileerrorFlag = true;
        else
            this.mobileerrorFlag = false;
        if (phoneForm.get('mobile_new').value == null) {
            this.mobileerrorFlag = false;
        }
        return this.mobileerrorFlag;
  }
  sendOTP(input,mobile_new)
  {
    this.messageMedium = input;
    this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'StringAttributeInputCallback').input[0].value = 
    this.phoneForm.controls.mobile_new.value.internationalNumber;
    this.isLoadingResults = true;

    this.passwordService.passWordResetStartJourneyProgress(this.code,this.otpSetCallBackResponse).subscribe(data => {
      ;
      this.otpSetCallBackResponse = data;
      this.isLoadingResults = false;

      // if (this.otpSetCallBackResponse != null && this.otpSetCallBackResponse.callbacks.find((callback: any) => callback.type === 'ValidatedCreatePasswordCallback') != null) {
      //   this.otpErrorMsg = ""; 
      //   this.sendSMSClicked = true;
      // }
      // else
      // {
      //   this.passWordCallBackResponse = data;

      //   this.otpErrorMsg = data.errorMsg;
      // }
      },err=>{
        this.isLoadingResults = false;

      });
  }
  checkNumber(){
    clearTimeout(this.timeout);

    // Set a new timeout to delay the execution of the logic
    this.timeout = setTimeout(() => {
      // Your logic to check the number goes here
      if(this.sendSMSClicked)
        {
          this.startPassWordResetJourney();
        }
        if(this.phoneForm.controls.mobile_new.value!=null)
      {
        const formphoneNumber = this.phoneForm.controls.mobile_new.value.internationalNumber;
        const phoneNumber = parsePhoneNumberWithError(formphoneNumber);
        if (phoneNumber) {
          this.isValid = phoneNumber.isValid();
          if(phoneNumber.isValid())
          {
            this.isValid =true;
          }
          else
          {
            this.isValid = false;
          }
        } else {
          
          this.isValid = false;
        }
        if(this.isValid)
        {
          const mobileNumber=(this.phoneForm.controls.mobile_new.value.internationalNumber);
          this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'StringAttributeInputCallback').input[0].value = 
          mobileNumber;  
    
          this.mobileFlag = true;
          this.isLoadingResults = true;
          this.passwordService.passWordResetStartJourneyProgress(this.code,this.passWordCallBackResponse).subscribe(data => {
            ;
          this.otpSetCallBackResponse = data;
          this.isLoadingResults = false;
    
           if(this.otpSetCallBackResponse!=null && this.otpSetCallBackResponse.callbacks!=null && this.otpSetCallBackResponse.callbacks.length>0)
            {
            if(this.otpSetCallBackResponse.callbacks.find(callback => callback.type === 'ConfirmationCallback')){
            this.sendSMSClicked = true;
            this.mobileFlag = true;
           }
           else
           {
            this.mobileFlag = false;
            this.sendSMSClicked = false;
            this.passWordCallBackResponse=data;
           }
    
           }
           else{
           
            this.startPassWordResetJourney();
           }
          },err=>{
             this.isLoadingResults = false;
    
          });
        }
        else{
          this.mobileFlag = false;
          this.sendSMSClicked = false;
         // this.startPassWordResetJourney();
        }
      }
      else
      {
        this.mobileFlag = false;
          this.sendSMSClicked = false;
      }
    }, 300); // 300ms delay
  
  
  }
  ngOnInit(): void {
   
    this.route.queryParams.subscribe(
      params => {
        this.code = params.code;
        this.lang = params.lang.toUpperCase();
        this.translateService.setDefaultLang(this.lang);
        this.commonService.setComponentLanguage(this.lang);
        this.translateService.use(this.lang);

        this.route.url.subscribe((event) => {
          this.path = '/' + event[0].path;
        });
        this.translateService.get('views.js.alerts.emailprocessing.Email Processing').subscribe(res => {
          this.title = res;
        });
        this.translateService.get('views.js.alerts.emailprocessing.Your action is being performed').subscribe(res => {
          this.text = res;
        });
        
        this.startPassWordResetJourney();
        
      })};


  startPassWordResetJourney(){
    //Calling first step of call backs.
    this.passWordCallBackResponse = this.passwordService.passWordResetStartJourney(this.code).subscribe(data => {
      ;
    
      this.passWordCallBackResponse = data;
      
      if (this.passWordCallBackResponse === null ||  this.passWordCallBackResponse.callbacks === null || this.passWordCallBackResponse.callbacks === 'undefined' || this.passWordCallBackResponse.callbacks.length<=0) {
        this.translateService.get('views.js.alerts.emailprocessing.Unknown Request').subscribe(res => {
          this.title = res;
        });
        this.translateService.get('views.js.alerts.emailprocessing.YouSomething went wrong').subscribe(res => {
          this.text = res;
        });
        this.router.navigate(['/public/error?code='+this.code]);
      }

       else if(!(this.passWordCallBackResponse === null ||  this.passWordCallBackResponse.callbacks === null || this.passWordCallBackResponse.callbacks === 'undefined')){
        
        if(this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'NameCallback')){
          this.preferredMFA = 'AuthenticatorApp';
        }

        else{
        const textOutputCallback = this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'TextOutputCallback');
        this.extractUserProfile(textOutputCallback);
        if(this.preferredMFA === 'email')
        this.passWordConfirmationCallBack = this.passWordCallBackResponse;
        }

          if (this.mobile != '')
          this.hideMobile = '******' + this.mobile;
           //this.otpappLastchanged = userrequest.body.otpappLastchanged;
            if(this.azureTenant==='global')
              {
                  if ((this.mobile  == "" || this.mobile  == undefined) && (this.preferredMFA === 'email')) {
                      this.resetFlag = false;
                  }
                  else if(this.preferredMFA === ''|| this.preferredMFA===null && this.azureTenant==='global')
                  {
                    this.router.navigate(["public/errormfa"]);
                  }
                  else if(this.preferredMFA==='email')
                  {
                    this.resetFlag=false;
                  }
                  else
                  {
                      this.resetFlag = true;

                  }
              }
              else if(this.azureTenant==="swiss")
              {
                  this.resetFlag = false;

                  this.passWordConfirmationCallBack = this.passWordCallBackResponse;

              }
          
      }
      else
      {
        this.router.navigate(["public/errorpassword"]);
      }
    },err=>{
      this.router.navigate(["public/errorpassword"]);

    }); 
  }

  resetauthErrors()
  {
    this.errormessage = "";
    this.successmessage = "";
  }
  verifycodeauth(event,codevalidation)
  {
    this.loaderFlag = true;
    this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'NameCallback').input[0].value = 
    codevalidation;

    this.passwordService.passWordResetStartJourneyProgress(this.code, this.passWordCallBackResponse).subscribe(data => {
      ;
      this.passWordConfirmationCallBack = data;
      const textOutputCallback=this.passWordConfirmationCallBack.callbacks.find(callback => callback.type === 'TextOutputCallback');
      if (this.passWordConfirmationCallBack !== null && textOutputCallback!=null && textOutputCallback!=undefined ) {
        this.loaderFlag = false;
        this.resetFlag = false;
        this.verifyauthcodeFlag = true;
        this.successmessage = 'views.js.alerts.emailprocessing.OTP application registration successful head';
        this.errormessage = "";

        const textOutputCallbackinside = this.passWordConfirmationCallBack.callbacks.find(callback => callback.type === 'TextOutputCallback');
        this.extractUserProfile(textOutputCallbackinside);
      } else {
        this.passWordCallBackResponse = data;

        // this.passwordService.passWordResetStartJourney(this.code).subscribe(data => {
        //   ;
        //   this.passWordCallBackResponse = data;
        // });
        this.resetFlag = true;
        this.codevalidation ="";
        this.verifyauthcodeFlag = false;
        this.successmessage = "";
        this.errormessage = 'views.js.alerts.emailprocessing.verification of one time password failed';
      }
    });

  }

  extractUserProfile(textOutputCallback: any) {
    const userprofile = JSON.parse(textOutputCallback.output[0].value);
    this.email = userprofile.email;
    this.firstname = userprofile.firstName;
    this.lastname = userprofile.lastName;
    this.mobile = userprofile.mobile;
    this.preferredMFA = userprofile.preferredMFA;
    this.azureTenant = userprofile.azureTenant;
   // this.emailOtp = userprofile.emailOtp;
   this.userRelationship = userprofile.userRelationship;
    for (const key in this.userRelationship) {
      console.log(this.userRelationship)
      if (this.userRelationship.hasOwnProperty(key)) {
          const tenant = this.userRelationship[key];
          console.log("tenant name", tenant.tenantName)
          if (tenant.tenantName && tenant.tenantName === 'global') {
              this.azureTenant = tenant.tenantName;
              break;
          }
      }
  }
  }

  changePasswordtype2() {
    if (this.password2type === 'password') {
      this.password2type = 'text';
    }
    else {
      this.password2type = 'password';
    }
  }
  isFormInvalid(): boolean {
    return !this.passwordContainsTen || !this.passwordContainsUppercase || !this.passwordContainsLowercase || !this.passwordContainsNumeral || !this.passwordNonAlphaNumeric || this.passwordHasIdenticalPattern || this.passwordHasConsecutivePattern || this.passwordHasDictionaryWord;
  }
  checkdictionaruWord(value)
  {
    const password = value.toLowerCase();
    const hasDictionaryWord = dictionaryWords.some(word => password.includes(word.toLowerCase()));
    return hasDictionaryWord ;
  }
  check() {
    const pwd = this.passwordForm.get('tmpkey').value;
    if(pwd!="" && pwd!=null)
    {
      this.tmpkeyFlag = true;
      this.pwdAlreadyUsed = false;

    if (pwd) {
      this.passwordContainsTen = pwd.length > 9;
      this.passwordContainsUppercase = !!pwd.match(/[A-Z]/);
      this.passwordContainsLowercase = !!pwd.match(/[a-z]/);
      this.passwordContainsNumeral = !!pwd.match(/[0-9]/);
      this.passwordNonAlphaNumeric = !!pwd.match(/[\W]/);
      this.passwordHasIdenticalPattern = !!pwd.match(this.identicalPattern);
      this.passwordHasConsecutivePattern = !!pwd.match(this.consecutivePattern);
      this.passwordHasDictionaryWord =this.checkdictionaruWord(pwd);
    
    }
    if (this.passwordForm.get('tmpkey').value === this.passwordForm.get('tmpkey2').value) {
      this.matching = true;
      document.getElementById('password2').setAttribute('style', '');
    } else {
      this.matching = false;
      if (this.passwordForm.get('tmpkey2').value !== '') {
        document.getElementById('password2').setAttribute('style', 'border: 1px solid #da291c');
      }
    }
    if (
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.email.toLowerCase()) !== -1) ||
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.firstname.toLowerCase()) !== -1) ||
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.lastname.toLowerCase()) !== -1)
    ) {
      this.pwddoesnotcontainuserattributes = false;
    } else {
      this.pwddoesnotcontainuserattributes = true;
    }
    if (zxcvbn(this.passwordForm.get('tmpkey').value).score > 1) {
      this.strongpassword = true;
    }
    if(this.strongpassword  && this.pwddoesnotcontainuserattributes && !(this.isFormInvalid())){
    this.tmpkeyFlag=false;
      }
  }
  else
  {
    this.tmpkeyFlag =false;
  }

  }
  testPattern() {
    if (this.passwordForm.controls.tmpkey.errors?.pattern == null) {
      this.tmpkeyFlag = false;
    }
    else {
      this.tmpkeyFlag = true;
    }
  }
  changeType() {
    if (this.itype === 'password') {
      this.itype = 'text';
    }
    else {
      this.itype = 'password';
    }
  }
  pwdReset(password) {

    //This call back has to be removed other wise the call back is throwing error. Confirmation from team.    
    // let callbacksTemp = this.passWordConfirmationCallBack.callbacks.filter(callback => callback.type !== "TextOutputCallback");
    // this.passWordConfirmationCallBack.callbacks = callbacksTemp;
    
    this.passWordConfirmationCallBack.callbacks.find(callback => callback.type === 'ValidatedCreatePasswordCallback').input[0].value = password;
    this.passwordService.passWordResetStartJourneyProgress(this.code, this.passWordConfirmationCallBack).subscribe(resp => {
    this.finalCallBackResponse = resp;
    this.passWordConfirmationCallBack = resp;
    let policyRequirement: string | null = null;
//Checking for password history policy
if (this.finalCallBackResponse && this.finalCallBackResponse.callbacks && Array.isArray(this.finalCallBackResponse.callbacks)) {
  for (const callback of this.finalCallBackResponse.callbacks) {
    if (callback && callback.type === "ValidatedCreatePasswordCallback" && callback.output && Array.isArray(callback.output)) 
      {
      for (const output of callback.output) {
        if (output && output.name === "failedPolicies" && output.value && Array.isArray(output.value)) {
          for (const failedPolicy of output.value) {
              policyRequirement = failedPolicy.policyRequirement;
              break;
          }
        }
      }
    }
    else if (callback && callback.type === "TextOutputCallback" && callback.output && Array.isArray(callback.output)) {
      for (const output of callback.output) {
        if( output && output.name=="message" && output.value=="Password reset successful")
          {
            this.passwordService.successtype = 'passwordupdate';
            this.router.navigate(['public/success']);
          }
      }
        
      }
  }
}

      if (policyRequirement !== null) {
        this.pwdAlreadyUsed = true;
      } else {
        // this.passwordService.passWordResetStartJourneyProgress(this.code, this.passWordConfirmationCallBack).subscribe(data => {
        //    ;
        //   this.passwordService.successtype = 'passwordupdate';
        //   this.router.navigate(['public/success']);
        // });
      }
    });
  }

}
