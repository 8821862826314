<div id="roleList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li class="breadcrumb-item"><a routerLink="/home">{{'views.role.Home' | translate }}</a></li>
        <li class="breadcrumb-item active">{{"views.rolerequestorg-list.Role requests for"| translate}} {{currentOrganization}}</li>
        <li class="breadcrumb-item logout"><a href="./oidc/breadcrumb-item">{{'views.role.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/roles.png" alt="role management" /></div>
    <h1>{{"views.rolerequestorg-list.Role requests for"| translate}} {{currentOrganization}}</h1>

    <div class="btn-group btn-group-xs" style="display: flex;justify-content: end; background: #f5f5f5;">
        <div class="btn-group btn-group-xs">
            <button type="button" class="btn btn-sm" (click)="showAllRoleRequests()">{{alltxt}}</button>
        </div>
        <div class="btn-group btn-group-xs">
            <a class="btn btn-primary" (click)="downloadCSV()">CSV</a>
        </div>
    </div>

    <div class="card" style="overflow: scroll;">
        <!-- <div class="card-header"></div>
        <div class="card-body">
            <table ng-table="tableParams" class="table  dislist" show-filter="true" style="padding: 2px"
                export-csv="csv">
                <tr ng-repeat="role in $data">
                    <td data-title="'views.role.Status' | translate" sortable="'status'" class="dislist">
                        <p ng-hide="true">{{role.status}}</p>
                        <img style="width: 25px" ng-show="role.status == 'suspended'" src="images/icons/bullet_red.png"
                            alt="suspended" title="suspended" />
                        <img style="width: 25px" ng-show="role.status == 'active'" src="images/icons/bullet_green.png"
                            alt="active" title="active" />
                    </td>
                    <td data-title="'views.role.Name' | translate" sortable="'name'" filter="{ 'name': 'text' }"><a
                            href="#/role/{{role.id}}">{{role.name}}</a></td>
                    <td ng-show="authorizedServices()" data-title="'Service'" sortable="'serviceId.name'"
                        filter="{ 'serviceId.name': 'text' }"><a
                            href="#/service/{{role.serviceId.id}}">{{role.serviceId.name}}</a></td>
                    <td data-title="''" style="width:22%">
                        <div ng-if="role.status !== 'suspended'">
                            <div confirm-suspend on-confirm="suspendRole(role)"></div>
                            <div confirm-delete on-confirm="deleteRole(role)"></div>
                        </div>
                        <div ng-if="role.status === 'suspended'">
                            <div confirm-activate on-confirm="activateRole(role)"></div>
                            <div confirm-delete on-confirm="deleteRole(role)"></div>
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->
        <div class="example-table-container">

            <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort matSortActive="email"
                matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                <!-- (matSortChange)="resetPaging()" -->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <img style="width: 25px" *ngIf="row.status === 'suspended'"
                            src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                            title="{{'views.user.suspended' | translate}}" />
                        <img style="width: 25px" *ngIf="row.status === 'active'"
                            src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                            title="{{'views.user.active' | translate}}" />
                    </td>
                </ng-container>
                <!-- {
                    "userId": {
                        "id": 10,
                        "lastname": "Savitha",
                        "firstname": "B",
                        "email": "bsavitha@deloitte.com",
                        "status": "active",
                        "userType": "external"
                    }
                } -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">User email
                    </th>
                    <td mat-cell *matCellDef="let row">{{row?.userId?.email}}</td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">Role
                    </th>
                    <td mat-cell *matCellDef="let row">{{row?.roleId?.name}}</td>
                </ng-container>
                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                        Organization</th>
                    <td mat-cell *matCellDef="let row">{{row?.organizationId.name}}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="enddate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        End Date</th>
                    <td mat-cell *matCellDef="let row">{{row?.endDate}}</td>
                </ng-container> -->
                <!-- <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="row.status  !== 'suspended'" style="display: flex;">
                            <app-confirmsuspend (onconfirm)="suspendRole(row)"></app-confirmsuspend>
                            <app-confirm-delete (onconfirm)="deleteRoleRequest(row)"></app-confirm-delete>

                        </div>
                        <div *ngIf="row.status  === 'suspended'" style="display: flex;">
                            <app-confirm-activate (onconfirm)="activateRole(row)"></app-confirm-activate>
                            <app-confirm-delete (onconfirm)="deleteRoleRequest(row)"></app-confirm-delete>
                        </div>
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="filter-status">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-email">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterbyEmail($event.target.value)" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-role">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterbyRole($event.target.value)" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-organization">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterByOrganization($event.target.value)" />
                        </mat-form-field>
                    </th>
                </ng-container>

                <!-- <ng-container matColumnDef="filter-email">
                    <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterbyEmail($event.target.value)" />
                        </mat-form-field> </th>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-header-row
                    *matHeaderRowDef="['filter-status', 'filter-email', 'filter-role','filter-organization']"
                    class="example-second-header-row">
                </tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"
            (page)="getPageDetails($event)"></mat-paginator>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" class="btn btn-light custom-btn" routerLink="/home">
                <span class="bi bi-house-up-fill" style="margin-right: 10px"></span>{{'views.role.Home' | translate
                }}</button>
        </div>
        <!-- <div class="btn-group">
            <button type="button" class="btn btn-light custom-btn" ng-click="gotoRoleNewPage()">
                <span class="bi bi-plus-lg" style="margin-right: 10px"></span>{{'views.role.Add new role' |
                translate }}</button>
        </div> -->
    </div>
    <app-terms-conditions></app-terms-conditions>

</div>